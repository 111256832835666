const style = (theme) => ({
    // ProjectsMenChatbodyuBody: {
    //     height: 'calc(100vh - 100px)',
    //     overflowY: 'auto',
    //     overflowX: 'hidden',
    //     // position: 'relative'
    // },
    ChatContainer: {
        background: '#fff',
        flex: '1 1 50%',
        position: 'relative',
        display: 'flex',
        "& .chatMessageContainer": {
            "&:focus": {
                border: "0px !important",
                outline: "0px !important",
            }
        },
    },
    ChatHeader: {
        padding: '5px 10px',
        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.13)',
        borderBottom: `1px solid ${theme.palette.primary.border2}`,
        maxHeight: '56px',
        '& .MoreIcon': {
            height: 30,
            width: 30
        }
    },
    textTypeIcon: {
        position: 'absolute',
        left: 24,
        top: 8,
        height: 50,
        width: 50,
        borderRight: `1px solid ${theme.palette.primary.border1}`,
        paddingRight: 15
    },
    messagestatus: {
        fontSize: "9px",
        color: '#fff',
        position: 'absolute',
        right: '8px',
        bottom: '1px'
    },

    ChatTypingArea: {
        // borderTop: '1px solid #cdcdcd',
        width: '100%',
        position: "relative",
        backgroundColor: '#fff',
        padding: '10px 20px',
        '@media only screen and (max-width: 899px)': {
            '&>.MuiGrid-container': {
                boxShadow: 'none',
                borderRadius: '12px',
                padding: '0px !important'
            },
            '& .chatInputBox': {
                borderRadius: '8px',
                // background: '#F0F0F0'
            },
            '& .textOverallCon': {
                background: 'transparent',
                // borderRadius: '30px'
            },
            '& #chat-msg-editor': {
                justifyContent: 'center',
                // paddingTop: '0px !important',
                // '&:before': {
                //     height: '42px',
                //     padding: '8px 0px'
                // }
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#fff'
        },
        '& .chatInputBox': {
            // width: 'calc(100% - 175px)',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            // maxHeight: 150,
            // overflowY: 'auto',
            padding: '0px 5px',
            margin: '2px 0 2px 2px'
        },
        '& .DraftEditor-root': {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            // background: palette.primary.bgColorGray,
            // borderLeft: `1px solid ${palette.primary.border1}`,
            // borderRadius: '10px',
            padding: '5px 10px',
            // marginTop: '5px',
            // marginBottom: '5px',
            minHeight: 50,
            // paddingLeft: 75,
            '& .DraftEditor-editorContainer': {
                width: "100%",
                height: "auto",
                overflowY: 'auto',
                maxHeight: '100px',
                '& .public-DraftStyleDefault-block': {
                    wordBreak: 'break-word',
                    margin: '0px !important',
                    // maxHeight: '100px',
                    // overflowY: 'auto'
                }
            }
        },
        '&.replyInput': {
            background: '#F5F9FF !important',
            '&>.MuiGrid-container': {
                background: '#fff !important'
            }
        }
    },
    chatTypeField: {
        maxWidth: 'calc(100% - 75px)'
    },
    ChatBodyContainer: {
        flex: '1 1 0',
        position: 'relative',
        overflowY: 'auto',
        display: 'flex',
        "& .scrollChatLine": {
            background: '#F5F5F7 !important'
        },
        "&:focus": {
            border: "0px !important",
            outline: "0px !important",
        },
        '& .MuiAvatar-root': {
            height: 30,
            width: 30,
            fontSize: 15
        },
        "& .chatName": {
            fontSize: "13px",
            fontWeight: 600
        },
    },
    Chatbody: {
        // height: 'calc(100vh - 343px)',
        width: '100%',
        padding: '0px',
        overflowY: 'auto',
        position: 'relative',
        '& .chat-online': {
            position: 'absolute',
            width: '12px',
            height: '12px',
            background: '#35ac19',
            borderRadius: '50%',
            bottom: '0',
            zIndex: 1,
            border: '2px solid #fff',
        },

    },
    chatTile: {
        width: 'auto',
        maxWidth: '65%',
        minWidth: '7.5rem',
        display: 'inline-block',
        // padding: '12px',
        // paddingBottom: "12px",
        borderRadius: '0 10px 10px 20px',
        lineHeight: '20px',
        verticalAlign: 'middle',
        textAlign: 'left',
        wordBreak: 'break-word',
        position: 'relative'
    },
    chatTileContainer: {
        display: 'block !important',
        width: '100%',
        // maxWidth: 'max-content',
        '@media only screen and (min-width: 900px)': {
            '&:hover': {
                '& .chatTileSendMenu': {
                    // display: 'inline-flex',
                    visibility: 'visible'
                },
                '& .chatTileStarMenu': {
                    // visibility: 'visible',
                    // padding: '3px',
                    // marginTop: "-2px"
                },
            },
        },
        '& .chatTileSendMenu': {
            // display: 'none'
            visibility: 'hidden',
            // padding: 8
        },
        '& .chatTileStarMenu': {
            visibility: 'visible',
            // padding: '3px',
            marginTop: "-2px"
        },
        '& .noStarMenu': {
            visibility: 'hidden',
        },
        '& .time_ago': {
            fontSize: '9px',
            lineHeight: "0.9rem",
            marginRight: 0,
            marginBottom: -3,
        },
        '&.chatTileReceiveContainer': {
            display: 'flex !important',
            alignItems: 'center'
        }
    },
    chatTileSend: {
        // background: '#495efc',
        // backgroundImage: 'linear-gradient(rgb(46 52 216), rgb(25 169 252 / 72%))',
        // backgroundSize: '100% 100%',
        // backgroundAttachment: 'fixed',
        marginRight: 40,
        // border: '1px solid #ddd',
        // borderRadius: '10px 0px 10px 10px',
        // boxShadow: '0 1px 0.5px #ddd',
        // paddingRight: 6,
        zIndex: 0
    },

    dialogContainer: {
        '@media only screen and (min-width: 768px)': {
            minWidth: 600,
            // minHeight: 324
        },
        borderRadius: "12px",
        // minWidth: 300,
        maxWidth: 300
    },
    header: {
        position: 'sticky',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 13px',
        alignItems: 'center',
        background: theme.palette.containers.card,
        borderBottom: `1px solid ${theme.palette.primary.border1}`,
        '& .CloseIcon': {
            height: '13px',
            width: '13px'
        }
    },
    forwarddHeader: {
        '& .titleText': {
            padding: '0px 20px',
        }
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: 12,
        '& .searchBoxGrid': {
            marginRight: '0px !important'
        },
        '& .MuiIconButton-edgeEnd': {
            marginRight: '0px !important'
        },
        '& .SearchIcon2': {
            width: '17px',
            height: '17px'
        }
    },
    bodyContainer: {
        padding: 20,
        overflowY: 'auto',
        flex: 1,
        paddingTop: '10px !important',
        maxHeight: '418px',
        '& .individualItem': {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '12px',
            '& label.MuiFormControlLabel-root': {
                marginRight: '5px !important'
            },
            '& .MuiCheckbox-root': {
                marginRight: '0px !important'
            }
        }
    },
    chatType: {
        marginTop: 15,
        padding: '0 12px',
        width: '100%',
        '& .filterIcon': {
            padding: '0px !important',
            marginLeft: '7px',
            '& svg': {
                width: '18px',
                height: '18px'
            }
        },
        '& .MuiButtonBase-root': {
            textTransform: 'capitalize',
            fontSize: 14,
            fontWeight: 500,
            padding: '7px'
        },
        '& .MuiToggleButtonGroup-root': {
            width: '100%'
        },
        '& .MuiToggleButton-root': {
            borderRadius: 8,
            border: "none !important",
            flex: 1,
            background: '#f4f6fc',
            margin: '2px'
        },
        '& .Mui-selected': {
            color: '#4b6bfe',
            background: theme.palette.primary.gradientBtnSelectedBg,
            boxShadow: '0px 1px 1px #3d83fa',
            borderRadius: '8px',
            '& .clienttypeBorderBtm': {
                display: 'block !important'
            },
            '& .MuiToggleButton-label': {
                // background: 'transparent',
                // backgroundImage: palette.primary.gradientBtn,
                // backgroundSize: '100%',
                // backgroundRepeat: 'repeat',
                // WebkitBackgroundClip: 'text',
                // WebkitTextFillColor: 'transparent',
                // MozBackgroundClip: 'text',
                // MozTextFillColor: 'transparent',
            }
        }
    },
    textMsgBubble: {
        marginRight: 0,
        '& .public-DraftEditor-content .public-DraftStyleDefault-block span': {
            fontSize: '0.875rem',
            fontWeight: 400,
        },
        // '& .public-DraftEditor-content .public-DraftStyleDefault-block span>span::after': {
        //     content: "''",
        //     width: '50px',
        //     height: "5px",
        //     display: "inline-block",
        //     verticalAlign: "baseline",
        // },
        '& .public-DraftEditor-content .public-DraftStyleDefault-block span:last-child>span::after': {
            content: "''",
            width: '65px',
            height: "5px",
            display: "inline-block",
            verticalAlign: "baseline",
        }
    },
    chatSingle: {
        "&.chatSingle": {
            display: "block",
            marginTop: "2px",
            textAlign: "center",
            padding: "40px 0px 40px 0px",
            "& .MuiChip-root": {
                color: '#94A3B8 !important',
                background: 'transparent',
                fontWeight: 500,
                fontSize: '12px !important',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .MuiChip-labelSmall': {
                paddingLeft: '15px',
                paddingRight: '15px'
            },
            '&.timeLabel': {
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                padding: '18px 0px',
                paddingRight: "40px",
                paddingLeft: "40px",
            },
            '& hr': {
                flex: 1,
                height: '0.5px',
                borderColor: 'white',
                background: theme.palette.primary.border3,
                border: '0px solid !important'
            },
            // views
            '&.bubblewView': {
                '& .chatTile': {
                    padding: '12px',
                },
                '& .chatTileSend': {
                    paddingBottom: '18px',
                    minWidth: '150px',
                    marginRight: '50px',
                    background: '#2563EB',
                    borderRadius: '16px 0px 16px 16px',
                    color: theme.palette.primary.contrastText,
                    '&.ismediasend': {
                        padding: '0px',
                        borderRadius: '16px 0px 16px 16px',
                    },
                },
                '& .chatTileReceive': {
                    background: '#F2F5FA',
                    borderRadius: '16px 16px 16px 16px',
                    boxShadow: '0 1px 0.5px rgb(0 0 0 / 8%)',
                    '& .time_ago': {
                        color: '#64748B !important'
                    },
                    '&.ismediareceive': {
                        padding: '3px',
                        borderRadius: '0px 6px 6px 6px',
                    }
                },
                '& .timeAgoBottom': {
                    display: 'block',
                    color: theme.palette.primary.contrastText
                },
                '& .timeAgoTop, & .timeBtm': {
                    display: 'inline'
                },
                '& .timeTop': {
                    display: 'none'
                },
                '& .replyAtavarText': {
                    '& .MuiAvatar-root': {
                        marginRight: 8,
                        width: '27px',
                        height: '27px'
                        // display: 'none'
                    }
                },
                '& .replyTileListBox': {
                    // display: 'none'
                },
                '& .replyTileBubbleBox': {
                    display: 'block'
                },
                '& .replyTileBox': {

                },
                '& .forwardText': {
                    fontSize: "12px",
                    fontStyle: "italic",
                    fontWeight: 500,
                    color: "#B4C0D2",
                    marginLeft: "5px"
                },
                '& .listViewTime': {
                    display: 'none'
                },
                '& .bubbleViewTime': {
                    // display: 'inline-block'
                },
                '& .chatNameContainer': {
                    '& .MuiAvatar-root': {
                        width: '40px !important',
                        height: '40px !important'
                    }
                },
                '@media only screen and (min-width: 576px)': {

                },
                '@media only screen and (max-width: 576px)': {
                    // '& .chatNameContainer': {
                    //     marginBottom: '-10px !important',
                    //     '& .MuiAvatar-root': {
                    //         width: '27px !important',
                    //         height: '27px !important'
                    //     }
                    // },
                    '& .replyAtavarText ': {
                        '& .MuiAvatar-root': {
                            width: '27px !important',
                            height: '27px !important'
                        }
                    },
                    '& .chatTileSend': {
                        // marginRight: '31px',
                        '& .chatName': {
                            marginRight: '5px'
                        }
                    },
                    '& .chatTileReceive': {
                        // marginLeft: '31px',
                    },

                },
                '& .replyVerticleBar': {
                    display: 'none'
                },
                '& .forwardMsg': {
                    marginTop: '-8px',
                    marginBottom: '2px'
                }
            }
        }
    },

    chatInputBox: {
        '@media only screen and (max-width: 768px)': {
            '&.chatInputBox': {
                borderRadius: '8px',
                // background: '#F0F0F0',
            },
            '& .textOverallCon': {
                background: 'transparent',
                // borderRadius: '30px'
            }
        },
        "& .DraftEditor-editorContainer": {
            maxHeight: "6rem !important",
            overflowY: "auto !important",
            width: "100%",
            position: 'relative',
            color: 'black !important'
        },
    },
    ChatTypingArea: {
        // borderTop: '1px solid #cdcdcd',
        width: '98%',
        margin: "5px",
        position: "sticky",
        bottom: 10,
        backgroundColor: theme.palette.containers.bg29,
        borderRadius: '12px',
        boxShadow: '0px 0px 8px rgb(0 0 0 / 5%)',
        border: `1px solid ${theme.palette.primary.border2}`,
        '@media only screen and (max-width: 899px)': {
            '&>.MuiGrid-container': {
                boxShadow: 'none !important',
                borderRadius: '12px',
                padding: '0px !important'
            },
            '& .chatInputBox': {
                borderRadius: '8px',
                // background: '#F0F0F0'
            },
            '& .textOverallCon': {
                background: 'transparent',
                // borderRadius: '8px'
            },
            '& #chat-msg-editor': {
                justifyContent: 'center',
                // paddingTop: '0px !important',
                // '&:before': {
                //     height: '42px',
                //     padding: '8px 0px'
                // }
            }
        },
        '& .chatInputBox': {
            // width: 'calc(100% - 175px)',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            // maxHeight: 150,
            // overflowY: 'auto',
            // padding: '8px 5px'
            padding: '0px 5px',
            margin: '2px 0 2px 12px',
            overflowX: 'auto'
        },
        '& .DraftEditor-root': {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            // background: palette.primary.bgColorGray,
            // borderLeft: `1px solid ${palette.primary.border1}`,
            // borderRadius: '10px',
            padding: '10px',
            // marginTop: '5px',
            // marginBottom: '5px',
            minHeight: 50,
            // paddingLeft: 75,
            '& .DraftEditor-editorContainer': {
                width: "100%",
                height: "auto",
                overflowY: 'auto',
                maxHeight: '100px',
                '& .public-DraftStyleDefault-block': {
                    wordBreak: 'break-word',
                    margin: '0px !important',
                    // overflowY: 'auto'
                }
            }
        }
    },
})

export default style