import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, withStyles } from '@material-ui/core'

//Import Component
import { Typography } from '..'

// Imports Styles
import styles from "./styles";

import { CameraAttachments, GifAttachments, VideoAttachments, AudioAttachments } from "../../assets/svg/chatIcons";

import { ReplyRadius } from "../../assets/svg";
import moment from 'moment';
import 'moment-duration-format';
import { formatFileSize } from '../../utils/chatHelper';
import { CustomAvatar } from '../AvatarComponent';
import ChatEditor from '../FlozyEditor/ChatEditor/chatEditor';

const InputReplyComponent = (props) => {
    const { classes, messageObj, userId, message = {} } = props;
    const message_category = message?.message_category || 0
    let fileUrl = null; let fileDoc = null; let fileIcon = null; let fileType = null;
    let fileObj;
    let duration;
    function formatDuration(duration) {
        const time= Math.round(duration / 1000)
        return moment.duration(time, 'seconds').format('mm:ss', { trim: false });
      }
    if (messageObj.message_file) {
        fileObj = JSON?.parse(messageObj?.message_file);
        if (messageObj.message_category === 1) {
            fileUrl = fileObj && fileObj.url ? fileObj.url : null;
            duration= fileObj?.duration
            if(fileObj.type.includes("video")) {
                fileType = "Video"
                fileUrl = fileObj.thumb ? fileObj.thumb : fileObj
                fileIcon = <VideoAttachments />
            } else if(fileObj.type.includes("gif")) {
                fileType = "GIF"
                fileIcon = <GifAttachments />
            } else {
                fileType = "Photo"
                fileIcon = <CameraAttachments />
            }
        }
        else if (messageObj.message_category === 2) {
            fileDoc = fileObj && fileObj.name ? fileObj.name : null;
            fileIcon = fileObj && fileObj.type ? fileObj.type.split("/")[1] : "docs";
        } else if (messageObj.message_category === 3) {
            fileUrl = fileObj && fileObj[0].url ? fileObj[0].url : null;
        }
        else if (messageObj.message_category === 4) {
            fileDoc = fileObj && fileObj.name ? "Voice Note" : null;
            fileIcon = <AudioAttachments />
        }
    }
    let fileExtension = fileObj?.type && fileObj?.type?.includes("video") ? 'docvideo' : fileObj?.type?.includes("image") ? 'docimg' : fileObj?.name  ? fileObj?.name?.split('.').pop() : messageObj.message_category === 3 ? 'docimg' :''
    const [imageSrc] = useState(`./icons/${fileExtension}.svg`);
    return (
        <>
            {messageObj ?
            <>
            <Grid className={"replyVerticleBar"}>
                <ReplyRadius />
            </Grid>
                <Grid className={classes.replyTile} container item alignItems="center">
                    <Grid className={`${classes.replyTileBox} replyTileBox`}>
                        <Grid className={classes.profileContainers}>
                        <Grid className={`${classes.avatarBox} replyAtavarText replyTileListBox`}>
                        <CustomAvatar
                            image={messageObj.avatar_filename ? messageObj.avatar_filename : null}
                            primaryText={messageObj.name ? messageObj.name : (messageObj.first_name ? messageObj.first_name : '')}
                            secondaryText={''}
                            size={'large'}
                            showBadge={false}
                            hideText = {true}
                        />
                        </Grid>
                        <Grid className= {`${classes.replyTileBubbleBox} ${message_category !== 0 ? 'reply-img' : ''} mr-2`}>
                        <Grid className="dflex flexColumn justifyCenter">
                                <Typography variant="subtitle2" className="taskstitle fs-12 fw-500 disable-user-select">{messageObj?.user_id == userId? "You" : messageObj.name ? messageObj.name : `${messageObj.first_name ? messageObj.first_name : ''} ${messageObj.last_name ? messageObj.last_name : ''}`}</Typography>
                                <div className="dflex">
                                {fileExtension && <img src={imageSrc} width={'20px'} height={'20px'} alt="loading"/>}
                                {messageObj?.message_text && messageObj?.message_text!=='\n' ? 
                                <ChatEditor
                                    className={'chatEditorRoot'}
                                    id={messageObj?.id  || 1}
                                    content_status={'loaded'}
                                    content={messageObj?.message_text }
                                    readOnly={true}
                                    clickable={true}
                                    elipis={message_category !== 0 ? 2 : 3}
                                />
                                :
                                <span className="dflex alignCenter"><Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize10 dflex alignCenter pl5"> {messageObj.message_category == 2 && fileObj?.name}  {messageObj.message_category === 4 && 'Audio'} {messageObj.message_category === 3 && 'Group images'} {(fileObj?.type?.includes("video") && fileObj?.size) && formatFileSize(fileObj?.size)}  {fileType} </Typography></span>}
                                </div >
                        </Grid>
                            </Grid>
                        </Grid>
                        {fileUrl ? <img alt={"imagethumb"} src={fileUrl} className={classes.replyThumb} /> : null}
                    </Grid>
                </Grid>
                </> : null}
        </>
    );
};

// Default props
InputReplyComponent.defaultProps = {
    classes: "",
    messageObj: { id: "" }
};

// Prop types
InputReplyComponent.propTypes = {
    classes: PropTypes.object,
    messageObj: PropTypes.any,
};

/** Export Component */
export const ChatReplyComponent = withStyles(styles)(InputReplyComponent);
