export const DownChevronIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M3.25 4.875L6.5 8.125L9.75 4.875" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const UpChevronIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12 12" fill="none">
    <path d="M3 7.5L6 4.5L9 7.5" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)