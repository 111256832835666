const Style = (theme) => ({
  repeatCont: {
    "& .MuiOutlinedInput-root": {
      strokeWidth: "1px",
      filter: "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))",
      border: "1px solid #6f6f6f33"
    },
    "&.inputFieldrecurring": {
      width: '115px',
      height: '40px',
      fill: '#FFF',
      strokeWidth: '1px',
      stroke: 'rgba(111, 111, 111, 0.20)',
      filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
    },
    "& .radioButton": {
      color: '#2563EB'
    },
    "& repeatTab": {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .repeatField': {
      "& .MuiOutlinedInput-root": {
        paddingLeft: '15px !important',
      }
    },
    '& .startDate': {
      '& .MuiOutlinedInput-input': {
        paddingLeft: '10px'
      }
    },
    // '& input': {
    //   paddingLeft: '10px !important'
    // },
    '@media only screen and (max-width: 768px)': {
      '&.MuiGrid-spacing-xs-5 > .MuiGrid-item': {
        padding: '10px !important'
      }
    }
  },
  recurringHeaders: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  },
  repeatDayCont: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  oneDay: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    display: 'flex',
    '@media only screen and (max-width: 599px)': {
      display: 'flex !important',
      marginTop: '10px !important'
    },
  },
  selectedDay: {
    border: `1px solid ${theme.palette.colors.blue} !important`,
    color: theme.palette.colors.blue,
    background: `${theme.palette.containers.bg27} !important`,
    '& .MuiButtonBase-root': {
      fontWeight: '600',
    }
  },

  repeatDays: {
    background: theme.palette.containers.card,
    border: `1px solid #6F6F6F33`,
    borderRadius: 9,
    border: "1px solid #6f6f6f33",
    padding: 10,
    // width: 54,
    height: 40,
    marginRight: '10px',
    '& .MuiList-root': {
      display: 'flex'
    },
    '& .MuiCheckbox-root': {
      padding: '0px !important',
      margin: '0px !important'
    },
    '& .MuiFormControlLabel-root': {
      margin: '0px !important',
      marginLeft: '-23px !important'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      visibility: 'hidden'
    },
    '& .MuiList-padding': {
      padding: '0px !important'
    },
    '& .MuiListItemText-root': {
      marginLeft: '-20px'
    },
  },
  calendar: {
    marginTop: "4px"
  },
  start: {
    // width: 151,
    // height: 41,
  },
  taskCalendar: {
    width: '16px',
    height: ' 15.568px',
    padding: '1.946px 2.667px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarFont: {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '160%'
  },
  timeZone: {
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))',
    width: '151 px',
    paddingTop: "11px",
    height: 40,
  },
  month: {
    width: '198px',
    height: '40px',
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: 'rgba(111, 111, 111, 0.20)',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))',
  },
  inputFieldText: {
    color: '#778599',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal'
  },
  endOptions: {
    width: "100%",
    padding: "4px",
    background: theme.palette.containers.bg4,
    borderRadius: "8px",
    justifyContent: "space-around",
    display: "flex"
  },
  selectedTab: {
    width: '75px',
    height: '28px',
    borderRadius: '8px',
    background: theme.palette.containers.card,
    boxShadow: '0px 0px 12px 0px rgba(107, 114, 128, 0.04), 0px 0px 1px 0px rgba(75, 85, 99, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      color: theme.palette.colors.blue,
      fontWeight: '700'
    }
  },
  normalTab: {
    color: 'var(--greyscale-500, #64748B)',
    textAlign: 'center',
    /* body/small/regular */
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  afterInput: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    width: '115px',
    height: ' 36px',
    flexShrink: '0',
    strokeWidth: '1px',
    stroke: '#2563EB',
    filter: 'drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05))'
  },
  calenderCont: {
    borderRadius: "8px",
    height: "40px",
    '& .MuiInputAdornment-positionEnd': {
      order: -1,
      marginLeft: '0px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px'
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px'
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '5px'
    },
  },
})

export default Style;