import { PARAM_KEY, SENT_MESSAGE_API_SERVICE_NAME } from '../containers/Chatv2/Chatv2Constants';
import axios from 'axios';
import { _api } from '../config/environment'
import {  getToken } from '../utils';

/**
 * Helper Function for chat Send
 * @param {*} userData 
 * @returns 
 * 
 */


export const getTempMsgIdHelper = (userData) => {
  return parseInt(new Date().getTime().toString().slice(-5), 10);
}


export const getParamsHelper = (userData) => {
    /* Add Temp message for the sender */
    const tempMessageId = userData?.media_uniqId ? userData.media_uniqId : getTempMsgIdHelper(userData)

    // temp message object
    let tempMessage = {
        id: tempMessageId,
        msgKey: tempMessageId,
        message_category: userData?.messageObj?.messageCategory || userData?.messageCategory || 0,
        message_text: userData?.messageObj?.message_text || userData?.chatText,
        message_raw_text: JSON.stringify(userData?.messageObj?.chatRawContent || userData?.chatRawContent),
        chat_mentions: userData?.messageObj?.mentions,
        chat_links: userData?.messageObj?.links,
        message_from: userData?.userId,
        created_by: userData?.userId,
        user_id: userData?.userId,
        delivered_status: 0,
        read_status: 0,
        reactions: null,
        star: 0,
        star_user_id: null,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        name: userData?.name,
        username: userData?.username,
        avatar_filename: userData?.profileImage,
        show_infiniti_loader: (!userData?.infinitySendClicked && userData?.activateInfinity) || userData.is_infiniti_room || (userData?.activateInfinity && userData?.messageObj?.get_welcome_message),
        is_infiniti_res: userData.is_infiniti_room ? 1 : (userData?.messageObj?.is_infiniti_res || (userData?.messageObj?.get_welcome_message && userData?.activateInfinity) || 0),
        is_infiniti_room: userData.is_infiniti_room || false,
        is_selfGroup: userData?.is_selfGroup || userData?.messageObj?.is_selfGroup || 0,
        ...(userData?.messageObj?.ext_objs) || {}
    }

    // new chat message object
    let chatMessageObj = {
        jobId: userData?.id,
        userId: userData?.userId,
        message: userData?.messageObj,
        messageCategory: userData?.messageObj?.messageCategory || 0,
        isReply: false,
        replyMsgId: null,
        agencyId: userData?.agency_pref,
        msgKey: tempMessage?.msgKey,
        isForward: false,
        forwardmsgId: null,
        isForward_type: null,
        is_infiniti_room: userData.is_infiniti_room,
        chat_title: userData?.chat_title || "",
        username: userData?.username || "",
        is_selfGroup: userData?.is_selfGroup || userData?.messageObj?.is_selfGroup || 0,
    };
    let reply_details={
      message_text:userData?.reply?.message_text,
      message_category:userData?.reply?.message_category,
      username:userData?.reply?.username,
      message_file:userData?.reply?.message_file,
      user_id:userData?.reply?.user_id,
      first_name:userData?.reply?.first_name,
      last_name:userData?.reply?.last_name,
      id:userData?.reply?.id
  }
    if ((!userData?.infinitySendClicked && userData?.activateInfinity) || (userData?.messageObj?.get_welcome_message && userData?.activateInfinity)) {
        chatMessageObj = {
            ...chatMessageObj,
            mode: 'infiniti',
            message_from: userData?.userId
        }

        tempMessage['show_infiniti_loader'] = true
        tempMessage['is_infiniti_res'] = 1
    }

    if (userData?.infinitySendClicked) {
        chatMessageObj = {
            ...chatMessageObj,
        }
    }

    if (userData?.reply && userData?.reply.id) {
        chatMessageObj["isReply"] = JSON.stringify(reply_details);
        chatMessageObj["replyMsgId"] = userData?.reply.id;
        tempMessage['is_reply'] = JSON.stringify(reply_details);
        tempMessage['reply_message_id']=userData?.reply?.id

    }

    return {
        chatMessageObj,
        tempMessage
    }
}

export const handleChatSendHelper = (userData) => {
    const payloadData = getParamsHelper(userData)
    const params = {
        skip: 1,
        cur_type: userData.type,
        room_id: userData.id
    }
    const serviceName = SENT_MESSAGE_API_SERVICE_NAME[userData.type]
    let data = { payloadData, params, serviceName }
    return data
}

export const formatFileSize = (size) => {
    if (size >= 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
    } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + ' KB';
    } else {
        return '';
    }
}
export const mediaCompresser = async (file) => {
    try {
        let blob = null;
        let fileObject = null;
        if (file.type.startsWith("video")) {
            blob = await new Promise((resolve, reject) => {
                const vid = document.createElement("video");
                vid.height = 200;
                vid.width = 200;
                vid.setAttribute('crossorigin', 'anonymous');
                const fileURL = URL.createObjectURL(file);
                vid.src = fileURL;
                vid.style.display = "none";
                document.body.appendChild(vid);

                vid.addEventListener("loadedmetadata", () => {
                    const seekTime = vid.duration * 0.05;
                    vid.currentTime = seekTime;
                });

                vid.addEventListener("error", reject);

                vid.addEventListener("seeked", () => {
                    const aspectRatio = vid.videoWidth / vid.videoHeight;
                    const canvas = document.createElement("canvas");
                    const maxWidth = 500;
                    const maxHeight = Math.floor(maxWidth / aspectRatio);
                    canvas.width = Math.min(vid.videoWidth, maxWidth);
                    canvas.height = Math.min(vid.videoHeight, maxHeight);
                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((e) => {
                        resolve(e);
                        URL.revokeObjectURL(fileURL);
                        document.body.removeChild(vid);
                    }, "image/jpeg", 0.7);
                });
            });

            fileObject = new File([blob], file.name + "_thumb", { type: file.type });
        } else if (file.type.startsWith("image")) {
            if (file.type === "image/svg+xml") {
                const text = await file.text();
                const img = new Image();
                img.src = `data:image/svg+xml;base64,${btoa(text)}`;
                
                // Wait for the image to load
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });

                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                blob = await new Promise((res) => canvas.toBlob(res, "image/jpeg", 0.7));
                fileObject = new File([blob], file.name + "_thumb", { type: "image/jpeg" });

            } else {
                // Handle other image types (JPEG, PNG, etc.)
                const originalSizeMB = file.size / (1024 * 1024);
                let targetSizeMB;

                if (originalSizeMB >= 10) {
                    targetSizeMB = originalSizeMB * 0.2;
                } else if (originalSizeMB >= 3) {
                    targetSizeMB = originalSizeMB * 0.5;
                } else {
                    targetSizeMB = originalSizeMB * 0.7;
                }

                const quality = Math.max(0.1, targetSizeMB / originalSizeMB);
                let bitmap = await createImageBitmap(file, {
                    resizeQuality: "low"
                });

                const canvas = document.createElement('canvas');
                canvas.width = bitmap.width;
                canvas.height = bitmap.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(bitmap, 0, 0);

                blob = await new Promise((res) => canvas.toBlob(res, "image/jpeg", quality));
                fileObject = new File([blob], file.name + "_thumb", { type: file.type });
            }
        }

        return fileObject;
    } catch (error) {
        console.log("Image Compress error:", error);
    }
};
export const isValidJSON = (jsonString) => {
    if (typeof jsonString !== 'string') {
      return false;
    }
    const trimmed = jsonString.trim();
    if (trimmed === '' || !(trimmed.startsWith('{') || trimmed.startsWith('['))) {
      return false;
    }
    try {
      JSON.parse(trimmed);
      return true;
    } catch (e) {
      return false;
    }
  }
  
export const getThumbnail = async (file) => {
    let blob = null;

    try {
        if (file.type.startsWith("video")) {
            blob = await new Promise((resolve, reject) => {
                const vid = document.createElement("video");
                vid.autoplay = true;
                vid.muted = true;
                vid.height = 200;
                vid.width = 200;
                vid.setAttribute('crossorigin', 'anonymous');
                const fileURL = URL.createObjectURL(file);
                vid.src = fileURL;
                vid.style.display = "none";
                document.body.appendChild(vid);

                vid.addEventListener("loadeddata", () => {
                    const seekTime = vid.duration * 0.05;
                    vid.currentTime = seekTime;
                });

                vid.addEventListener("error", (error) => {
                    reject(error);
                    URL.revokeObjectURL(fileURL);
                    document.body.removeChild(vid);
                });

                vid.addEventListener("seeked", () => {
                    const aspectRatio = vid.videoWidth / vid.videoHeight;
                    const canvas = document.createElement("canvas");
                    const maxWidth = 500;
                    const maxHeight = Math.floor(maxWidth / aspectRatio);
                    canvas.width = Math.min(vid.videoWidth, maxWidth);
                    canvas.height = Math.min(vid.videoHeight, maxHeight);
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((e) => {
                        resolve(e);
                        URL.revokeObjectURL(fileURL);
                        document.body.removeChild(vid);
                    }, "image/jpeg", 0.7);
                });
            });
        } else if (file.type.startsWith("image")) {
            let bitmap = await createImageBitmap(file, {
                resizeWidth: 300, 
                resizeHeight: 300, 
                resizeQuality: "medium"
            });
            const canvas = document.createElement('canvas');
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(bitmap, 0, 0);
            blob = await new Promise((res) => canvas.toBlob(res, "image/jpeg", 0.7));
        } else {
            return null;
        }

        let fileObject = new File([blob], `${file.name}`, { type: "image/jpeg" });

        const thumbPresignedUrl = await axios.post(_api.url + "/file-hub/get-presigned-urls", {
            resource_id: file.resource_id,
            resource_type: file.resource_type,
            files: [`${file.name}_thumb`],
            folder_id: file.folder_id
        }, {
            headers: {
                Authorization: "Bearer " + getToken(),
            },
        });

        await axios.put(thumbPresignedUrl.data.data[0], fileObject, {
            headers: {
                'Content-Type': fileObject.type
            },
        });

        return thumbPresignedUrl.data.data[0].split("?X-Amz-Algorithm")[0];

    } catch (error) {
        console.error("Error generating thumbnail:", error);
        return null;
    }
};

const getStyle = (text, inlineStyle, data) => {
    if (inlineStyle?.style) {
      switch (inlineStyle?.style) {
        case "BOLD":
          return { text, bold: true };
        case "ITALIC":
          return { text, italic: true };
        default:
          return { text };
      }
    } else if (inlineStyle?.key !== undefined) {
      const entityData = data?.entityMap[inlineStyle?.key];
      switch (entityData?.type) {
        case "mention":
          return {
            character: text,
            type: entityData?.type,
            data: entityData?.data,
            children: [{ text: "" }],
          };
        default:
          return { text };
      }
    } else {
      return { text };
    }
  };
  
  const splitInlineStyleRanges = (text, inlineStyleRanges, data) => {
    if (inlineStyleRanges.length > 0) {
      let currentOffset = 0;
      let allRanges = [];
      for (let i = 0; i < inlineStyleRanges.length; i++) {
        const { offset, length } = inlineStyleRanges[i];
        if (currentOffset < offset) {
          allRanges.push({
            offset: currentOffset,
            length: offset - currentOffset,
          });
          allRanges.push({
            offset,
            length,
          });
          currentOffset = offset + length;
        }
      }
      // last line push
      if (currentOffset < text.length) {
        allRanges.push({
          offset: currentOffset,
          length: text.length - currentOffset,
        });
      }
      const splits = allRanges.reduce((a, b) => {
        a.push({
          ...getStyle(
            text.substr(b.offset, b.length),
            inlineStyleRanges.find(
              (f) => f.offset === b.offset && f.length === b.length
            ),
            data
          ),
        });
        return a;
      }, []);
      return splits;
    } else {
      return [{ text }];
    }
  };
  
  export const draftToSlate = (props) => {
    const { data } = props;
    if (data?.blocks && data?.blocks?.length > 0) {
      const converted = data?.blocks?.reduce((a, b) => {
        if (b?.text !== undefined) {
          const blocks = splitInlineStyleRanges(
            b?.text,
            [...b?.inlineStyleRanges, ...b?.entityRanges],
            data
          ).map((m) => {
            return {
              ...m,
            };
          });
          a.push({
            type: "paragraph",
            children: blocks,
          });
        }
        return a;
      }, []);
      return converted;
    } else if (data?.length) {
      if(typeof data === 'string') {
        if(isValidJSON(data)){
          JSON.parse(data)
        } else {
          return([{
            type: "paragraph",
            children: [{text: data}]
          }])
        }
        // return isValidJSON(data) ? JSON.parse(data) : data;
      } else {
        return data;
      }
    } else {
      return [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];
    }
  };

