import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography, withStyles } from "@material-ui/core";
import DeleteDialogStyles from "./styles";

//Import Component
import { Button } from "../Button";

/**
 *Delete Dialog Component
 *
 * @class DeleteDialog
 * @extends {React.Component}
 */
function DeleteDialog(props) {
    /** Handle Dialog close Action  */

    const { classes, open, content, handleDeleteAction, deleteButtonTitle, buttonAlignmentType, closeButtonTitle, handleCloseAction, fromDocs, fullWidth, maxWidth } = props;

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleCloseAction();
    };

    /** Handle Dialog Delete Action */
    const handleDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDeleteAction();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ borderRadius: "12px !important", zIndex: 9999 }}
            className={`${classes.MuiBackdropRoot} ${classes.MuiPaperRounded}`}
            BackdropProps={{
                classes: {
                    root: fromDocs ? classes.customBackdrop : ""  ,
                },
            }}

            fullWidth={fullWidth}
            maxWidth={maxWidth}
        >
            {/* <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle> */}
            <DialogContent style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                {/* <DialogContentText id="alert-dialog-description"> */}
                <Typography color="primary" style={{ textAlign: 'center' }} className={fromDocs ? classes.dialogContentTextForDoc : classes.dialogContentText}>
                    {content}
                </Typography>


                {/*
                    </DialogContentText> */}
            </DialogContent>
            <DialogActions  className={"justifyCenter pb-2"}>
                <Button
                
                    onClick={buttonAlignmentType === 1 ? handleClose : handleDelete}
                    // variant={buttonAlignmentType === 1 ? closeButtonVariant ? closeButtonVariant : "contained" : deleteButtonVariant ? deleteButtonVariant : "contained"}
                    // color={buttonAlignmentType === 1 ? "secondary" : "primary"}
                    // size="large"
                    disableElevation
                    autoFocus
                    className='pl-3 pr-3 pt-1 pb-1 fw-500'
                    variant="outlined"
                    style={{ fontSize: '14px !important', minWidth: '90px' }}
                    color='primary'
                >
                    {buttonAlignmentType === 1 ? (closeButtonTitle ? closeButtonTitle : 'No') : (deleteButtonTitle ? deleteButtonTitle : 'Yes')}
                </Button>
                <Button
                 
                    onClick={buttonAlignmentType === 1 ? handleDelete : handleClose}
                    // variant={buttonAlignmentType === 1 ? deleteButtonVariant ? deleteButtonVariant : "contained" : closeButtonVariant ? closeButtonVariant : "contained"}
                    disableElevation
                    // size="large"
                    className='blueBtn pl-3 pr-3 pt-1 pb-1 fw-500'

                    variant="contained"
                    style={{ fontSize: '14px !important', minWidth: '90px' }}
                    color='primary'
                // color={buttonAlignmentType === 1 ? "primary" : "secondary"}
                >
                    {buttonAlignmentType === 1 ? (deleteButtonTitle) : (closeButtonTitle)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// default props
DeleteDialog.defaultProps = {
    open: false,
    deleteButtonTitle: 'Delete',
    closeButtonTitle: 'Cancel',
    buttonAlignmentType: 1,
    className: null,
    id: null,
    fromDocs : false,
    content: "Are you sure you want to delete this record?",
    handleDeleteAction: () => { },
    handleCloseAction: () => { }
};

// prop types
DeleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    deleteButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
    className: PropTypes.any,
    id: PropTypes.any,
    buttonAlignmentType: PropTypes.any,
    handleDeleteAction: PropTypes.func.isRequired,
    handleCloseAction: PropTypes.func.isRequired,
    maxWidth: PropTypes.string,
    fullWidth: PropTypes.bool
};

/** Export Component */
export const DeleteDialogComponent = withStyles(DeleteDialogStyles)(DeleteDialog);