export const projectConstant = {

    //create project
    CREATE_PROJECT_R: "CREATE_PROJECT_R",
    CREATE_PROJECT_S: "CREATE_PROJECT_S",
    CREATE_PROJECT_F: "CREATE_PROJECT_F",

    CREATE_PROJECT_UPDATE: "CREATE_PROJECT_UPDATE",

    //GET PROJECTS
    GET_PROJECTS_R: "GET_PROJECTS_R",
    GET_PROJECTS_S: "GET_PROJECTS_S",
    GET_PROJECTS_F: "GET_PROJECTS_F",

    GET_TAGS_R: "GET_TAGS_R",
    GET_TAGS_S: "GET_TAGS_S",
    GET_TAGS_F: "GET_TAGS_F",

    //GET PROJECT_DETAIL
    GET_PROJECT_DETAIL_R: "GET_PROJECT_DETAIL_R",
    GET_PROJECT_DETAIL_S: "GET_PROJECT_DETAIL_S",
    GET_PROJECT_DETAIL_F: "GET_PROJECT_DETAIL_F",

    //GET_PROJECT_FILTER
    GET_PROJECT_FILTER_R: "GET_PROJECT_FILTER_R",
    GET_PROJECT_FILTER_S: "GET_PROJECT_FILTER_S",
    GET_PROJECT_FILTER_F: "GET_PROJECT_FILTER_F",

    //update project
    UPDATE_CREATE_PROJECT: "UPDATE_CREATE_PROJECT",

    //update task
    UPDATE_P_CREATE_TASK: "UPDATE_P_CREATE_TASK",

    //update drag and drop
    UPDATE_DND_R: "UPDATE_DND_R",
    UPDATE_DND_S: "UPDATE_DND_S",
    UPDATE_DND_F: "UPDATE_DND_F",

    //complete project
    COMPLETE_PROJECT_R: "COMPLETE_PROJECT_R",
    COMPLETE_PROJECT_S: "COMPLETE_PROJECT_S",
    COMPLETE_PROJECT_F: "COMPLETE_PROJECT_F",

    //archive project
    ARCHIVE_PROJECT_R: "ARCHIVE_PROJECT_R",
    ARCHIVE_PROJECT_S: "ARCHIVE_PROJECT_S",
    ARCHIVE_PROJECT_F: "ARCHIVE_PROJECT_F",

    //remove project
    REMOVE_PROJECT_R: "REMOVE_PROJECT_R",
    REMOVE_PROJECT_S: "REMOVE_PROJECT_S",
    REMOVE_PROJECT_F: "REMOVE_PROJECT_F",

    /********************************BRIEF DETAILS**************************************************************** */

    //GET BRIEF
    GET_BRIEF_R: "GET_BRIEF_R",
    GET_BRIEF_S: "GET_BRIEF_S",
    GET_BRIEF_F: "GET_BRIEF_F",

    //UPDATE BRIEF
    UPDATE_BRIEF_R: "UPDATE_BRIEF_R",
    UPDATE_BRIEF_S: "UPDATE_BRIEF_S",
    UPDATE_BRIEF_F: "UPDATE_BRIEF_F",

    //update brief
    UPDATE_BRIEF_REDUCER: "UPDATE_BRIEF_REDUCER",

    //duplicate flow
    UPDATE_PROJECT_CLONE_REDUCER: "UPDATE_PROJECT_CLONE_REDUCER",

    //create duplicate project
    CREATE_CLONE_PROJECT_R: "CREATE_CLONE_PROJECT_R",
    CREATE_CLONE_PROJECT_S: "CREATE_CLONE_PROJECT_S",
    CREATE_CLONE_PROJECT_F: "CREATE_CLONE_PROJECT_F",

    //GET PROJECT TEAM USERS
    UPDATE_PROJECT_CLONE_ACTIVE_TEAM_S: "UPDATE_PROJECT_CLONE_ACTIVE_TEAM_S",

    /********************************NOTES DETAILS**************************************************************** */

    //UPDATE NOTE
    UPDATE_NOTE_R: "UPDATE_NOTE_R",
    UPDATE_NOTE_S: "UPDATE_NOTE_S",
    UPDATE_NOTE_F: "UPDATE_NOTE_F",

    //ADD NOTE
    ADD_NOTE_R: "ADD_NOTE_R",
    ADD_NOTE_S: "ADD_NOTE_S",
    ADD_NOTE_F: "ADD_NOTE_F",

    //update new note data 
    UPDATE_NOTE_REDUCER: "UPDATE_NOTE_REDUCER",
    //clear selected note when close editor
    CLEAR_SELECTED_NOTE: "CLEAR_SELECTED_NOTE",

    //GET ALL NOTES
    GET_ALL_NOTES_R: "GET_ALL_NOTES_R",
    GET_ALL_NOTES_S: "GET_ALL_NOTES_S",
    GET_ALL_NOTES_F: "GET_ALL_NOTES_F",

    //GET ONE NOTES
    GET_ONE_NOTES_R: "GET_ONE_NOTES_R",
    GET_ONE_NOTES_S: "GET_ONE_NOTES_S",
    GET_ONE_NOTES_F: "GET_ONE_NOTES_F",

    //UPDATE NOTE
    SHARE_NOTE_R: "SHARE_NOTE_R",
    SHARE_NOTE_S: "SHARE_NOTE_S",
    SHARE_NOTE_F: "SHARE_NOTE_F",

    //delete current task
    DEL_CURRENT_NOTE_R: "DEL_CURRENT_NOTE_R",
    DEL_CURRENT_NOTE_S: "DEL_CURRENT_NOTE_S",
    DEL_CURRENT_NOTE_F: "DEL_CURRENT_NOTE_F",

    UPDATE_NOTE_REDUCER_TITLE: "UPDATE_NOTE_REDUCER_TITLE",

    /************************************TEAMS DETAILS*********************************************************** */

    UPDATE_P_TEAM_USERS: "UPDATE_P_TEAM_USERS",

    //GET ALL TEAM MEMBERS
    GET_TEAM_R: "GET_TEAM_R",
    GET_TEAM_S: "GET_TEAM_S",
    GET_TEAM_F: "GET_TEAM_F",

    //GET ALL TEAM MEMBERS
    GET_ACTIVE_TEAM_R: "GET_ACTIVE_TEAM_R",
    GET_ACTIVE_TEAM_S: "GET_ACTIVE_TEAM_S",
    GET_ACTIVE_TEAM_F: "GET_ACTIVE_TEAM_F",

    USER_EXISTING_INVITE_R: "USER_EXISTING_INVITE_R",
    USER_EXISTING_INVITE_S: "USER_EXISTING_INVITE_S",
    USER_EXISTING_INVITE_F: "USER_EXISTING_INVITE_F",

    USER_NEW_INVITE_R: "USER_NEW_INVITE_R",
    USER_NEW_INVITE_S: "USER_NEW_INVITE_S",
    USER_NEW_INVITE_F: "USER_NEW_INVITE_F",

    REMOVE_TEAM_USER_R: "REMOVE_TEAM_USER_R",
    REMOVE_TEAM_USER_S: "REMOVE_TEAM_USER_S",
    REMOVE_TEAM_USER_F: "REMOVE_TEAM_USER_F",

    REMOVE_USER_FROM_AGENCY_R: "REMOVE_USER_FROM_AGENCY_R",
    REMOVE_USER_FROM_AGENCY_S: "REMOVE_USER_FROM_AGENCY_S",
    REMOVE_USER_FROM_AGENCY_F: "REMOVE_USER_FROM_AGENCY_F",

    //GET PROJECT TEAM USERS
    GET_PROJECT_TEAM_R: "GET_PROJECT_TEAM_R",
    GET_PROJECT_TEAM_S: "GET_PROJECT_TEAM_S",
    GET_PROJECT_TEAM_F: "GET_PROJECT_TEAM_F",

    //GET PROJECT SHARED USERS
    GET_PROJECT_SHARED_R: "GET_PROJECT_SHARED_R",
    GET_PROJECT_SHARED_S: "GET_PROJECT_SHARED_S",
    GET_PROJECT_SHARED_F: "GET_PROJECT_SHARED_F",

    //NEW_USER_ACCEPT_INVITATION
    NEW_USER_ACCEPT_R: "NEW_USER_ACCEPT_R",
    NEW_USER_ACCEPT_S: "NEW_USER_ACCEPT_S",
    NEW_USER_ACCEPT_F: "NEW_USER_ACCEPT_F",

    G_NEW_USER_ACCEPT_R: "G_NEW_USER_ACCEPT_R",
    G_NEW_USER_ACCEPT_S: "G_NEW_USER_ACCEPT_S",
    G_NEW_USER_ACCEPT_F: "G_NEW_USER_ACCEPT_F",

    /***************************************TASK DETAILS****************************************************** */

    //create new task
    CREATE_NEW_TASK_R: "CREATE_NEW_TASK_R",
    CREATE_NEW_TASK_S: "CREATE_NEW_TASK_S",
    CREATE_NEW_TASK_F: "CREATE_NEW_TASK_F",

    //get all task
    GET_ALL_TASK_R: "GET_ALL_TASK_R",
    GET_ALL_TASK_S: "GET_ALL_TASK_S",
    GET_ALL_TASK_F: "GET_ALL_TASK_F",

    //get current task
    GET_CURRENT_TASK_R: "GET_CURRENT_TASK_R",
    GET_CURRENT_TASK_S: "GET_CURRENT_TASK_S",
    GET_CURRENT_TASK_F: "GET_CURRENT_TASK_F",

    //delete current task
    DEL_CURRENT_TASK_R: "DEL_CURRENT_TASK_R",
    DEL_CURRENT_TASK_S: "DEL_CURRENT_TASK_S",
    DEL_CURRENT_TASK_F: "DEL_CURRENT_TASK_F",

    //update current task
    UPDATE_CURRENT_TASK: "UPDATE_CURRENT_TASK",

    UPATE_FILTER_DATA: "UPATE_FILTER_DATA",

    UPATE_CARD_VIEW: "UPATE_CARD_VIEW",

    //update task
    UPDATE_CURRENT_TASK_R: "UPDATE_CURRENT_TASK_R",
    UPDATE_CURRENT_TASK_S: "UPDATE_CURRENT_TASK_S",
    UPDATE_CURRENT_TASK_F: "UPDATE_CURRENT_TASK_F",

    //filter
    GET_TASKS_FILTER_R: "GET_TASKS_FILTER_R",
    GET_TASKS_FILTER_S: "GET_TASKS_FILTER_S",
    GET_TASKS_FILTER_F: "GET_TASKS_FILTER_F",

    //complete task
    COMPLETE_TASK_R: "COMPLETE_TASK_R",
    COMPLETE_TASK_S: "COMPLETE_TASK_S",
    COMPLETE_TASK_F: "COMPLETE_TASK_F",

    //GET_P_TASK_TAGS
    GET_P_TASK_TAGS_R: "GET_P_TASK_TAGS_R",
    GET_P_TASK_TAGS_S: "GET_P_TASK_TAGS_S",
    GET_P_TASK_TAGS_F: "GET_P_TASK_TAGS_F",

    //create task notes
    CREATE_TASK_NOTE_R: "CREATE_TASK_NOTE_R",
    CREATE_TASK_NOTE_S: "CREATE_TASK_NOTE_S",
    CREATE_TASK_NOTE_F: "CREATE_TASK_NOTE_F",

    //create task notes
    GET_TASK_NOTE_R: "GET_TASK_NOTE_R",
    GET_TASK_NOTE_S: "GET_TASK_NOTE_S",
    GET_TASK_NOTE_F: "GET_TASK_NOTE_F",

    //delete task notes
    DELETE_TASK_NOTE_R: "DELETE_TASK_NOTE_R",
    DELETE_TASK_NOTE_S: "DELETE_TASK_NOTE_S",
    DELETE_TASK_NOTE_F: "DELETE_TASK_NOTE_F",

    //update note title changes
    UPDATE_NOTE_TITLE_R: "UPDATE_NOTE_TITLE_R",
    UPDATE_NOTE_TITLE_S: "UPDATE_NOTE_TITLE_S",
    UPDATE_NOTE_TITLE_F: "UPDATE_NOTE_TITLE_F",

    //update deleted note
    DELETE_NOTE_R: "DELETE_NOTE_R",
    DELETE_NOTE_S: "DELETE_NOTE_S",
    DELETE_NOTE_F: "DELETE_NOTE_F",

    //update pin note to the reducer
    PIN_NOTE_R: "PIN_NOTE_R",
    PIN_NOTE_S: "PIN_NOTE_S",
    PIN_NOTE_F: "PIN_NOTE_F",
    
    //update duplicated note reducer
    DUPLICATE_NOTE_R: "DUPLICATE_NOTE_R",
    DUPLICATE_NOTE_S: "DUPLICATE_NOTE_S",
    DUPLICATE_NOTE_F: "DUPLICATE_NOTE_F",

    //update note data in reducer
    NOTE_DATA_UODATE_P: "NOTE_DATA_UODATE_P"
};
