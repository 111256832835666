// Import API Helper
import { postRequest, getRequest, putRequest, getHTMLRequest, deleteRequest } from "../../utils/apiHelper";

export const pageBuilderService = {
  createSite,
  listSites,
  getSite,
  updateSite,
  createPage,
  getPage,
  updatePage,
  createTemplate,
  listTemplates,
  listCategory,
  publishSite,
  loadPreviewContent,
  loadPreviewImage,
  deletePage,
  deleteSite,
  unPublishSite,
  getPageAnalyticsChart,
  getFormList,
  getFormResponse,
  listActiveSite,
  getPages,
  getSiteCustomCode,
  updateSiteCustomCode,
  saveEmailConfig,
  getEmailConfig,
  testEmailConfig,
  getSiteSeoSettings,
  updateSiteSeoSettings,
  infinityAI,
  validateCode,
  allowedDomains,
  compressThumbnail,
  listGoogleFont,
  getDocs,
  getDocAccess
};

async function createSite(params) {
  const response = await postRequest(
    `site/create`,
    params,
    false,
    "json",
    "pageBuilder"
  );
  return response;
}

async function listSites(searchKey) {
  const response = await getRequest(`site/list?query=${searchKey}`, "pageBuilder");
  return response;
}

async function getSite(id) {
  const response = await getRequest(`site/detail/${id}`, "pageBuilder");
  return response;
}

async function updateSite(id, params) {
  const response = await putRequest(`site/${id}`, params, false, "pageBuilder");
  return response;
}

async function createPage(params) {
  const response = await postRequest(
    `page/create`,
    params,
    false,
    "json",
    "pageBuilder"
  );
  return response;
}

async function getPage(id) {
  const response = await getRequest(`page/detail/${id}`, "pageBuilder");
  return response;
}

async function updatePage(id, params) {
  const response = await putRequest(
    `page/update/${id}`,
    params,
    false,
    "pageBuilder"
  );
  return response;
}

async function createTemplate(params) {
  const response = await postRequest(
    `templates/create`,
    params,
    true,
    "json",
    "pageBuilder"
  );
  return response;
}

async function listTemplates(query = '') {
  let from = window?.location?.href
  if (from?.split('#')?.length > 0) {
    from = from?.split('#')[1]
    if (query && query?.indexOf('?') >= 0) {
      from = `&from=${from}`
    } else {
      from = `?from=${from}`
    }
  } else {
    from = ''
  }
  
  const response = await getRequest(`templates/list${query}${from}`, "pageBuilder");
  return response;
}

async function listCategory() {
  let from = window?.location?.href
  if (from?.split('#')?.length > 0) {
    from = from?.split('#')[1]
    from = `?from=${from}`
  } else {
    from = ''
  }
  const response = await getRequest(`templates/category${from}`, "pageBuilder");
  return response;
}

async function compressThumbnail(query = '') {
  const response = await getRequest(`templates/compress_thumbnail${query}`,"pageBuilder");
  return response;
}

async function listGoogleFont(query = '') {
  const response = await getRequest(`getfonts/goolgelist`, "pageBuilder");
  return response;
}

async function publishSite(site_id = 0, page_id = 0, params = {}) {
  const response = await postRequest(
    `site/publish/${site_id}/${page_id}`,
    params,
    true,
    "json",
    "pageBuilder"
  );
  return response;
}

async function loadPreviewContent(site_id = 0, page_url = "") {
  const response = await getHTMLRequest(
    `site/preview/${site_id}/${page_url}`,
    "pageBuilder"
  );
  return response;
}

async function loadPreviewImage(site_id, page_url = "home") {
  const response = await getHTMLRequest(
    `/page/screenshot/${site_id}/${page_url}`,
    "pageBuilder"
  );
  return response;
}

async function deletePage(params) {
  const { siteId, pageId } = params;
  const response = await deleteRequest(`page/delete/${siteId}/${pageId}`, {}, "pageBuilder")
  return response
}

async function deleteSite(params) {
  const response = await deleteRequest(`site/delete/${params}`, {} ,"pageBuilder")
  return response
}


async function unPublishSite(site_id) {
  const response = await postRequest(
    `site/un-publish/${site_id}`,
    {},
    false,
    "json",
    "pageBuilder"
  );
  return response
}

async function getPageAnalyticsChart(site_id, params) {
  const response = await postRequest(
    `analytics/report/${site_id}`,
    params,
    false,
    "json",
    "pageBuilder"
  );
  return response
}

async function getFormList(site_id, body) {
  const response = await postRequest(`form/list/${site_id}`, body, false, "json", "pageBuilder" )
  return response
}

async function getFormResponse(form_id, body) {
  const response = await postRequest(`form/response/${form_id}`, body, false, "json", "pageBuilder")
  return response
}

async function listActiveSite(id) {
  const response = await getRequest(`site/active-site`, "pageBuilder");
  return response;
}

async function getPages(siteId, pageId) {
  const response = await getRequest(`page/links/${siteId}/${pageId}`, "pageBuilder");
  return response;
}

async function getSiteCustomCode(id) {
  const response = await getRequest(`site/custom-code/${id}`, "pageBuilder");
  return response;
}

async function updateSiteCustomCode(id, params) {
  const response = await putRequest(
    `site/custom-code/${id}`,
    params,
    false,
    "pageBuilder"
  );
  return response;
}

async function getSiteSeoSettings(id) {
  const response = await getRequest(`site/seo-settings/${id}`, "pageBuilder");
  return response;
}

async function updateSiteSeoSettings(id, params) {
  const response = await putRequest(
    `site/seo-settings/${id}`,
    params,
    false,
    "pageBuilder"
  );
  return response;
}

async function saveEmailConfig(id, params) {
  const response = await putRequest(`site/email-config/${id}`, params, false, "pageBuilder");
  return response;
}

async function getEmailConfig(id) {
  const response = await getRequest(`site/email-config/${id}`, "pageBuilder");
  return response;
}

async function testEmailConfig(params) {
  const response = await postRequest(`site/test-email-config`, params, false, "json", "pageBuilder");
  return response;
}

async function infinityAI(params) {
  const response = await postRequest(`infiniti/base`, params);
  return response;
}

async function validateCode(params) {
  const response = await postRequest(
    `editor/validate-code`,
    params,
    false,
    "json",
    "pageBuilder"
  );
  return response;
}

async function allowedDomains() {
  const response = await getRequest(`editor/allowed-domains`, "pageBuilder");
  return response;
}

async function getDocs(params) {
  const { search, skip, limit } = params;
  let url = `embedding/getDocs?search=${encodeURIComponent(search || '')}&skip=${skip}&limit=${limit}`;
  const response = await getRequest(url, "pageBuilder");
  return response;
}

async function getDocAccess(params) {
  const { docId } = params;
  let url = `embedding/getDocsAccess/${encodeURIComponent(docId)}}`;
  const response = await getRequest(url, "pageBuilder");
  return response;
}
