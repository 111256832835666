import { Checkbox, FormControlLabel, Grid, Icon, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Radio, RadioGroup, Zoom, withStyles, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// styles
import { CalendarIconNew, ChevronIcon, ChevronDown } from '../../assets/svg';
import { DownChevronIcon, UpChevronIcon } from '../../assets/svg/task/ChevronIcon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Styles from './styles';

//Import Component
import { AutoComplete, DatePickers, Select, TextField, Typography } from '../';

// constants
import { DAY_OPTIONS, END_TYPES, MONTH, MONTH_OPTIONS, REPEAT_OPTIONS, TASK_WEEK_OPTIONS, TIME_ZONE_OPTIONS, WEEK_OPTIONS } from '../../config/constants';
// import { isMobile } from 'react-device-detect';
import { isMobile } from 'react-device-detect';

const RecurringComponent = (props) => {

    //get props
    const { classes, updateData, data, hideFields, startDate, endDate, module } = props;
    console.log("updateData", updateData, "data", data)

    // variables
    const scheduleType = Number(data.schedule_type);


    const handleButtons = (type, field) => {
        let currentValue = Number(data[field]) || 0; 

        const max = field === "occurance_day" ? 31 : Infinity;
        const min=0      
        if (type === 'increment' && currentValue < max) {
          currentValue += 1;
        } else if (type === "decrement" && currentValue > min) {
          currentValue -= 1;
        }
      
        updateData({ name: field, value: currentValue });
      };

    // render all the recurring options
    const RenderRecurringOptions = (OPTIONS, title, name) => {

        // update options
        const updateSelection = (value) => {
            let selectionList = data[name] || [];
            const selIndex = selectionList.indexOf(value)
            if (selIndex > -1) {
                selectionList.splice(selIndex, 1)
            } else {
                selectionList.push(value)
            }
            updateData({ name, value: selectionList })
        }
        return (
            <Grid container className={`${classes.repeatCont} pt-1`} >
                <Grid item xs={12} className="mb-1">
                    <Typography variant="body1" className={classes.recurringHeaders}>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <List className={`${classes.repeatDayCont}`}>
                        <Grid container spacing={1}>
                            {[...OPTIONS].map((value, index) => {

                                const labelId = `checkbox-list-label-${value.value}`;
                                const isChecked = (data[name] || []).indexOf(value.value) > -1;

                                return (
                                    <Grid item xs={3} sm={2} md={1}>
                                        <ListItem
                                            key={`e-d-${index}`}
                                            className={`${isChecked ? classes.selectedDay : ""} ${classes.repeatDays}`}
                                            dense
                                            button
                                            onClick={() => { updateSelection(value.value) }}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={isChecked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={value.label} />
                                        </ListItem>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </List>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className='p-2 overflowYAuto'>
            <Grid className='dflex' item xs={12}>
            <Grid item xs={3} lg={3} container className={classes.repeatCont} >
                <Grid item xs={6} sm={9}>
                    <Grid container className='dflex justifyBetween'>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="primary" className="fw-500">Repeat</Typography>
                        </Grid>
                        <Grid item xs={12}>
                                <Select
                                    defaultVal={"Select Options"}
                                    options={REPEAT_OPTIONS}
                                    className={`${classes.repeat} pt12 ${isMobile?'': 'w-200'}`}
                                    handleChange={(v) => { updateData({ name: "schedule_type", value: v.value }) }}
                                    value={data.schedule_type ? data.schedule_type : ''}
                                    IconComponent={(props) => (<KeyboardArrowDownIcon style={{ marginRight: '10px' }} {...props} />)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                {!hideFields.includes("START_DATE") && <Grid item xs={6} sm={4}>
                    <Grid container className='dflex justifyBetween'>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="primary" className="fw-500">Start</Typography>
                        </Grid>
                        <Grid tem xs={12} className={`${classes.calendar}`}>
                            <DatePickers
                                fullWidth
                                name={"start_date"}
                                value={data.start_date ? data.start_date : null}
                                handleChange={(e) => { updateData(e) }}
                                disablePast={true}
                                className={classes.start}
                                InputProps={
                                    {
                                        startAdornment: (
                                            < InputAdornment position="end" style={{ cursor: "pointer" }}>
                                                <Icon position="start" >
                                                    <CalendarIconNew className={classes.taskCalendar} />
                                                </Icon>
                                                {data.start_date ?
                                                    <div className={classes.calendarFont}>{data.start_date}</div>
                                                    :
                                                    <div>{'Select a date'}</div>}
                                            </InputAdornment>
                                        ),
                                        disableUnderline: true
                                    }
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>}
                {!hideFields.includes("TIMEZONE") &&
                    <Grid item sm={4} xs={6}>
                        <Grid container>
                            <Grid tem xs={12}>
                                <Typography variant="body1" color="primary" className="fw-500">Timezone</Typography>
                            </Grid>
                            <Grid tem xs={12}>
                                <AutoComplete
                                    fullWidth
                                    placeholder="Select timezone"
                                    name={"timezone"}
                                    className={classes.timeZone}
                                    suggestions={TIME_ZONE_OPTIONS}
                                    handleAutoComplete={(v) => { updateData({ name: "timezone", value: v.value }) }}
                                    value={data["timezone"] ? TIME_ZONE_OPTIONS.find((val) => val.value === data["timezone"]) : ''}
                                />
                            </Grid>
                        </Grid>
                    </Grid>}
            </Grid>
            <Grid item xs={9} lg={9} className='dflex alignEnd mb-1 pt-2'>
                {([3, 4].includes(scheduleType)) &&
                    <Grid container className={`${classes.repeatCont} mt-1 mb-1`} spacing={2} style={{ marginLeft: 2, paddingLeft:'77px' }}>
                        <RadioGroup value={data.occuranceType} row aria-label="position">
                            <FormControlLabel
                                name="occuranceType"
                                value='1'
                                onChange={(e) => { updateData({ name: "occuranceType", value: "1" }) }}
                                control={<Radio className={data.occuranceType === '1' ? 'radioButton' : null} />}
                                label="Select Multiple Days"
                            />
                            <FormControlLabel
                                name="occuranceType"
                                value='2'
                                onChange={(e) => { updateData({ name: "occuranceType", value: "2" }) }}
                                control={<Radio className={data.occuranceType === '2' ? 'radioButton' : null} />}
                                label="Select Specific Day"
                            />
                        </RadioGroup>
                    </Grid>
                }
            </Grid>
            </Grid>
            {(data.occuranceType === '1' && scheduleType === 4) && RenderRecurringOptions(MONTH_OPTIONS, "Select Months", "recurring_months")}
            {(data.occuranceType === '1' && [3, 4].includes(scheduleType)) && RenderRecurringOptions(module === "TASK" ? TASK_WEEK_OPTIONS : WEEK_OPTIONS, "Select Weeks", "recurring_weeks")}
            {(data.occuranceType === '1' && [2, 3, 4].includes(scheduleType)) && RenderRecurringOptions(DAY_OPTIONS, "Select Day", "recurring_days")}
            {(([3, 4].includes(scheduleType)) && data.occuranceType === '2') &&
                <Grid container className={`${classes.repeatCont} pt-1 smFlex`} >
                    {(scheduleType === 4) &&
                        <Grid item xs={12} sm={4} className='mr-2 pb-1'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="primary" className="fw-500">On Month</Typography>
                                </Grid>
                                <Grid item xs={12} className='smFlex mt-1'>
                                    <Select
                                        className={`${classes.month}`}
                                        options={MONTH}
                                        value={data.occurance_month ? data.occurance_month   : ""}
                                        handleChange={(e) => updateData({ name: 'occurance_month', value: e.value })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <Grid>
                            <Grid item xs={12} className='pb-1'>
                                <Typography variant="body1" color="primary" className="fw-500">On Day</Typography>
                            </Grid>
                            <Grid item className={classes.oneDay} style={{ alignItems: "center" }}>
                                <TextField
                                    type='number'
                                    min={'1'}
                                    value={data.occurance_day}
                                    style={{ width: 115 }}
                                    placeholder="Select"
                                    onChange={e => {
                                        // prevent negative values but accept null value on remove
                                        if (e.target.value === "" || (e.target.value > 0 && e.target.value < 32)) {
                                            updateData({ name: 'occurance_day', value: e.target.value })
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 31
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end" className='dflex flexColumn pb-5 mt-25'>
                                             <IconButton onClick={()=>{handleButtons('increment', 'occurance_day')}} className='p0'>
                                                <UpChevronIcon />
                                              </IconButton>
                                            <IconButton onClick={()=>{handleButtons('decrement', 'occurance_day')}} className='p0'>
                                               <DownChevronIcon />
                                            </IconButton>
                                            </InputAdornment>
                                          )
                                    }}
                                />
                                <Grid className={`mt-1 ml-2 ${classes.inputFieldText}`}>
                                    Pick date from 1 to 31
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Grid item xs={12} sm={12}>
                <Grid container className={`${classes.repeatCont} pt-1`} alignItems='center'>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container className='pt-1'>
                            <Grid item xs={12} sm={6} className='dflex justifyBetween'>
                                <Typography variant="body1" color="primary" className="fw-500 d-flex">Ends</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={10} className={`${classes.endOptions} mt-1`}>
                                {END_TYPES.map((item, index) => (
                                    <Grid
                                        className={`${(!data.end_type && index === 0) || item.value + "" === data.end_type + "" ? classes.selectedTab : classes.normalTab} ${item.value.toLowerCase()}`}
                                        onClick={() => { updateData({ name: 'end_type', value: item.value }) }}>
                                        <Typography className='text textCenter' >{item.label}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={`pt-2`}>
                        {(Number(data.end_type) === 2) &&
                            <Zoom in={true}>
                                <Grid className={`mt-1 ${classes.calenderCont}`} >
                                    <DatePickers
                                        margin="none"
                                        name={`end_at`}
                                        value={data.end_at ? data.end_at : null}
                                        handleChange={(e) => updateData(e)}
                                        style={{ width: 151 }}
                                        minDate={startDate || undefined}
                                        maxDate={endDate || undefined}
                                        InputProps={
                                            {
                                                startAdornment: (
                                                    <InputAdornment position="start" style={{ cursor: "pointer" }} className='dflex flexColumn pb-4'>
                                                        <Icon position="start" >
                                                            <CalendarIconNew className={`${classes.taskCalendar} mt-1`} />
                                                        </Icon>
                                                        {!data.end_date && <div>{'Select a date'}</div>}
                                                        {data.end_date && <div className={`${classes.calendarFont}`} >{data.end_date}</div>}
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true
                                            }
                                        }
                                    />
                                </Grid>
                            </Zoom>
                        }
                        {(Number(data.end_type) === 3) &&
                            <Zoom in={true}>
                                <Grid className='smFlex mt-1' style={{ alignItems: 'center' }}>
                                    <TextField
                                        id={`end_at`}
                                        name={`end_at`}
                                        margin="none"
                                        style={{width: 70}}
                                        type="number"
                                        onWheel={(e) => e?.target?.blur()}
                                        className={classes.afterInput}
                                        onChange={e => {
                                            updateData({ name: 'end_at', value: e.target.value })
                                        }}
                                        value={data.end_at ? data.end_at : ''}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end" className='dflex flexColumn pb-5 mt-25'>
                                                <IconButton onClick={()=>{handleButtons('increment', 'end_at')}} className='p0'>
                                                  <UpChevronIcon />
                                                </IconButton>
                                                <IconButton onClick={()=>{handleButtons('decrement', 'end_at')}} className='p0'>
                                                  <DownChevronIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                    />
                                    <Typography variant="body1" color="primary" className="pl-1">
                                        Occurrence
                                    </Typography>
                                </Grid>
                            </Zoom>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
RecurringComponent.defaultProps = {
    classes: {},
    updateData: () => { },
    data: {},
    hideFields: [],
    startDate: "",
    endDate: "",
    module: ""
};

// prop types
RecurringComponent.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    updateData: PropTypes.func,
    hideFields: PropTypes.array,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    module: PropTypes.string,
};

export const Recurring = withStyles(Styles)(RecurringComponent);