const styles = (theme) => ({
  smallIcon:{
    width:"20px !important",
    height:"20px !important",
    fontSize: '20px !important',
    '& .MuiAvatar-root, & img': {
      objectFit: "cover",
      border: 'none !important',
      width: '20px !important',
      height: '20px !important',
      fontSize: '14px !important'
    }
  },
  avatarletter:{
    '@media only screen and (max-width: 899px)':{
      marginRight:'10px !important'
    }
  },
  extraSmallIcon:{
    width:"20px !important",
    height:"20px !important",
    fontSize: '17px !important',
    '& .MuiAvatar-root, & img': {
      objectFit: "cover",
      border: 'none !important',
      width: '20px !important',
      height: '20px !important',
      fontSize: '14px !important'
    }
  },
  choosenIcon: {
    width: '34px',
    height: '34px',
    borderRadius: '50%',
    marginRight: 10,
    flexShrink: 0
  },
  chosenIcon: {
    width: 40,
    height: 40,
    marginRight:10,
    borderRadius: '50%',
    // marginRight: 10,
    '& .MuiAvatar-root': {
      fontSize: '15px',
      border: '0.6px solid #E8E8E8 !important',
    }
  },
  chosenEmoji: {
    display: "flex",
    fontSize: "1.75rem",
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10
  },
  chosenSchedule: {
    width: "18px",
    height: "18px",
    borderRadius:"15%"
  },
  chosenScheduleColor: {
    width: "16px",
    height: "16px",
    flexShrink: 0,
  },
  chosenColor: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    flexShrink: 0,
    color: '#fff'
  },
  avatar:{
    width:"18px",
    height:"18px",
    borderRadius: '15%',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    height: '100%'
  },
  avatarImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export default styles;
