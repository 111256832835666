const Style = (theme) => ({
    popUpBox:{
        "& .MuiDialog-paper":{
            borderRadius: '12px',
            border: `${theme.palette.type === "dark" ? "1px solid #E4E8F11A" : "none"}`,
        }
    },
    cancelbutton:{
        '@media only screen and (max-width: 599px)':{
        '& .MuiButton-outlined':{
            padding:'6px 23px !important'
        }
    }
    },
    text:{
        '@media only screen and (max-width: 599px)':{
            color:theme.palette.text.text1,
            background:'none !important',                           
        }
    },
    actionsWrapper:{
        '& .MuiButton-outlinedSecondary':{
            '& .MuiButton-label':{
                color:'#64748B',
            }
        }
    }
})
export default Style;
