import React from 'react';
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";

//Import Styles
import styles from "./styles";
import { CircularProgress } from "@material-ui/core"
import ChatEditor from "../FlozyEditor/ChatEditor/chatEditor";

const GroupMediaMessage = (props) => {
    const { classes, message, handleSlideShowView, handleUndo, undo, slidepreviewdata, msgComponentType, userId, isUploading, isSender } = props;

    const media = message.message_file ? JSON.parse(message.message_file) : [];
    media?.map((m) => { m["id"] = message.id; return m })
    var groupSize = media.length;
    var displayData = groupSize === 3 ? media.slice(0, 2) : groupSize > 4 ? media.slice(0, 4) : media;

    const renderImage = (m) => {
        const media = message?.uploadPreviews?.files_data !== "{}" && message?.uploadPreviews?.files_data ? JSON.parse(message?.uploadPreviews?.files_data) : [];
        media?.map((m) => { m["id"] = message?.id; return m })
        var groupSize = media.length;
        var displayData = groupSize === 3 ? media.slice(0, 2) : groupSize > 4 ? media.slice(0, 4)  : media;
        return (
            <>
                {
                    displayData.map((data, i) => {
                        let extension = data?.path?.split('.')?.pop()
                        if (extension === 'mov') {
                            extension = `./icons/mp4.svg`
                        }
                        else if (extension === 'm4a' || extension === 'mp3' || extension === 'html' || extension === 'csv') {
                            extension = `./icons/doc.svg`
                        }
                        else if (extension === 'txt') {
                            extension = `./icons/txt.svg`
                        }
                        else if (extension === 'xls' || extension === 'xlsx') {
                            extension = `./icons/xls.svg`
                        }
                        else if (extension === 'pdf') {
                            extension = `./icons/pdf.svg`
                        }
                        else {
                            extension = data?.thumb || data?.preview
                        }
                        return <div className="groupImgContainer_img" key={`group_media_msg_${i}`}>
                            <img className={`${"ChatGroupImages"} ${classes.chatImage}`} alt='loading'
                                src={extension}
                                onClick={(e) => message?.message_file && !isUploading ? handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message }) : console.log("loading...")} key={i} />
                            {
                                (groupSize === 3 && i === 1) ? (
                                    <div className="overlay groupImgOverlay" onClick={(e) => message?.message_file && !isUploading ? handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message }) : console.log("loading...")}>+{parseInt(groupSize) - 2} More
                                    </div>
                                ) : (i === 3 && groupSize > 4) ? (
                                    <div className="overlay groupImgOverlay" onClick={(e) => message?.message_file && !isUploading ? handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message }) : console.log("loading...")}>+{parseInt(groupSize) - 3} More
                                    </div>
                                ) : null
                            }

                        </div>
                    })
                }
            </>
        )
    }

    if ((isUploading && message.uploadPreviews) || message.uploadPreviews) {
        return (
            <>
                {!undo ?
                    <div className={`${classes.groupImgContainerMain}`} >
                        <Grid className={`${classes.groupImgContainer} groupImgContainer`} >
                            {renderImage()}
                        </Grid>
                        {message?.message_text && message?.message_text !== "\r\n" && message?.message_text !== "\n" &&
                            <Grid className={`${message?.message_text ? 'm4' : 'mt-1'}`}>
                                <div className={`${message?.star && userId == message?.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} spacenone ${classes.hideGroupText} disable-user-select`}>
                                    <ChatEditor
                                        className={'chatEditorRoot'}
                                        id={message?.id || 1}
                                        content_status={'loaded'}
                                        content={message?.message_raw_text || message?.message_text || ""}
                                        readOnly={true}
                                        msgComponentType={msgComponentType}
                                        isSender={isSender}
                                    />
                                </div>
                            </Grid>
                        }
                        {!message.message_file && 
                            <div className="progress-container">
                            <CircularProgress className="circular-progress" />
                            </div>}
                    </div>
                    :
                    <span className="deletedMessage">This message has been deleted
                        {message?.message_from === userId ? (
                            <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                        ) : null
                        }
                    </span>
                }
            </>
        )
    } else {
        return (
            <>
                {!undo ?
                    <div className={`${classes.groupImgContainerMain}`} >
                        <Grid className={`${classes.groupImgContainer} ${message?.message_text && message?.message_text !== "\r\n" && message?.message_text !== "\n" ? '' : 'onlyMedia'} groupImgContainer`} >
                            {
                                displayData.map((data, i) =>
                                    <div className="groupImgContainer_img" key={`group_media_msg_${i}`}>
                                        <img className={`${"ChatGroupImages"} ${classes.chatImage}`} alt='loadingg'
                                            src={data?.thumb ? data?.thumb : `./icons/${data?.name?.split('.').pop() === 'xlsx' ? 'doc' : ''}.svg`}

                                            onClick={(e) => handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message })} key={i} />
                                        {
                                            (groupSize === 3 && i == 1) ? (
                                                <div className="overlay groupImgOverlay" onClick={(e) => handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, data: message, previewData: Array(groupSize).fill(slidepreviewdata) })} >+{parseInt(groupSize) - 2} More</div>)
                                                : (i == 3 && groupSize > 4) ? (
                                                    <div className="overlay groupImgOverlay" onClick={(e) => handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, data: message, previewData: Array(groupSize).fill(slidepreviewdata) })} >+{parseInt(groupSize) - 3} More</div>)
                                                    : null}
                                    </div>
                                )
                            }
                        </Grid>
                        {message?.message_text && message?.message_text !== "\r\n" && message?.message_text !== "\n" &&
                            <Grid className={`${message?.message_text ? 'm4' : 'mt-1'}`}>
                                <div className={`${message?.star && userId == message?.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} spacenone ${classes.hideGroupText} disable-user-select`}>
                                    <ChatEditor
                                        className={'chatEditorRoot'}
                                        id={message?.id || 1}
                                        content_status={'loaded'}
                                        content={message?.message_raw_text || message?.message_text || ""}
                                        readOnly={true}
                                        msgComponentType={msgComponentType}
                                        isSender={isSender}
                                    />
                                </div>
                            </Grid>
                        }
                    </div>
                    :
                    <span className="deletedMessage">This message has been deleted
                        {message?.message_from === userId ? (
                            <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                        ) : null
                        }
                    </span>
                }
            </>
        )
    }
}

// Default props
GroupMediaMessage.defaultProps = {
    classes: {},
    message: null,
    handleSlideShowView: () => { }
};

// Prop types
GroupMediaMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    handleSlideShowView: PropTypes.func,
};

export default withStyles(styles)(GroupMediaMessage)
