import { boardsConstant } from "../constants";
import { getUserBoardFilterPreferences, parseEditorString } from "../../utils";

const filter_default = {
    'global_search': {
        name: 'global_search',
        operator: 'like',
        field_type: 'string',
        field_options: 'string',
        value: "",
        selected: true
    }
}

const initialState = {
    loading: false,
    loading2: true,
    fieldloading:false,
    boards: [],
    board_id: null,
    columns: {},
    tasks: {},
    columnOrder: [],
    order_r: {},
    taskDetail: {},
    filter: {
        ...filter_default
    },
    // this array will store the users name
    // to show in card user avatar initial letter
    assigned: {},
    tags: [],
    // list of board members for this agency_id
    members: [],
    // list of selected members for this board
    selected_members: [],
    // task activity
    task_activity_history: {},
    // comments
    comments: {},
    // saved filters,
    saved_filters: [],

    public_key: "",
    is_public: "",
    project_id: "",
    board_title: "",

    exportBoard: [],
    import_data: {
        sheet_columns: null,
        file_name: ""
    },
    custom_fields: [],
    custom_fields_list:[]
}

export default function project(state = initialState, action) {
    switch (action.type) {

        case boardsConstant.GET_BOARDS_R:
            return {
                ...state,
                loading: true,
                board_id: null,
                boards: [],
                columns: {},
                tasks: {},
                columnOrder: []
            }

        case boardsConstant.GET_BOARDS_S:
            return {
                ...state,
                boards: action.data.data,
                loading: false
            }   

        case boardsConstant.GET_BOARDS_F:
            return {
                ...state,
                loading: false
            }

        case boardsConstant.PUBLIC_BOARD_R:
            return {
                ...state,
                loading2: true
            }

        case boardsConstant.PUBLIC_BOARD_S:
            return {
                ...state,
                loading2: false
            }
        case boardsConstant.PUBLIC_BOARD_F:
            return {
                ...state,
                loading2: false
            } 

        case boardsConstant.CREATE_BOARD_S:
            return {
                ...state,
                boards: [
                    ...state.boards,
                    {
                        ...action.data.data
                    }
                ]
            }

        case boardsConstant.CREATE_BOARD_LIST_S:
            return {
                ...state,
                // push the list id in the order
                columnOrder: [
                    ...state.columnOrder,
                    `${action.data.data.id}`
                ],
                columns: {
                    ...state.columns,
                    [`${action.data.data.id}`]: {
                        ...action.data.data
                    }
                }
            }

        // edit board list
        case boardsConstant.EDIT_BOARD_LIST_S:
            return {
                ...state,
                columns: {
                    ...state.columns,
                    [`${action.data.data.id}`]: {
                        ...state.columns[`${action.data.data.id}`],
                        ...action.data.data
                    }
                }
            }

        // delete board list
        case boardsConstant.DELETE_BOARD_LIST_S:
            delete state.columns[`${action.data.id}`]
            delete state.tasks[`${action.data.id}`]
            state.columnOrder.splice(state.columnOrder.indexOf(`${action.data.id}`), 1)
            return {
                ...state
            }

        case boardsConstant.CREATE_BOARD_TASK_S:
            const new_task = {
                ...action.data.data
            }
            delete new_task.taskIds
            delete new_task.add_to_first
            let updated_tasks_c = [
                ...(state.tasks[`${action.data.data.list_id}`] || [])
            ]

            // add first or last based on add card clicked
            if (action.data.data.add_to_first) {
                updated_tasks_c.unshift(new_task)
            } else {
                updated_tasks_c.push(new_task)
            }

            return {
                ...state,
                columns: {
                    ...state.columns,
                    [`${action.data.data.list_id}`]: {
                        ...state.columns[`${action.data.data.list_id}`],
                        taskIds: action.data.data.taskIds
                    }
                },
                tasks: {
                    ...state.tasks,
                    [`${action.data.data.list_id}`]: [
                        ...updated_tasks_c
                    ]
                }
            }

        case boardsConstant.SET_BOARD_ID:
            return {
                ...state,
                board_id: action.data,
                board_access_type: '',
                columns: {},
                tasks: {},
                columnOrder: [],
                saved_filters: getUserBoardFilterPreferences(action.data)
            }

        case boardsConstant.SET_PUBLIC_BOARD_ID:
            return {
                ...state,
                board_id : action.data.board_id,
                public_key: action.data.public_key,
                board_access_type: 'public',
                columns: {},
                tasks: {},
                columnOrder: [],
                saved_filters: getUserBoardFilterPreferences(action.data)
            }
        case boardsConstant.GET_BOARD_DETAIL_R:
            return {
                ...state,
                loading: true,
                columns: {},
                tasks: {},
                columnOrder: []
            }

        case boardsConstant.GET_BOARD_DETAIL_S:
            return {
                ...state,
                loading: false,
                ...action.data.data
            }

        case boardsConstant.GET_BOARD_DETAIL_F:
            return {
                ...state,
                loading: false
            }

        case boardsConstant.GET_BOARD_TASK_S:
            const prev_task_ids = (state.tasks[`${action.data.data.list_id}`]
                || []
            ).map(m => `${m.id}`)
            return {
                ...state,
                columns: {
                    ...state.columns,
                    [`${action.data.data.list_id}`]: {
                        ...state.columns[`${action.data.data.list_id}`],
                        estimated_value: parseFloat(action.data.data.estimated_value || 0)
                    }
                },
                tasks: {
                    ...state.tasks,
                    [`${action.data.data.list_id}`]: [
                        // for load more
                        ...(state.tasks[`${action.data.data.list_id}`] || []),
                        // do not append already loaded task
                        // need to find the sceario temp fix
                        ...(action.data.data.tasks || []).filter(f => {
                            return prev_task_ids.indexOf(`${f.id}`) < 0
                        })
                    ]
                }
            }

        case boardsConstant.UPDATE_BOARD_TASK_ORDER_R:
            return {
                ...state,
                order_r: {
                    columns: {
                        ...state.columns
                    },
                    tasks: {
                        ...state.tasks
                    }
                },
                columns: {
                    ...action.params.columns
                },
                tasks: {
                    ...action.params.tasks
                }
            }

        case boardsConstant.UPDATE_BOARD_TASK_ORDER_S:
            return {
                ...state
            }

        case boardsConstant.UPDATE_BOARD_TASK_ORDER_F:
            return {
                ...state,
                columns: {
                    ...state.order_r.columns
                },
                tasks: {
                    ...state.order_r.tasks
                }
            }

        case boardsConstant.UPDATE_BOARD_LIST_ORDER_R:
            if (action.params.columnOrder) {
                return {
                    ...state,
                    order_r: {
                        columnOrder: [...state.columnOrder]
                    },
                    columnOrder: [...action.params.columnOrder]
                }
            } else {
                return {
                    ...state
                }
            }

        case boardsConstant.UPDATE_BOARD_LIST_ORDER_S:
            return {
                ...state,
                boards: state.boards.map(m => {
                    if (m.value === state.board_id && action.data.board_title) {
                        m.label = action.data.board_title
                    }
                    return m
                }),
                order_r: {}
            }

        case boardsConstant.UPDATE_BOARD_LIST_ORDER_F:
            if (action.order_r.columnOrder) {
                return {
                    ...state,
                    order_r: {},
                    columnOrder: [...action.order_r.columnOrder]
                }
            } else {
                return { ...state }
            }

        case boardsConstant.GET_BOARD_TASK_DETAIL_R:
            return {
                ...state,
                taskDetail: {
                    content_status: null
                }
            }

        case boardsConstant.GET_BOARD_TASK_DETAIL_S:
            return {
                ...state,
                taskDetail: {
                    ...action.data.data,
                    content_status: 'loaded',
                    attachments: action.data.data && action.data.data['attachments']
                        ? JSON.parse(action.data.data['attachments']) : null,
                    assigned: action.data.data && action.data.data['assigned']
                        ? JSON.parse(action.data.data['assigned']).map(m => Number(m)) : null,
                    description: action.data.data && action.data.data['description']
                        ? parseEditorString(action.data.data['description']) : null,
                    default_tags: action.data.data && action.data.data['default_tags']
                        ? JSON.parse(action.data.data['default_tags']) : null,
                }
            }

        case boardsConstant.UPDATE_BOARD_TASK_DETAIL_S:
            const tempCustomFields = [...state.custom_fields]
            if (action.data.data.freesolo_labels) {
                const parsedData = JSON.parse(action.data.data.freesolo_labels)
                const findIndex = tempCustomFields.findIndex(item => item["field_id"] == parsedData.field_id)
                if (findIndex !== -1) {
                    tempCustomFields[findIndex] = {
                        ...tempCustomFields[findIndex],
                        default_list_json: [
                            ...JSON.parse(parsedData.labels)
                        ]
                    }
                }
            }
            return {
                ...state,
                taskDetail: {
                    ...action.data.data,
                    content_status: 'loaded',
                    attachments: action.data.data && action.data.data['attachments']
                        ? JSON.parse(action.data.data['attachments']) : null,
                    assigned: action.data.data && action.data.data['assigned']
                        ? JSON.parse(action.data.data['assigned']).map(m => Number(m)) : null,
                    description: action.data.data && action.data.data['description']
                        ? parseEditorString(action.data.data['description']) : null,
                    default_tags: action.data.data && action.data.data['default_tags']
                        ? JSON.parse(action.data.data['default_tags']) : null,
                },
                custom_fields: tempCustomFields
            }

        // filter on change
        case boardsConstant.FILTER_ON_CHANGE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.params.name]: {
                        ...action.params
                    }
                }
            }

        // assigned users
        case boardsConstant.GET_ASSIGNED_USERS_TASK_R:
            return {
                ...state,
                assigned: {
                    ...state.assigned,
                    ...action.params.users.reduce((a, b) => {
                        a[b] = { id: b, name: '-' }
                        return a
                    }, {})
                }
            }

        case boardsConstant.GET_ASSIGNED_USERS_TASK_S:
            return {
                ...state,
                assigned: {
                    ...state.assigned,
                    ...action.data.data
                }
            }

        // Reload board task in reduce after save
        case boardsConstant.RELOAD_BOARD_TASK_CARD:
            let diff_estimate = 0
            const updated_tasks = state.tasks[`${action.params.list_id}`].map(m => {
                if (`${m.id}` == `${action.params.id}`) {
                    let oldEstimatedValue = m.hasOwnProperty("estimated_value") && m.estimated_value && !isNaN(m.estimated_value) ? m.estimated_value || 0 : 0;
                    diff_estimate = parseFloat(oldEstimatedValue) - parseFloat(action.params.estimated_value || 0)
                    m = {
                        ...action.params
                    }
                }
                return m
            })

            const update_columns = {
                ...state.columns
            }
            let newEstimatedValue = parseFloat(update_columns[`${action.params.list_id}`]?.estimated_value || 0) - parseFloat(diff_estimate)
            if (newEstimatedValue < 0) {
                update_columns[`${action.params.list_id}`].estimated_value = 0
            } else {
                update_columns[`${action.params.list_id}`].estimated_value = newEstimatedValue
            }

            // update tags if any
            let up_tags = [...state.tags].map(m => {
                if (m.value == action.params.tags) {
                    m.color = action.params.tags_color
                }
                return m
            })
            if (action.params.tags && up_tags.find(f => f.value === action.params.tags)) {
                up_tags.push({
                    value: action.params.tags,
                    label: action.params.tags,
                    color: action.params.tags_color
                })
            }

            return {
                ...state,
                columns: update_columns,
                tasks: {
                    ...state.tasks,
                    [`${action.params.list_id}`]: updated_tasks
                },
                tags: up_tags
            }

        // Delete board task
        case boardsConstant.DELETE_BOARD_TASK_S:
            state.columns[`${action.data.listId}`].taskIds.splice(
                state.columns[`${action.data.listId}`].taskIds.indexOf(action.data.taskId),
                1
            )
            if (action.data.taskDetail && action.data.taskDetail.estimated_value) {
                if (!isNaN(action.data.taskDetail.estimated_value)
                    && !isNaN(state.columns[`${action.data.listId}`].estimated_value)) {
                    let newEstValue = parseFloat(state.columns[`${action.data.listId}`].estimated_value || 0)
                        - parseFloat(action.data.taskDetail.estimated_value || 0)
                    state.columns[`${action.data.listId}`].estimated_value = newEstValue > 0 ? newEstValue : 0;
                }
            }
            state.tasks[`${action.data.listId}`] = state.tasks[`${action.data.listId}`].filter(f => f.id != action.data.taskId)
            return {
                ...state
            }

        // get baords task tags
        case boardsConstant.BOARDS_TASK_TAGS_S:
            return {
                ...state,
                tags: action.data.data
            }

        // get board members
        case boardsConstant.GET_BOARD_MEMBERS_S:
            return {
                ...state,
                ...action.data,
                boards: state.boards.map(m => {
                    if (m.value == action.data.board_id) {
                        m.admins = action.data.admins
                    }
                    return m
                }),
            }

        // case add / update / delete board members
        case boardsConstant.ADD_BOARD_MEMBERS_S:
        case boardsConstant.MODIFY_BOARD_MEMBERS_S:
        case boardsConstant.DELETE_BOARD_MEMBERS_S:
            return {
                ...state,
                boards: state.boards.map(m => {
                    if (m.value == action.data.board_id) {
                        m.admins = action.data.admins
                    }
                    return m
                }),
                selected_members: action.data.selected_members
            }

        // get boards task history
        case boardsConstant.GET_BOARD_TASK_HISTORY_S:
            return {
                ...state,
                task_activity_history: {
                    ...state.task_activity_history,
                    [action.data.task_id]: action.data.data
                }
            }

        case boardsConstant.GET_BOARD_TASK_COMMENTS_S:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.data.task_id]: action.data.data
                }
            }

        case boardsConstant.ADD_BOARD_TASK_COMMENTS_S:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.data.task_id]: [
                        {
                            ...action.data.data
                        },
                        ...(state.comments[action.data.task_id] || [])
                    ]
                }
            }

        case boardsConstant.UPDATE_BOARD_TASK_COMMENTS_S:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.data.task_id]: (state.comments[action.data.task_id] || [])
                        .map(m => {
                            if (m.id == action.data.comment_id) {
                                m = {
                                    ...m,
                                    ...action.data.data
                                }
                            }
                            return m
                        })
                }
            }

        case boardsConstant.DELETE_BOARD_TASK_COMMENTS_S:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    [action.data.task_id]: (state.comments[action.data.task_id] || [])
                        .filter(m => {
                            return m.id != action.data.comment_id
                        })
                }
            }

        case boardsConstant.SAVE_BOARD_FILTER:
            return {
                ...state,
                saved_filters: [
                    ...state.saved_filters,
                    {
                        ...action.data
                    }
                ]
            }

        case boardsConstant.UPDATE_BOARD_FILTER:
            return {
                ...state,
                saved_filters: state.saved_filters.map(m => {
                    if (m.board_id == action.data.board_id && m.value === action.data.value) {
                        m.data = {
                            ...m.data,
                            ...action.data.data
                        }
                    }
                    return m
                })
            }

        case boardsConstant.SET_BOARD_FILTER:
            return {
                ...state,
                filter: {
                    ...filter_default,
                    ...((state.saved_filters || []).find(f => f.value == action.data) || { data: {} }).data
                }
            }

        case boardsConstant.RESET_BOARD:
            return {
                ...initialState
            }

        case boardsConstant.UPDATE_TASK_CARD:
            if (state.tasks[`${action.data.list_id}`]) {
                state.tasks[`${action.data.list_id}`] = (
                    state.tasks[`${action.data.list_id}`]
                    || [])
                    .map(m => {
                        if (`${m.id}` == `${action.data.id}`) {
                            m = {
                                ...m,
                                ...action.data
                            }
                        }
                        return m
                    })
            }
            return {
                ...state
            }

        case boardsConstant.UPDATE_BOARD_SETTINGS_S:
            return {
                ...state,
                boards: state.boards.map(m => {
                    if (`${m.value}` == `${action.data.board_id}`) {
                        m.settings = action.data.settings
                    }
                    return m
                })
            }

        case boardsConstant.CLONE_BOARD_TASK_S:
            if (action.data.clone_task_id) {
                let cloned_task_index = null
                const old_list_detail = state.columns[`${action.data.list_id}`] || {}
                const cloned_task = state.tasks[`${action.data.list_id}`]
                    .find((f, i) => {
                        if (`${f.id}` === `${action.data.clone_task_id}`) {
                            cloned_task_index = i
                            return true
                        } else {
                            return false
                        }
                    })
                // check if parent task exists
                if (cloned_task_index !== null && cloned_task) {
                    const new_cloned_task = {
                        ...cloned_task,
                        id: `${action.data.id}`,
                        ...action.data.task_detail
                    }
                    // update in list
                    state.tasks[`${action.data.list_id}`]
                        .splice(cloned_task_index, 0, new_cloned_task)
                    // update the list order and list detail
                    state.columns = {
                        ...(state.columns || {}),
                        [`${action.data.list_id}`]: {
                            id: action.data.list_id,
                            ...(state.columns[`${action.data.list_id}`] | {}),
                            ...(action.data.list_detail || {}),
                            estimated_value: parseFloat((old_list_detail.estimated_value || 0))
                                + parseFloat((new_cloned_task.estimated_value || 0))
                        }
                    }
                }
            }
            return {
                ...state,
            }

        case boardsConstant.DELETE_BOARD_S:
            return {
                ...state,
                boards: state.boards.filter(m => {
                    return `${m.value}` !== `${action.data.board_id}`
                })
            }

        case boardsConstant.PUBLIC_LINK:
            return {
                ...state,
                public_key: action.data
            }

        case boardsConstant.PUBLIC_BOARD_ID:
            return {
                ...state,  
             board_id: action.data.id,
             project_id: action.data.job_id,
             board_title: action.data.board_title,
             is_public: action.data.is_public
            }

            case boardsConstant.SET_PUBLIC:
                return {
                    ...state,
                    is_public: action.data
                }    

        case boardsConstant.UPLOAD_SHEET_DATA_R:
            return {
                ...state,
                import_data: {
                    sheet_columns: null,
                    file_name: ""
                }
            }
        case boardsConstant.UPLOAD_SHEET_DATA_S:
            return {
                ...state,
                import_data: action.data
            }
        case boardsConstant.UPLOAD_SHEET_DATA_F:
            return {
                ...state,
                import_data: {
                    sheet_columns: null,
                    file_name: ""
                }
            }
        case boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_R:
            return {
                ...state,
                fieldloading: true
            }
        case boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_S:
            return {
                ...state,
                fieldloading: false
            }
        case boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_F:
            return {
                ...state,
                fieldloading: false
            }
        case boardsConstant.UPDATE_TASK_FIELD_REDUCER:
            let description = action?.data?.data && action?.data?.data?.description ? action.data.data.description : action?.data?.description ? action.data.description : null;
            description = description ? parseEditorString(description) : null;

            return {
                ...state,
                taskDetail: {
                    ...action.data,
                    attachments: action.data && action.data['attachments']
                        ? (typeof action.data['attachments']) === 'string'
                            ? JSON.parse(action.data['attachments'])
                            : action.data['attachments'] : null,

                    assigned: action.data && action.data['assigned']
                        ? typeof action.data['assigned'] === 'string'
                            ? JSON.parse(action.data['assigned']).map(m => Number(m))
                            : action.data['assigned']
                        : null,
                    custom_fields_data: (action.data?.["custom_fields_data"] || null),
                    description: description,
                }
            }
        case boardsConstant.GET_BOARD_CUSTOM_FIELD_S:
            return {
                ...state,
                custom_fields: (action?.data?.data || [])
            }
        case boardsConstant.CREATE_BOARD_CUSTOM_FIELD_S:
            const { field_name, field_type, field_id, field_value, default_list_json } = action.data.data

            return {
                ...state,
                custom_fields: [
                    ...(state.custom_fields || {}),
                    ({ field_id, field_value, type: field_type, label: field_name, name: field_name, placeholder: `Enter ${field_name}`, default_list_json: default_list_json && JSON.parse(default_list_json) } || {}),
                ],
                custom_fields_list: [
                    ...(state.custom_fields_list || []),
                    (action.data.data.created_field || {})
                ]
            }
        case boardsConstant.UPDATE_BOARD_CUSTOM_FIELD_S:
            let updated = action.data.data
            const findIndex = state.custom_fields.findIndex(obj => obj.field_id == action.data.data.field_id)
            let updatedCustomFields = [...state.custom_fields];
            if (findIndex !== -1) {
                updatedCustomFields.splice(findIndex, 1, {
                    field_id: updated.field_id,
                    field_value: updated.field_value,
                    type: updated.field_type,
                    label: updated.field_name,
                    name: updated.field_name,
                    placeholder: `Enter ${updated.field_name}`,
                    default_list_json: updated.default_list_json ? JSON.parse(updated.default_list_json) : null
                });
            }
            const findFilterFieldIndex = state.custom_fields_list.findIndex(obj => obj.id == action.data.data.field_id)
            if (findFilterFieldIndex !== 1) {
                state.custom_fields_list[findFilterFieldIndex] = action.data.data.updated_field
            }
            const tempTaskDetail = { ...state.taskDetail }
            tempTaskDetail[updated.field_name] = tempTaskDetail[state.custom_fields[findIndex]["name"]]
            delete tempTaskDetail[state.custom_fields[findIndex]["name"]]
            return {
                ...state,
                custom_fields: updatedCustomFields,
                custom_fields_list: [...(state.custom_fields_list || [])],
                taskDetail: tempTaskDetail
            }
        case boardsConstant.GET_BOARD_CUSTOM_FIELD_LIST_S:
            return {
                ...state,
                custom_fields_list: (action?.data?.data || []),
            }
        default:
            return state

        case boardsConstant.EXPORT_BOARD_R:
            return {
                ...state
            }

        case boardsConstant.EXPORT_BOARD_S:
            return {
                ...state,
                exportBoard: action.data.data
                }

        case boardsConstant.EXPORT_BOARD_F:
            return {
                ...state
            }

    }
}
