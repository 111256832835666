const styles = (theme) => ({
    root: {
        display: 'flex',
        background: "transparent",
        boxShadow: "none !important",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiCardContent-root": {
            padding: "4px !important",
            paddingLeft: "10px !important",
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
        '& .MuiTypography-root ': {
            wordBreak: 'break-word'
        }
    },
    cover: {
        width: "50px",
    },
    paper: {
        minWidth: 'fit-content',
        right: 0,
        // minWidth: 'fit-content',
        width: '100%',
        // right: 0,
        '& .MuiAutocomplete-listbox': {
            maxWidth: '100%',
            width: '100%',
        },
        '& .avatarDiv, & .MuiAvatar-root': {
            width: '32px',
            height: '32px',
            marginRight: '2px'
        },
        '& .chosenEmoji': {
            fontSize: '28px !important',
            width: '32px',
            height: '32px',
            marginRight: '2px'
        }
    }

});

export default styles;
