import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { ChatEditor as Editor } from "@flozy/editor";
import serializeToHTML from "./serializeToHTML"
import { draftToSlate } from "./draftToSlate";
import styles from "./style";
import { isValidJSON } from '../../../utils';

const ChatEditor = (props) => {
  const {
    editorRef,
    id,
    classes,
    className,
    content_status,
    content,
    onSave,
    readOnly = false,
    otherProps,
    handleEditorChange,
    msgComponentType,
    isSender,
    isInfinity = false,
    viewType,
    elipis=false,
    clickable = false,
    ...rest
  } = props;

  if (readOnly) {
    let html;
    if (isInfinity) {
      html = content;
    } else {
      const convertedContent = content ? draftToSlate({ data: isValidJSON(content) ? JSON.parse(content) : content }) : []
      let infinity_response = false
      convertedContent && convertedContent.map(val => {
        if (val?.is_infiniti_res) {
          infinity_response = val?.children?.[0]?.text || ''
        }
      })
      html = infinity_response ? infinity_response : serializeToHTML(convertedContent, msgComponentType, viewType, isSender, clickable);
    }
    return (
      <Grid className={`pl5 ${classes.textMsgEditor} ${classes.textMsgEditorSpace}  ${elipis && elipis === 3 ? 'threeLineEllipForReply' : (elipis === 2 ? 'twoLineEllip' : (elipis === 1 ? 'oneLineEllipText' : (elipis ? 'threeLineEllipForReply' : '')))} ${isSender && viewType !== 'listView' ? `chatSend` : 'chatreceive'}`}
        dangerouslySetInnerHTML={{
          __html:
            html || "",
        }}
      />
    )
  } else {
    return content_status ? (
      <Grid container>
        <Grid
          item
          xs={12}
          className={`${classes?.chatEditorRoot}`}
        >
          <Editor
            ref={editorRef}
            id={id}
            className={className}
            toolBar={false}
            content={content}
            onSave={onSave}
            handleEditorChange={handleEditorChange}
            otherProps={otherProps}
            {...rest}
          />
        </Grid>
      </Grid>
    ) : null;
  }
};

ChatEditor.defaultProps = {
  toolbarOptions: {
    removeOptions: [],
    showOptions: [],
  },
  content: [
    {
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ],
  classes: {},
  otherProps: {
    variableOptions: [],
  },
  handleEditorChange: () => { },
};

ChatEditor.displayName = "ChatEditor";

export default withStyles(styles)(ChatEditor);

