import { pageBuilderService } from "../../../redux/services/pageBuilderService";
import { aiService } from "../../../redux/services/ai";
import { mediaService } from "../../../redux/services/mediaService";
import { _api } from "../../../config/environment";

const listTemplates = async () => {
  try {
    const result = await pageBuilderService.listTemplates('?type=Template');
    if (result?.data) {
      result.data = result?.data?.map(m => {
        m.previewLink = `${_api.pageBuilder}/templates/preview/${m.id}`
        return m
      })
    }
    return result;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const listGoogleFont = async () => {
  try {
    const result = await pageBuilderService.listGoogleFont()
    return result
  } catch (err) {
    console.log(err)
  }
}

const uploadFile = async (formData) => {
  try {
    const response = await mediaService?.editorUploadFiles(formData);
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const loadpreviewContent = async (site_id = 0, page_url = "") => {
  try {
    const result = await pageBuilderService.loadPreviewContent(
      site_id,
      page_url
    );
    var data_url = URL.createObjectURL(result.data);
    return data_url;
  } catch (err) {
    console.log(err, "loadpreviewContent");
    return "<h1>Error Loading Content</h1>";
  }
};

const loadPreviewImage = async (site_id = 0, page_url = "home") => {
  try {
    const result = await pageBuilderService.loadPreviewImage(site_id, page_url);
    const file = new File([result.data], `site_${site_id}_${page_url}.png`, {
      type: "image/png",
    });
    return file;
  } catch (err) {
    console.log(err, "loadpreviewImage");
    return "<h1>Error Loading loadpreviewImage</h1>";
  }
};

const getAssets = async () => {
  try {
    return [
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%283%29.jpeg",
        title: "abstract1",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%284%29.jpeg",
        title: "abstract2",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%2810%29.jpeg",
        title: "colorful sky",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%282%29.jpeg",
        title: "sticky notes",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%281%29.jpeg",
        title: "abstract3",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%285%29.jpeg",
        title: "ocean",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%287%29.jpeg",
        title: "buildings",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%20878/pages/603/878b5de8cc9e07e1672817f2c3971493e9577f88%20%281%29.jpeg",
        title: "glass buildings",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13.jpeg",
        title: "abstract4",
      },
      {
        img: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/878/pages/603/WhatsApp%20Image%202024-04-27%20at%2023.36.13%20%288%29.jpeg",
        title: "corridor",
      },
    ];
  } catch (err) {
    console.log(err, "loadpreviewImage");
    return "<h1>Error Loading loadpreviewImage</h1>";
  }
};

const getPages = async (props) => {
  try {
    const { site_id, page_id } = props;
    const result = await pageBuilderService.getPages(site_id, page_id);

    return result;
  } catch (err) {
    console.log(err);
    return;
  }
};

const infinityAI = async (props) => {
  try {
    const result = await pageBuilderService.infinityAI(props);

    return result;
  } catch (err) {
    console.log(err);
    return [];
  }
};


export const onServices = async (serviceName, props) => {
  try {
    const result = await DocServices[serviceName](props);
    if (serviceName === "getAssets") {
      return result;
    }
    if (serviceName === "uploadFile") {
      return result;
    }
    return {
      data: result?.data || [],
    };
  } catch (err) {
    console.log(err);
  }
};

const speechToText = (data) => {
  return aiService.speechToText(data.formData);
}

const getDocs = async (params) => {
  try {
    const result = await pageBuilderService.getDocs(params);
    return result;
  } catch (err) {
    console.log(err);
    return;
  }
};

const getDocAccess = async (params) => {
  try {
    const result = await pageBuilderService.getDocAccess(params);
    return result;
  } catch (err) {
    console.log(err);
    return;
  }
}

export const DocServices = {
  listTemplates: listTemplates,
  listGoogleFont: listGoogleFont,
  uploadFile: uploadFile,
  loadpreviewContent: loadpreviewContent,
  loadPreviewImage: loadPreviewImage,
  getAssets: getAssets,
  getPages: getPages,
  infinityAI: infinityAI,
  validateCode: pageBuilderService.validateCode,
  allowedDomains: pageBuilderService.allowedDomains,
  speechToText: speechToText,
  getDocs: getDocs,
  getDocAccess: getDocAccess
};
