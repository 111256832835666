
// import green from "@material-ui/core/colors/green";
const style = (theme) => ({
  ALERT_SUCCESS: {
    background: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
    fontWeight: "500"
  },
  ALERT_ERROR: {
    background: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
    fontWeight: "500"
  },
  ALERT_INFO: {
    background: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
    fontWeight: "500"
  },
  ALERT_WARNING: {
    background: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
    fontWeight: "500"
  },
  ALERT_CLOSE: {
    padding: theme.spacing(0.5)
  },
  ALERT_INFORM: {
     background: '#778599E5',
    fontWeight: "500"
  },
  snackBar: {
    position: 'absolute',
    zIndex: 9999
  },
  close: {
    '& svg': {
      fill: '#fff'
    }
  }
});

export default style;
