const NoDataStyle = (theme) => ({
    NoDataHeader: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "800",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    noDataHeaderbtn: {
        display: "flex",
        justifyContent: "space-between",
        gap: "17px",
        alignItems: "center",
    },
    noDataContainer: {
        height: "100%",
        borderRadius: "12px",
        background: theme.palette.containers.card,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        '& .fillFront': {
            fill: theme.palette.noItemIcon.front.bg
        },
        '& .strokeCard': {
            stroke: theme.palette.noItemIcon.front.light
        },
        '& .fillCard': {
            fill: theme.palette.noItemIcon.back.bg
        },
        '& .fillStroke': {
            stroke: theme.palette.noItemIcon.front.border
        },
        "@media only screen and (max-width: 599px)": {
            padding:'15px',
            "& .MuiButton-label":{
                fontSize:'12px'
            }
        }
    },
   
    NodataText: {
        color: theme.palette.secondary.main,
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "600",
    },
    NoDataContent: {
        color: theme.palette.text.secondary2,
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        width: "80%",
    },
    newpubliccontainer: {
        padding: "4px 1px",
        background: theme.palette.containers.card,
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "space-around",
    },
});

export default NoDataStyle;
