/* eslint-disable react/prop-types */
import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { isMobile, isIOS, isBrowser, mobileModel } from 'react-device-detect';

// Layout Style
import PrivateLayoutStyles from "./PrivateLayoutStyles";

// Import Child Container
import Header from "../../containers/Header/Header";
import MobileFooter from '../../containers/Footer/MobileFooter'
import NotificationPermission from "../../components/NotificationPermissionComponent";
import CookiesComponent from "../../components/CookiesComponent";

// Import Config Layout
// import { getPrivateContainerHeight } from "../../config/layout";

//Import Action
import { profileAction, chatv2Action } from "../../redux/actions"

import { requestFirebaseNotificationPermission, onMessageListener } from '../../firebaseInit';
import Shortcuts from "../../components/Shortcuts/KeyboardShortcuts";
import WorkSpaceLobby from "../../components/WorkSpaceLobby";
import ConfirmationPopup from "../../components/WorkSpaceLobby/reloadConfirmationPopup"
const PrivateLayoutWeb = (props) => {
  const { match, classes } = props;
  const dispatch = useDispatch();

  let { web_app_token } = useSelector(s => s.auth.loginUser);
  let { loading } = useSelector(s => s.UI);
  const isTokenSentToServer = localStorage.getItem('AF_Token_SentToServer');
  const existingToken = localStorage.getItem('notificationToken');

  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");

  // Set Container Height
  // let height = getPrivateContainerHeight();

  if (window.innerWidth <= 768) {
    // height = height - 50;
  }

  if (isIOS && isMobile && !isBrowser && mobileModel === "iPhone") {
    // height = height - parseInt(safeAreaTopHeight.replace("px", ""));
  }


  if (!isTokenSentToServer || !web_app_token || web_app_token !== existingToken) {
    requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        const hasKey = localStorage.getItem('key');
        if (hasKey && firebaseToken) {
          //save token into db
          dispatch(profileAction.saveWebBrowserToken({ token: firebaseToken }, () => {
            //save isTokenSet or Not in LocalStorage;
            localStorage.setItem('AF_Token_SentToServer', 1);
            localStorage.setItem('notificationToken', firebaseToken);
          }))
        } else {
          console.log('no permission granted...!')
        }
      })
      .catch((err) => {
        return err;
      });
  }


  onMessageListener()
    .then((payload) => {
      const { body, image } = payload.notification;
      const notificationOptions = {
        body: body,
        tag: "chat",
        image: image,
        icon: './logo.png',
        badge: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%201685586307248_profile",
        actions: [
          {
            action: 'yes',
            type: 'button',
            title: '👍 Yes',
          },
          {
            action: 'no',
            type: 'text',
            title: '👎 No (explain why)',
            placeholder: 'Type your explanation here',
          },
        ],
      };
      // new Notification(notificationTitle, notificationOptions);
    })
    .catch((err) => {
      console.error("🚀 ~ file: PrivateLayoutWeb.jsx ~ line 69 ~ .catch ~ err", err)
    });


  //Notification
  const [allowCookie, setAllowCookie] = useState(false);
  const [allowNotify, setAllowNotify] = useState(false);

  let cookie = localStorage.getItem('myPolicy');
  if (cookie !== "PrivacyPolicy") {
    setTimeout(() => {
      setAllowCookie(true);
    }, 2000);
  }

  const onAcceptCookie = () => {
    localStorage.setItem('myPolicy', 'PrivacyPolicy');
    setAllowCookie(false);
  }

  const onAcceptNotify = () => {
    // cookies.set('myPolicy', 'PrivacyPolicy', { path: '/' });
    setAllowNotify(false);
  }

  const onNotAcceptNotify = () => {
    setAllowNotify(false);
  }

  /**
  * Handle Header Menu Action
  */
  const {newState,cloningProgress} = useSelector(s=>(s.workSpaceCloning))
  const [browserPopup, setBrowserPopup] = useState(true); 
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (newState && cloningProgress !== 100) { 
        setBrowserPopup(true); 
        event.preventDefault(); 
        event.returnValue = 'Are you sure want to refresh'; 
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [newState, cloningProgress]);


  return (
    <Grid container className={`${isIOS && isMobile ? "notchContainer" : null} h-100 flexBasis0`} direction="column" wrap="nowrap">
      <Shortcuts />
      {
        isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ?
          <Grid item xs={12} style={{ height: `${safeAreaTopHeight}` }} className="notchGrid flexBasis0 " /> : null
      }
      <Grid item xs={12} className="flexBasisAuto">
        <Header match={match} isPublic={false} />
      </Grid>
      <Grid item xs={12} className="growOne overflowYAuto w-100" id="privateLayoutDiv">
        <Grid>
          {
            newState ? <WorkSpaceLobby/> : null
          }
        {/* {
          newState && browserPopup  ? 
          <ConfirmationPopup  open={browserPopup} onClose={confirmPageRefresh} onProcess={closeBrowserPopup}/> : null
        } */}
        </Grid>
        <Grid
          container
          className={`${classes.container} h-100 w-100`}
        >
          {props.children}
        </Grid>
      </Grid>
      <Grid item xs={12} className="mobile_overall flexBasisAuto">
        <MobileFooter />
      </Grid>
      <CookiesComponent
        openStatus={allowCookie}
        message={
          <>
            <span>
              By clicking “ACCEPT”, you agree to the storing of first- and third-party cookies.{' '}
              <a href="https://www.flozy.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                Read our cookie policy
              </a>
            </span>
          </>
        }
        actionMessage="Accept"
        onActionClick={onAcceptCookie}
      />
      <NotificationPermission
        openStatus={allowNotify}
        message={"We'd like to show you notifications."}
        actionMessage="ALLOW"
        actionMessage2="NO THANKS"
        onActionClick={onAcceptNotify}
        onActionClick2={onNotAcceptNotify}
      />
    </Grid>
  );
}

// Define Props Types
PrivateLayoutWeb.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PrivateLayoutWeb.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default withStyles(PrivateLayoutStyles)(PrivateLayoutWeb);
