import '@draft-js-plugins/emoji/lib/plugin.css';
// import palette from '../../assets/theme/palette';

const styles = (theme) => ({
  roots: {
    height: 36,
    '& .MuiOutlinedInput-root': {
      height: 36,
      minHeight: 36
    }
  },
  popoverIcon: {
    '&.bottomWithKeyboard': {
      "& .MuiPopover-paper": {
        top: '0px !important',
        bottom: '0px !important',
      }
    },
    "& .MuiPopover-paper": {
      // top: "185px !important"
      // bottom: '70px !important',
      // top: 'auto !important',
      // minHeight: '480px',
      '& .reactGiphySearchbox-searchForm-input': {
        background: theme.palette.containers.card,
        border: `1px solid ${theme.palette.primary.border1}`
      }
    }
  },
  chatTypAttach: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: "12px",

    "& .chatEmojiSelectPopover": {
      position: "fixed !important",
      bottom: "4rem",
      right: "1rem",
      background: theme.palette.containers.bg1,
      borderColor: theme.palette.primary.border1,
      boxShadow: theme.palette.shadows.shadow5,
      zIndex: 999
    },
    "& button.chatEmojiSelectButton": {
      flex: "0 0 auto",
      color: "rgba(0, 0, 0, 0.54)",
      padding: "6px",
      overflow: "visible",
      fontSize: "1rem",
      textAlign: "center",
      transition: 'background - color 150ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '50%',
      border: "0px"
    },
    "& button.chatEmojiSelectButtonPressed": {
      flex: "0 0 auto",
      color: "rgba(0, 0, 0, 0.54)",
      padding: "6px",
      overflow: "visible",
      fontSize: "1rem",
      textAlign: "center",
      transition: 'background - color 150ms cubic- bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: '50%',
      border: "0px"
    }
  },
  textOverallCon: {
    flexWrap: 'nowrap',
    // background: palette.primary.bgColorGray,
    background: theme.palette.containers.bg29,
    borderRadius: '10px',
  },
  textTypeIcon: {
    // background: palette.primary.bgColorGray,
    // borderRadius: '10px',
    padding: '10px 12px',
    '& .TypeTextIcon': {
      // position: 'absolute',
      // left: 24,
      // top: 8,
      // height: 50,
      // width: 50,
      // borderRight: `1px solid ${palette.primary.border1}`,
      // paddingRight: 15
    }
  },
  chatInputBox: {
    overflowY:'hidden',
    '@media only screen and (max-width: 899px)': {
      '&.chatInputBox': {
        borderRadius: '8px',
        color: theme.palette.text.text15
        // background: '#F0F0F0'
      },
      '& .textOverallCon': {
        background: 'transparent',
        // borderRadius: '30px'
      }
    },
    "& .DraftEditor-editorContainer": {
      maxHeight: "6rem !important",
      overflowY: "auto !important",
      width: "100%",
      position: 'relative',
    },
    "& .chatEmojiSuggestions": {
      // width: "auto",
      // cursor: "pointer",
      // display: "flex",
      // zIndex: 9999,
      // position: "absolute",
      // background: "#fff",
      // boxSizing: "border-box",
      // borderRadius: "2px",
      // flexDirection: "column",
      // transformOrigin: "50% 0%",
      // left: "0",
      // bottom: "101%"

    },
    "& .chatMentionsSuggestions": {
      background: '#fff !important',
      backgroundColor: '#fff !important',
      marginBottom: '-50px !important',
      zIndex: 9999,
      boxShadow: '0px 1px 16px #ddd !important',
      borderRadius: '6px !important'
      // width: "100%",
      // cursor: "pointer",
      // display: "flex",
      // zIndex: 9999,
      // position: "absolute !important",
      // background: "#fff",
      // boxSizing: "border-box",
      // borderRadius: "2px",
      // flexDirection: "column",
      // transformOrigin: "50% 0%",
      // left: "0",
      // bottom: "0 !important"
    },
    "& .chatMentionSuggestionsEntry": {
      display: "flex",
      flexDirection: "row",
      padding: "7px 10px 3px 10px",
      transition: "background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56)",
      alignItems: "center",
    },
    '& .mentionSuggestionsEntryContainer:first-child, .mentionSuggestionsEntryContainer:last-child': {
      borderTopLeftRadius: '6px !important',
      borderTopRightRadius: '6px !important'
    },
    "& .chatMentionSuggestionsEntry:hover": { backgroundColor: "#cce7ff", cursor: "pointer" },
    "& .chatMentionSuggestionsEntry:active": { backgroundColor: "#cce7ff" },
    "& .chatMentionSuggestionsEntryFocused": {
      display: "flex",
      flexDirection: "row",
      padding: "7px 10px 3px 10px",
      transition: "background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56)",
      backgroundColor: "#e6f3ff",
      alignItems: "center",
    },
    "& .chatMentionSuggestionsEntryAvatar": {
      display: "flex",
      fontSize: "1rem",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      marginRight: "10px"
    },
    "& .chatMentionSuggestionsEntryText": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& .chatMentionSuggestionsEntryTitle": {
      fontSize: "80%",
      color: "#a7a7a7",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "& .chatMentionSuggestionsEntryContainer": { display: "table", width: "100%" },
    "& .chatMentionSuggestionsEntryContainerLeft, & .chatMentionSuggestionsEntryContainerRight": {
      display: "table-cell",
      verticalAlign: "middle"
    },
    "& .chatMentionSuggestionsEntryContainerLeft:hover, & .chatMentionSuggestionsEntryContainerRight:hover": {
      cursor: "pointer"
    },
    "& .chatMentionSuggestionsEntryContainerRight": {
      width: "90%",
      paddingLeft: "8px"
    },
    "& .chatMention": {
      background: "#CFE6F7",
      padding: "2px",
      margin: "0 1px",
    },
    "& .chatHashTag": {
      color: "blue",
    }
  },
  //Chat Gif Grid Section
  chatGifGrid: {
    "& .chatGifSuggestionBox": {
      right: "1rem",
      bottom: "110%",
      cursor: "pointer",
      display: "flex",
      zIndex: 9999,
      position: "absolute",
      background:theme.palette.containers.card,
      boxSizing: "border-box",
      minHeight: "10rem",
      borderRadius: "2px",
      flexDirection: "column",
      transformOrigin: "50% 0%",
      padding: "0.5rem",
      boxShadow: "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
    },
    "& .reactGiphySearchbox-listWrapper": { overflowY: "auto", overflowX: "hidden" }
  },
  //Chat Url Preview Grid Section
  chatUrlPreview: {
    "& .chatUrlPreviewBox": {
      width: "100%",
      cursor: "pointer",
      display: "flex",
      zIndex: 9999,
      position: "absolute",
      background: theme.palette.containers.card,
      boxSizing: "border-box",
      borderRadius: "2px",
      flexDirection: "column",
      transformOrigin: "50% 0%",
      left: "0",
      bottom: "100%",
      "& .react_tinylink_card": {
        width: "100%",
        maxWidth: "none",
      },
      "& .skeleton-container": {
        width: "100%",
        flexDirection: "row",
        height: "100px",
        borderRadius: "0px",
        overflow: "hidden",
        "& .Image": {
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "10vh",
          width: "10vw"
        }
      }
    },
  },
  '@keyframes rotate': {
    from: {
      WebkitTransform: "rotate(0deg)"
    },
    to: {
      WebkitTransform: "rotate(360deg)"
    },
  },
  recording: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    background: "transparent",
    borderRadius: "50%",
    border: "2px dashed #000",
    animationName: '$rotate',
    animationDuration: '3s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  recordingGrid: {
    position: "absolute",
    right: "0",
    bottom: "105%",
    borderRadius: "10rem",
    "& .recordingContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "0.5rem",
      border: "1px solid",
      borderRadius: "2rem"
    },
    "& .iconBtn": {

    }
  },
  urlPreview: {
    width: "100%",
    flexDirection: "row",
    height: "100px",
    borderRadius: "0px",
    overflow: "hidden",
    "& .Image": {
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      height: "10vh",
      width: "10vw"
    }
  },
  mobileSendIcon: {
    // background: palette.primary.gradientBtn
    background: '#2563EB',
    height: '36px',
    width: '36px',
    borderRadius: '36px',
    boxShadow: '0px 4px 25px rgba(64, 111, 252, 0.22)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: '#2563EB !important'
    },
    '@media only screen and (max-width: 768px)': {
      '&.MuiButtonBase-root': {
        marginLeft: '10px',
        marginRight: '5px'
      },
    }
  },
  infinityLogo: {
    height: '40px',
    width: '50px',
    padding: '0px !important',
    '&.active': {
      borderRadius: 50,
      backgroundImage: 'linear-gradient(white, white), linear-gradient(180deg, #8D53F2 , #9D3EDF,#2140FF )',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      padding: '1px !important',
      width: '40px',
      height: '40px',
      filter: ' drop-shadow(0 4px 2px rgba(0, 0, 0, 0.19))',
      border: '1px'
    }
  },
  BlueRecordIcon: {
    height: '45px',
    width: '45px',
  },
  linkPoper: {
    position: 'absolute',
    top: '-114px',
    left: 0,
    right: 0,
    width: '100%',
    borderRadius: '6px',
    backgroundColor: theme.palette.containers.bg13,
    padding: 12,
    zIndex: 1,
    '& img': {
      height: 88,
      width: '100%',
      objectFit: 'cover'
    },
    '& .closeContainer': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .closeContainer button': {
      padding: '12px'
    },
    '& .poper-card': {
      backgroundColor: theme.palette.containers.card
    }
  },

  editorRoot: {
    height: `${window.innerHeight - 100}px`,
    overflowY: "auto",
  },

  textMsgEditor: {
    fontSize: '0.875rem',
    fontWeight: 400,
    '&.chatSend': {
      color: theme.palette.text.contrastText,
    },
    '&.chatreceive': {
      color : theme.palette.primary.main
    },
  
    '& .chat-editor': {
      margin: 0
    },

  },

  textMsgEditorSpace: {
    fontSize: '0.875rem',
    fontWeight: 400,
    '&.chatSend': {
      color: theme.palette.text.contrastText,
    },
    '&.chatreceive': {
      color: theme.palette.primary.main
    },

    '& .chat-editorspace': {
      margin: 0
    },
    '& .chat-editorspace:last-child:after': {
      content: "''",
      width: '65px',
      height: "5px",
      display: "inline-block",
      verticalAlign: "baseline"
  },

  },
  
  chatEditorRoot: {
    '& .chatEditorRoot': {
      width: "100%",
      alignContent: 'center',
      '& p': {
        margin: 10, 
        [theme.breakpoints.down('sm')]: {
          margin: 0,
        },
      },
      wordBreak: 'break-word',
      '&:focus-visible': {
        outline: 'none',
        border: 'none',
      }
    },
    minHeight: "50px",
    maxHeight: "100px",
    overflowY: "auto",
    display: "flex"
  },
  
});

export default styles;
