import { authConstant, workspaceConstant } from "../constants";

const initialState = {
    loading: false,
    signup: {},
    userData: {},
    loginUser: {
        email: "",
        first_name: "",
        id: "",
        last_name: "",
        username: "",
        user_role: {},
        agencyName: null
    },
    emailReq: false,
    providerData: null,
    popupType: "",
    products: { prices: {}, discounts: [] },
    paymentStatus : 0,
    isSpecialUser:null,
    agencyLogin:{
        email: "",
        first_name: "",
        id: "",
        last_name: "",
        username: "",
        user_role: {},
        agencyName: null
    },
    collab_url:""
}


export default function users(state = initialState, action) {
    switch (action.type) {

        //login
        case authConstant.USER_LOGIN_R:
            return {
                ...state,
            }
        case authConstant.USER_LOGIN_S:
            console.log(action.data,"see action data reducer")
            return {
                ...state,
                loginUser: action.data.data,
                emailReq: false,
                providerData: null,
                isSpecialUser:action.data.data.checkSpecialFlag
            }
        case authConstant.USER_LOGIN_F:
            return {
                ...state,
            }

        case authConstant.UPDATE_STEP_2:
            return {
                ...state,
                loginUser: { ...state.loginUser, signupData: { ...state.loginUser.signupData, step_2: 1 } }
            }

        case authConstant.UPDATE_STEP_3:
            return {
                ...state,
                loginUser: { ...state.loginUser, signupData: { ...state.loginUser.signupData, step_3: 1 } }
            }


        case authConstant.GET_UPDATED_STATUS_R:
            return {
                ...state,
            }
        case authConstant.GET_UPDATED_STATUS_S:
            return {
                ...state,
                loginUser: { ...state.loginUser, status: action.data.data.status }
            }
        case authConstant.GET_UPDATED_STATUS_F:
            return {
                ...state,
            }

        //login
        case authConstant.GET_PROFILE_R:
            return {
                ...state,
            }
        case authConstant.GET_PROFILE_S:
            return {
                ...state,
                loginUser: action.data.data
            }
        case authConstant.GET_PROFILE_F:
            return {
                ...state,
            }

        //forget password
        case authConstant.FORGET_PASSWORD_R:
            return {
                ...state,
            }
        case authConstant.FORGET_PASSWORD_S:
            return {
                ...state,
            }
        case authConstant.FORGET_PASSWORD_F:
            return {
                ...state,
            }

        //reset password
        case authConstant.RESET_PASSWORD_R:
            return {
                ...state,
            }
        case authConstant.RESET_PASSWORD_S:
            return {
                ...state,
            }
        case authConstant.RESET_PASSWORD_F:
            return {
                ...state,
            }

        //logout
        case authConstant.LOGOUT_R:
            return {
                ...state,
            }
        case authConstant.LOGOUT_S:
            return {
                ...state,
            }
        case authConstant.LOGOUT_F:
            return {
                ...state,
            }

        //signup
        case authConstant.SIGNUP_R:
            return {
                ...state,
            }
        case authConstant.SIGNUP_S:
            return {
                ...state,
                signup: action.data.data,
                collab_url:action.data.data === 'redirect' ?action.data.message :""
            }
        case authConstant.SIGNUP_F:
            return {
                ...state,
            }

        //updateSignUpUserData
        case authConstant.UPDATE_SIGNUP_DATA_R:
            return {
                ...state,
            }
        case authConstant.UPDATE_SIGNUP_DATA_S:
            return {
                ...state,
                userData: action.data.data
            }
        case authConstant.UPDATE_SIGNUP_DATA_F:
            return {
                ...state,
            }

        //update payment
        case authConstant.UPDATE_PAYMENT_DATA_R:
            return {
                ...state,
            }
        case authConstant.UPDATE_PAYMENT_DATA_S:
            return {
                ...state,
            }
        case authConstant.UPDATE_PAYMENT_DATA_F:
            return {
                ...state,
            }

        // apple login email required stage
        case authConstant.SOCIAL_LOGIN_EMAIL_REQ:
            return {
                ...state,
                emailReq: true,
                providerData: action.data
            }

        case authConstant.SHOW_POPUP_LOGIN:
            return {
                ...state,
                popupType: action.data.message
            }

        //products list get
        case authConstant.GET_PRODUCTS_LIST_R:
            return {
                ...state,
                products: { prices: {}, discounts: [] }
            }
        case authConstant.GET_PRODUCTS_LIST_S:
            return {
                ...state,
                products: action.data.data
            }
        case authConstant.GET_PRODUCTS_LIST_F:
            return {
                ...state,
                products: { prices: {}, discounts: [] }
            }

        case workspaceConstant.INVITE_AGENCY_S:
            let loginUser = { ...state.loginUser, wsm: false }
            if (state.loginUser.hasOwnProperty('wsm')) {
                loginUser['wsm'] = true;
            }
            return {
                ...state,
                loginUser
            };
        case workspaceConstant.ACCEPT_SHARE_INVITE_S:
            let loginUser1 = { ...state.loginUser, wsm: false }
            if (state.loginUser.hasOwnProperty('wsm')) {
                loginUser1['wsm'] = true;
            }
            return {
                ...state,
                loginUser: loginUser1
            };
        case authConstant.UPDATE_FLOWS_ORDER: {
            let ag_users = state.loginUser.ag_users.map((user) => {
                if (user.id === action.data.user_id) {
                    user.resource_order = {
                        ...user.resource_order,
                        flows: action.data.ids
                    }
                }
                return user
            })
            return {
                ...state,
                loginUser: {
                    ...state.loginUser,
                    ag_users
                }
            };
        }
        case authConstant.UPDATE_CLIENTS_ORDER: {
            let ag_users = state.loginUser.ag_users.map((user) => {
                if (user.id === action.data.user_id) {
                    user.resource_order = {
                        ...user?.resource_order,
                        clients: {
                            ...user?.resource_order?.clients,
                            [action.data.type]: action.data.ids
                        }
                    }
                }
                return user
            })
            return {
                ...state,
                loginUser: {
                    ...state.loginUser,
                    ag_users
                }
            };
        }

        // update preference
        case authConstant.SAVE_TASK_PREFERENCE: {
            return {
                ...state,
                loginUser : {
                    ...state.loginUser,
                    preference : {
                        ...state.loginUser.preference,
                        task : {
                            ...state.loginUser.preference.task,
                            ...action.data
                        }
                    }
                }

            }
        }

        case authConstant.UPDATE_USER_PREFERENCE: {
            return {
                ...state,
                loginUser : {
                    ...state.loginUser,
                    preference : {
                        ...action.data
                    }
                }

            }
        }

        case authConstant.UPDATE_PAYMENT_STATUS_S : {
            return {
                ...state,
                paymentStatus: action.data
            }
        }

        case authConstant.GET_PAYMENT_STATUS_R : {
            return {
                ...state,
                paymentStatus: action.data
            }
        }

        case authConstant.GET_PAYMENT_STATUS_S : {
            return {
                ...state,
                paymentStatus: action?.data?.data?.is_payment_processing
            }
        }

        case authConstant.GET_PAYMENT_STATUS_F : {
            return {
                ...state,
                paymentStatus: action.data
            }
        }

        case authConstant.REMOVE_PLAN_DATA_S : {
            return {
                ...state
            }
        }
        case authConstant.AGENCY_LOGIN_SIGNUP_S: {
            return {
                ...state,
                agencyLogin:action.data 
            }
        }
        case authConstant.CLEAR_STATE_VALUES_S: {
            return {
                ...state,
                agencyLogin:{},
                signup:{}

            }
        }

        case authConstant.AUTH_SIGNUP_RESET_S:{
            return {
                ...state,
                signup: state.signup === 'redirect' ? '' : state.signup,
                collab_url:state.collab_url?.length > 0 ? '' : ''
            }
        }
        


        default:
            return state
    }
}
