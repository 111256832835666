import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, TextField, Avatar, Card, CardContent, InputAdornment } from "@material-ui/core";

//import styles
import styles from "./styles";
import { Typography } from '../Typography';
import { TitleAvatar } from '../TitleAvatar';
import { useSelector } from 'react-redux';
import { DownArrowCurved, SearchIcon } from '../../assets/svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



function AutoCompleteComponent(props) {
  const { id: userId } = useSelector((s) => s.auth.loginUser)
  const {
    suggestions,
    name,
    disabled,
    value,
    classes,
    showValidate,
    validationMsg,
    fullWidth,
    placeholder,
    className,
    renderTags,
    smallIcon,
    showSearchIcon,
    InputProps
  } = props;

  return (
    <Fragment >
      <Autocomplete
        multiple
        id={`ac-id${name}`}
        name={`${name}`}
        variant={"outlined"}
        disabled={disabled}
        options={suggestions}
        fullWidth={fullWidth}
        className={className}
        hiddenLabel="true"
        classes={{ paper: classes.paper, option: classes.option }}
        disableClearable={true}
        getOptionLabel={option => option.label || option.username || ''}
        getOptionSelected={(option, value) => {
          if (option.value !== null && value.value !== null) {
            return option.value === value.value
          } else {
            return option.username === value.username
          }
        }
        }
        renderOption={option => {
          let recipientId = option.id || String(option.value)
          
          const userName = (text, maxLength) => {
            return text && text.length > maxLength ? text.substr(0, maxLength) + "..." : text;
          };
        
          const label = userName(option.label || option.username, 25);
          const helperText = userName(option.helperText, 25);
          return (
            <Fragment>
              <Card className={classes.root}>
                {option && option.image ?
                  <TitleAvatar type='avatar' userId={userId} recipientId={recipientId} alt={option.label || option.username} value={option && option.image ? option.image : ""} />
                  :
                  option && option.hasOwnProperty("avatar_filename") && option.avatar_filename ? <Avatar alt={option.label || option.username} src={option && option.avatar_filename ? option.avatar_filename : "/static/images/avatar/1.jpg"} />
                    :
                    <TitleAvatar type='avatar' userId={userId} recipientId={recipientId} title={option.label || option.username} alt={option.label || option.username} value={`/static/images/avatar/1.jpg`} />
                }
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography color="primary" variant="body1">
                      {label}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {helperText}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Fragment>
          );
        }
        }
        filterSelectedOptions={true}
        value={value ? value : []}
        onChange={(event, newValue) => { props.handleAutoComplete(newValue || [], name) }}
        onInputChange={(value, eValue, reason) => { props.onInputChange(value, name, reason) }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              error={showValidate ? true : false}
              helperText={showValidate ? validationMsg : ""}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
            />
          </>
        )}
        renderTags={renderTags}
        popupIcon={<ExpandMoreIcon fontSize="small" />}
      />
    </Fragment>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  handleAutoComplete: () => { },
  onInputChange: () => { },
  showValidate: false,
  validationMsg: '',
  placeholder: 'Enter value',
  showSearchIcon:false,
  InputProps: {}, 
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  onInputChange: PropTypes.func,
  showValidate: PropTypes.bool,
  validationMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  InputProps: PropTypes.object,
};

/** Export Component */
export const UserAutoComplete = withStyles(styles)(AutoCompleteComponent);
