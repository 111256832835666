import React, { useEffect, useState } from "react";
import { Avatar, Popover, Grid, IconButton, withStyles, InputAdornment, useMediaQuery } from "@material-ui/core";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

// images
import { ChevronIcon, CloseIcon, SearchIcon3, TimeZoneIcon } from "../../assets/svg";

// components
import { TextField, Typography } from "..";

//Style
import Styles from './styles'

// action
import { dashboardAction } from "../../redux/actions";
import { isMobile } from "react-device-detect";

const TimezonePopup = (props) => {
    const dispatch = useDispatch()

    const isTab =  useMediaQuery('(max-width:899px)')

    // props
    const { handleChange, classes, label, disabled, value, tz_str, className, scheduleFlag } = props;

    // reducer
    const { cityTimeZoneOptions } = useSelector(d => d.dashboard);

    // state
    const [openTimezonePopup, setOpenTimezonePopup] = useState(false);
    const [timeZones, setTimeZones] = useState([])

    useEffect(() => {
        dispatch(dashboardAction.getCityTimeZones({ qry: "", showLoader: false }))
    }, [dispatch])

    useEffect(() => {
        const timeZoneOptions = cityTimeZoneOptions.reduce((acc, cur) => {
            if (!acc[cur.continent]) {
                acc[cur.continent] = []
            }
            acc[cur.continent].push(cur)
            return acc;
        }, {})
        let cities = []
        Object.entries(timeZoneOptions).map(i => {
            cities.push({ name: i[0], disabled: true })
            cities.push(...i[1])
        })
        setTimeZones(cities)
    }, [cityTimeZoneOptions])

    // close item selection
    const handleClose = () => {
        setOpenTimezonePopup(false);

        // for removing search values
        if (cityTimeZoneOptions.length !== 20) {
            dispatch(dashboardAction.getCityTimeZones({ qry: "", showLoader: false }))
        }
    }

    const onSearch = (qry) => {
        if (qry && qry.length > 1) {
            dispatch(dashboardAction.getCityTimeZones({ qry, showLoader: false }, () => { }));
        }
    }

    function findTime(timezoneName) {
        const date = new Date();
        const options = { timeZone: timezoneName, hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit' };
        const formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedTime.slice(0, 5) + formattedTime.slice(8)
    }

    return (
        <>
            <Grid className={`${classes.tzCont} ${(!scheduleFlag && !isTab) && classes.marginTop15} tzCont marginTop15 cursorPointer ${isMobile?'pt-1':''}`} onClick={!disabled ? (e) => { setOpenTimezonePopup(e) } : () => { }}>
                <TimeZoneIcon/>
                <Grid className="dflex alignCenter justifyBetween">
                    <Typography color="primary" className={`${className} secondary1 mr5 fontcolor`}>{(tz_str || value) ? (tz_str || value) : 'Select Timezone'}</Typography>
                    <Grid className="dflex alignCenter">
                        <ChevronIcon />
                    </Grid>
                </Grid>
            </Grid>
            <Popover
                className={`${classes.userModelPopper} ${classes.timeZonePopup}`}
                anchorEl={openTimezonePopup?.target}
                onClose={() => { handleClose() }}
                open={!!openTimezonePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Grid container justifyContent="space-between" className="addUsersHeader">
                    <Typography variant="h4" className="fs-18 fw-600">{label}</Typography>
                    <IconButton className="closeBtn" onClick={() => { handleClose() }}><CloseIcon /></IconButton>
                </Grid>
                <Grid item xs={12} className="pl-2 pr-2 mb-1 pt-1">
                    <TextField
                        style={{ width: '100%', height: 30 }}
                        onChange={(e) => { onSearch(e.target.value) }}
                        placeholder={"Search here"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon3 />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid className="usersList pl-1 pr-1">
                    {timeZones && timeZones.length > 0 ?
                        <>
                            {timeZones.map((item, inx) => (
                                <Grid
                                    key={inx}
                                    className={"pb-1 cursorPointer"}
                                >
                                    {(item?.avatar_filename || item?.label) && <Avatar
                                        src={item.avatar_filename || item.label || item.name}
                                        alt={item.avatar_filename || item.label}
                                    />}
                                    {item.disabled ?
                                        <Typography variant="body1" color="primary" className="fs-16 fw-600 pl-1">
                                            {item.label || item.name}
                                        </Typography> :
                                        <Grid className={classes.flxCls} onClick={() => {
                                            handleChange({ ...item, tz_str: `${item.label || item.name},${item.country} (${item.offset})` });
                                            handleClose();
                                        }}>
                                            <Typography variant="body1" color="secondary" className="fs-12 fw-500 pl-1">
                                            {item.label || item.name}, {item.province==item.name?"":`${item.province},`} {item.country} {`(${item.offset})`}

                                            </Typography>
                                            <Typography variant="body1" color="secondary" className="fs-12 fw-500 pl-1 whiteSpacNowrap">
                                                {item?.timezone ? findTime(item.timezone) : ''}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            ))}
                        </>
                        :
                        <Typography variant="body1" color="primary" align={"center"} className="fs-14 fw-500 pl-1">No data Found</Typography>
                    }
                </Grid>
            </Popover>
        </>
    );
}

TimezonePopup.defaultProps = {
    handleChange: () => { },
    classes: {},
    className: 'pl-3',
    label: "Timezone",
    disabled: false,
    scheduleFlag: false,
    tz_str: null
};

TimezonePopup.propTypes = {
    handleChange: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    scheduleFlag: PropTypes.bool,
    tz_str: PropTypes.string,
}

export const TimeZone = withStyles(Styles)(TimezonePopup)