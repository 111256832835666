import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles, TextField, Card, CardContent } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//import styles
import styles from "./styles";
import { Typography } from '../Typography';
import { TitleAvatar } from '../TitleAvatar';


function AutoCompleteComponent(props) {

  const {
    suggestions,
    name,
    disabled,
    value,
    classes,
    showValidate,
    validationMsg,
    fullWidth,
    placeholder,
    InputProps
  } = props;


  return (
    <Fragment >
      <Autocomplete
      className={classes.autoCompleteRoot}
        popupIcon={<ExpandMoreIcon fontSize="small" />}
        id={`ac-id${name}`}
        name={`${name}`}
        variant={"outlined"}
        disabled={disabled}
        options={suggestions}
        fullWidth={fullWidth}
        hiddenLabel="true"
        disableClearable={true}
        getOptionLabel={option => option.label}
        renderOption={option => {
          return (
            <Fragment>
              <Card className={`${classes.root} w-100`}>
                {/* {option && option.avatar && ?
                  <Avatar alt={option.label} src={option && option.image ? option.image : ""} />
                  :
                  <Avatar alt={option.label} src={`/static/images/avatar/${option && option.value ? option.value : 1}.jpg`} />
                } */}
                <div className={`${classes.details} w-100`}>
                  <CardContent className={`oneLineEllipNowrap ${classes.content} dflex w-100`}>
                    {option.logo ? option.logo : <TitleAvatar smallIcon type={option.avatar_type} value={option.avatar} />}
                    <Typography component="body2" className="oneLineEllipNowrap">
                      {option.label}
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Fragment>
          );
        }
        }
        filterSelectedOptions={true}
        value={value ? value : []}
        onChange={(event, newValue) => { props.handleAutoComplete(newValue || [], name) }}
        onInputChange={(value, eValue, reason) => { props.onInputChange(value, name, reason) }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            error={showValidate ? true : false}
            helperText={showValidate ? validationMsg : ""}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                  <ExpandMoreIcon className={classes.dropdownIcon} />
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Fragment>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  handleAutoComplete: () => { },
  onInputChange: () => { },
  showValidate: false,
  validationMsg: '',
  placeholder: 'Enter value',
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  onInputChange: PropTypes.func,
  showValidate: PropTypes.bool,
  validationMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
};

/** Export Component */
export const JobAutoComplete = withStyles(styles)(AutoCompleteComponent);
