import { isFlozyMobileApp } from "../../utils";
import { alertConstant } from "../constants/alertConstant";

const initialState = {
  message: "",
  type: ""
};

const alertReducer = (state = initialState, action) => {
  if (!action || (action && !action.type)) return state;

  switch (action.type) {
    case alertConstant.ALERT_ERROR:
      if (!isFlozyMobileApp() || (isFlozyMobileApp() && action.error !== "Error: Network Error" && action.error !== "AxiosError: Network Error" && action.error !== 'Internal Server Error!' && action.error !== "AxiosError: timeout exceeded")) {
        return {
          ...state,
          message: action.error && action.error === "Error: Network Error" ? "No Internet Connection" : action.error,
          type: alertConstant.ALERT_ERROR
        };
      }
      return initialState
    case alertConstant.ALERT_INFO:

      const data = action.data;
      return {
        ...state,
        message: data?.message || "",
        type: data?.status === "success" ? alertConstant.ALERT_SUCCESS : alertConstant.ALERT_ERROR
      };
    case alertConstant.ALERT_INFORM:
      return {
        ...state,
        message: action.data || "",
        type: alertConstant.ALERT_INFORM
      };
    case alertConstant.ALERT_SUCCESS:
      return {
        ...state,
        message: action.data,
        type: alertConstant.ALERT_SUCCESS
      };
    case alertConstant.ALERT_CLEAR:
      return {
        ...state,
        ...initialState
      };
    case alertConstant.ALERT_INFORM:
      return {
        ...state,
        message: action.data || "",
        type: alertConstant.ALERT_INFORM
      };
    default:
      return state;
  }
}

export default alertReducer;
