export const saveState = (state) => {
	try {
	const serialState = JSON.stringify(state);
	const result = localStorage.setItem('persistStore', serialState);
	} catch(err) {
		console.log(err);
	}
};


export const loadState = () => {
	try {
	const serialState = localStorage.getItem('persistStore');
	if (serialState === null) {
		return undefined;
	}
	return JSON.parse(serialState);
	} catch (err) {
	return undefined;
	}
};

export const removeState = () => {
	try {
	const serialState = localStorage.removeItem("persistStore");
	if (serialState === null) {
		return undefined;
	}
	return JSON.parse(serialState);
	} catch (err) {
	return undefined;
	}
};

export const removeToken = () => {
	try {
	const serialState = localStorage.removeItem("key");
	if (serialState === null) {
		return undefined;
	}
	return JSON.parse(serialState);
	} catch (err) {
	return undefined;
	}
};

