import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from "prop-types";
import { ChatEditor as Editor } from "@flozy/editor";
import { useLocation } from "react-router-dom";
import { Grid, IconButton, withStyles, Popover, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { Skeleton } from '@material-ui/lab';

import useCapacitorKeyboard from "../../../hooks/keyboard_listener";

// Imports Styles
import styles from "./style";

//Icons
import { InfinityLogo, InputPlusIcon, MobileSend, ChatSendIconNew, ChatGifNew, ChatAttachmentIcon, ChatMicIcon, BlueRecordIcon, CloseIcon, InfinityMobile } from '../../../assets/svg'

//Import Components
import GiphyComponent from "../../GiphyComponent";
import { InfoDialogComponent } from "../../InfoDialogComponent";
import { EmojiPickerComponent } from "../../FlozyEmojiPicker";
import { AudioRecorder } from "../../AudioRecorder";
import { MobileAudioRecorder } from '../../MobileComponents/MobileAudioRecorder';
import { OutsideClickHandler } from '../../../hoc';
import { isFlozyMobileApp, setDraftMessage, extractLinks, serializeToText } from '../../../utils';
import { commonAction } from '../../../redux/actions/commonAction';

/**
 * TextField Component
 * @param {*} props
 */
const TextEditor = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { flags } = useSelector(s => (s.featureFlags));
  const { planDetails } = useSelector( s => s.userAgency);
  const { id, type, content = '', classes, handleChatSend, handleAttachment, handleChatGif, handleTyping, handleVoiceNoteMessage, infinityModeHandler, typingUsers, userId, unsendMessages, updateDraftMessages, actionIcons, editChatData, backgroundColor, otherProps, dropzone = false, handleChatChange,reply } = props;
  const [gifOpen, setGifOpen] = useState(null);
  const [tinyUrl, setTinyUrl] = useState(null);
  const [tinySelectedUrl, setTinySelectedUrl] = useState(null)
  const [tinyUrlMeta, setTinyUrlMeta] = useState([]);
  const [tinyFetchedUrls, setTinyFetchedUrls] = useState([]);
  const [chatInputText, setChatInputText] = useState(content.length ? serializeToText(content) : '');
  const [chatRawContent, setChatRawContent] = useState(content);
  const [recordingStatus, setRecordingStatus] = useState(false)
  const [audioPermission, setAudioPermission] = useState(false);
  const [audioDevice, setAudioDevice] = useState(false);
  const [audioDeviceWaring, setAudioDeviceWarning] = useState(false);
  const { channel } = useSelector(s => s.chatv2)
  const { profile } = useSelector(s => s.profile)
  const activeHubData = (channel[type] || []).find(f => f.id == id)
  const [infinityButtonOn, setInfinityButtonOn] = useState(false)
  const [linkPoperLoading, setLinkPoperLoading] = useState(false);
  const theme = useTheme()

  const editorRef = useRef(null);
  const emojiRef = useRef(null)


  const isKeyboardVisible = useCapacitorKeyboard();

  const pasteListenerAdded = useRef(false);

  const has_mention = chatRawContent?.length ? chatRawContent.some(paragraph => 
    paragraph.children.some(child => child.type === "mention")
  ) : false;

  useImperativeHandle(ref, () => ({
    onThumbnailClick: (message_text) => {
      editorRef?.current?.clearAll(message_text, true);
    },
    clearEditorState: (msgId) => {}
  }));

  useEffect(() => {
    if (!isMobile) {
      editorRef?.current?.clearAll(content, true);
    }

  }, [location, id,reply])


  useEffect(() => {
    const handlePaste = async (event) => {
      const items = event.clipboardData.items;
      let isTextPasted = false;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file' && item.type.includes('image')) {
          const blob = item.getAsFile();
          const url = URL.createObjectURL(blob);
          handleAttachment({ isPaste: true, blob: blob, url: url });
        }
      }
      if (isMobile) {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const plainText = clipboardData.getData('text/plain');
        const editor = document.getElementById(`chat-msg-editor_${actionIcons.length}`);
        if (editor) {
          const selection = window.getSelection();
          if (!selection.rangeCount) return;
          const range = selection.getRangeAt(0);
          range.deleteContents();
          const textNode = document.createTextNode(plainText);
          range.insertNode(textNode);
          range.setStartAfter(textNode);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
          const eventInput = new Event('input', { bubbles: true });
          editor.dispatchEvent(eventInput);
        }
        isTextPasted = true;
      }
    };

    if (!pasteListenerAdded.current) {
      window.addEventListener('paste', handlePaste);
      pasteListenerAdded.current = true;
    }

    return () => {
      if (pasteListenerAdded.current) {
        window.removeEventListener('paste', handlePaste);
        pasteListenerAdded.current = false;
      }
    };
  }, [handleAttachment]);

  useEffect(() => {
    // Deactivates infiniti mode when user is in Infiniti chat room.
    if (activeHubData?.isInfiniti === 1) {
      setInfinityButtonOn(false)
      infinityModeHandler(true)
    }
  }, [activeHubData?.isInfiniti])

  useEffect(() => {
    if (tinyUrl && tinyUrl.length > 0) {
      let url = tinyUrl.filter(url => url.startsWith("https:"))
      if (url.length > 0) {
        let urlSelect = url[0];
        if (urlSelect !== tinySelectedUrl) {
          setTinySelectedUrl(urlSelect)
        }
        const val = url[0];
        if (!tinyFetchedUrls.includes(val)) {
          setLinkPoperLoading(true);
          getMetadataInfo({ url: val?.trim() })
        }
      }
    } else {
      setLinkPoperLoading(false);
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyUrl(null);
    }
  }, [tinyUrl])

  useEffect(() => {
    if (chatInputText && chatInputText.length > 0) {
      let urls = extractLinks(chatInputText)
      if (urls && tinyUrl !== urls) {
        setTinyUrl([...urls])
      }
      else {
        setTinyUrl(null);
      }
    } else {
      setTinyUrl(null);
      setTinyFetchedUrls([])
      setTinyUrlMeta([]);
    }
  }, [chatInputText])


  async function getMedia() {
    try {
      const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      if (isFlozyMobileApp()) {
        setRecordingStatus(true);
      } else {
        if (audioMedia.active && audioMedia.id) {
          setAudioDevice(true)
          setAudioPermission(true)
          setRecordingStatus(true);
        } else {

          setAudioDevice(true)
          setAudioPermission(false)
        }
      }
    } catch (err) {
      setAudioDevice(false)
      setAudioDeviceWarning(true)
    }
  }

  const handleClose = () => {
    setGifOpen(null)
  };

  const getMetadataInfo = (params) => {
    dispatch(commonAction.getUrlMetadata(params, (resp) => {
      if (chatInputText) {
        setTinyUrlMeta([resp]);
      }
      setLinkPoperLoading(false);
    }, () => {
      setTinyUrlMeta([]);
      setLinkPoperLoading(false);
    }))
  }

  const onChange = (rawTxt, text_message) => {
    if (JSON.stringify(chatRawContent) !== rawTxt) {
      const updated = (text_message?.text?.trim() || "") === chatInputText
      if (!typingUsers?.[`${type}_${id}_${userId}`] && chatInputText.length >= 1 && !dropzone && !updated) {
        handleTyping(true)
      }
      handleChatChange(rawTxt)
      setChatRawContent(JSON.parse(rawTxt))
      setChatInputText(text_message?.text.trim() || "")
    }
  };


  const handleChatSubmit = async (get_welcome_message = false, chatContent = null) => {
    const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url")) : []
    const mentions = chatContent?.strVal ? JSON.parse(chatContent?.strVal) : [];
    const hasMention = mentions.some(paragraph => 
      paragraph.children.some(child => child.type === "mention")
    );

    const hasContent = chatContent && ((chatContent.restVal?.text?.trim() !== "") || hasMention);

    if (get_welcome_message || hasContent) {
      let is_infiniti = 0
      if (activeHubData?.isInfiniti == 1 || get_welcome_message) {
        is_infiniti = 1
      }
      let chatText = chatInputText
      let chat_rawContent = chatRawContent
      if (chatContent) {
        const { strVal, restVal } = chatContent
        chat_rawContent = JSON.parse(strVal)
        chatText = restVal?.text || ""
      }
      const trimmedValue = [...chat_rawContent];
      while (trimmedValue.length > 0 && 
             trimmedValue[trimmedValue.length - 1].type === 'paragraph' && 
             trimmedValue[trimmedValue.length - 1].children?.every(child => child?.text?.trim() === '')) {
          trimmedValue.pop();
      }
      
      let dataObj = {
        chatText: chatText?.trim(),
        chatRawContent: trimmedValue,
        hashtags: [],
        mentions: [],
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }

      if (get_welcome_message) {
        dataObj['get_welcome_message'] = get_welcome_message
      }

      // changeContent()
      editorRef?.current?.clearAll(null, true);
      emojiRef?.current?.onClose();
      setDraftMessage(id, "");
      setChatInputText("")
      setChatRawContent(null)
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyFetchedUrls([]);
      handleChatSend(dataObj, is_infiniti)
      let sendMessages = {}
      sendMessages[`${id}`] = ''
      let messages = unsendMessages
      delete messages[`${id}`];
      updateDraftMessages(messages)
      handleTyping(false)
    } else if (dropzone) {
      let dataObj = {
        chatText: null,
        chatRawContent: null,
        hashtags: [],
        mentions: [],
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }
      handleChatSend(dataObj, false)
    } else
      return false;
  }

  const onEmojiSelect = (emoji) => {
    editorRef?.current?.emojiClick(emoji)
  };

  const onGifSelect = (data) => {
    if (isMobile) {
      setTimeout(() => {
        handleChatGif(data)
        setGifOpen(null)
      }, [1000])
    } else {
      handleChatGif(data)
      setGifOpen(null)
    }
  }


  const stopAudioRecording = () => {
    setRecordingStatus(false);
  }

  const sendVoiceNote = (data) => {
    handleVoiceNoteMessage(data)
    setRecordingStatus(false);
  }

  const infinityButtonHandler = () => {
    setInfinityButtonOn(!infinityButtonOn)
    infinityModeHandler(infinityButtonOn)
  }

  useEffect(() => {
    if (infinityButtonOn && profile.show_ai_welcome_msg) {
      // To trigger a welcome message
      handleChatSubmit(true);
    }
  }, [infinityButtonOn])


  const handleBlur = (chatContent) => {
    const { restVal } = chatContent
    const chatText = restVal?.text || ""
    if(!dropzone) {
      handleTyping(false);
      setDraftMessage(id, chatText);
    }
  }


  const getLinkPoper = () => {
    if (tinyUrlMeta?.length > 0 && chatInputText.trim().length > 0) {
      return (
        <div className={classes.linkPoper}>
          <Grid container alignItems='center'>
            <Grid item xs={11} style={{ background: '#E4EAF2' }}>
              <Grid container className='poper-card'>
                <Grid item xs={4} md={2} className='dflex alignCenter'>
                  <img src={tinyUrlMeta[0]?.thumbnail_url || tinyUrlMeta[0]?.icon} alt="" />
                </Grid>
                <Grid item xs={8} md={10} className='pl-1 pr-1'>
                  <Typography variant='h6' className={tinyUrlMeta[0]?.description ? 'twoLineEllip' : 'threeLineEllip'}>{tinyUrlMeta[0]?.title || tinyUrlMeta[0]?.meta?.title}</Typography>
                  <Typography variant='body2' className='twoLineEllip'>{tinyUrlMeta[0]?.description || tinyUrlMeta[0]?.meta?.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} className='closeContainer'>
              <IconButton
                onClick={() => {
                  setLinkPoperLoading(false);
                  setTinySelectedUrl(null);
                  setTinyUrlMeta([]);
                  setTinyUrl(null);
                }}
                size='small'
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      if (linkPoperLoading && chatInputText.trim().length > 0) {
        return (
          <div className={classes.linkPoper}>
            <Grid container>
              <Grid item xs={5} md={3}>
                <Skeleton variant="rectangular" width={124} height={88} />
              </Grid>
              <Grid item xs={7} md={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Skeleton variant="text" style={{ fontSize: '1.5rem' }} />
                    <Skeleton variant="text" style={{ fontSize: '.75rem' }} />
                    <Skeleton variant="text" style={{ fontSize: '.75rem' }} />
                    <Skeleton variant="text" style={{ fontSize: '.75rem' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
      }
    }
    return null
  }

  return (
    <>
      {
        actionIcons.includes('send') && getLinkPoper()
      }
      <Grid className={`chatInputBox ${classes.chatInputBox} ${isAndroid && !actionIcons.includes('send') ? 'p0' : ''}`} ref={ref}>
        <Grid container alignItems="center" style={{ background: backgroundColor }}>
          <Grid
            item
            xs={12}
            className={`${classes?.chatEditorRoot}`}
          >
            {
              actionIcons.includes('plus') && !infinityButtonOn &&
              <IconButton onClick={handleAttachment} className="mShow"><InputPlusIcon /></IconButton>
            }
            <Editor
              ref={editorRef}
              id={id}
              theme={theme}
              className={'chatEditorRoot'}
              toolBar={false}
              otherProps={otherProps}
              content={content}
              onSave={onChange}
              onsubmit={handleChatSubmit}
              onBlur={handleBlur}
              isMobile={isFlozyMobileApp()}
            />

            {isMobile && actionIcons.includes('gif') && chatInputText?.trim()?.length === 0 &&
              <IconButton
                onClick={(event) => {
                  if (gifOpen) {
                    setGifOpen(null);
                  } else {
                    setGifOpen(event.currentTarget);
                  }
                }}
              >
                <ChatGifNew />
              </IconButton>
            }
          </Grid>
        </Grid>
      </Grid>

      <Grid className={`${classes.chatTypAttach} chatTypAttach dFlexShow`}>
        {( !dropzone && !reply && ( planDetails.plan_code?.includes("educate") || planDetails.plan_code === 'pro_plan_v2'|| planDetails.plan_code === 'pro_plan')) && type !== "teams" && type !== "teams" && Object.keys(editChatData)?.length === 0 &&
          activeHubData?.isInfiniti != 1 && actionIcons.includes('infinity') && <IconButton onClick={infinityButtonHandler} className={`floatOption ${classes.infinityLogo} ${infinityButtonOn && 'active'}`}><InfinityLogo /></IconButton>
        }
        {
          actionIcons.includes('plus') && !dropzone && !infinityButtonOn &&
          <OutsideClickHandler onOutsideClick={() => handleAttachment(false)}>
            <IconButton onClick={handleAttachment} className="floatOption ml-1 p0"><ChatAttachmentIcon /></IconButton>
          </OutsideClickHandler>
        }

        <Grid className='ml-1 pt5'>
        <EmojiPickerComponent
          onEmojiClck={onEmojiSelect}
          ref={emojiRef}
          fromInput = {true}

        />
        </Grid>
         
        {
          actionIcons.includes('audio') && !dropzone && !infinityButtonOn &&
          <IconButton onClick={() => getMedia()} className="floatOption ml-1 p0">
            <ChatMicIcon />
            {recordingStatus && <span className={classes.recording}></span>}
          </IconButton>
        }
        {/* Gif Container Start */}
        {
          actionIcons.includes('gif') && !dropzone && !infinityButtonOn &&
          <>
            <IconButton onClick={(event) => setGifOpen(event.currentTarget)} className="floatOption gif ml-1 p0"><ChatGifNew /></IconButton>
            <Grid>
              <Popover
                id={"gif"}
                anchorEl={gifOpen}
                className={`${classes.popoverIcon} ${isKeyboardVisible ? 'bottomWithKeyboard' : ''}`}
                open={Boolean(gifOpen)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                style={isKeyboardVisible ? { top: 'env(safe-area-inset-top)', bottom: '70px' } : {}}
              >
                <GiphyComponent
                  handleSelect={onGifSelect}
                />

              </Popover>

            </Grid>
          </>
        }
        {/* Gif Container  End */}

        {
          actionIcons.includes('send') && !dropzone &&
          <IconButton onClick={() => editorRef?.current?.submitChat()} className="floatOption p0 ml-1"><ChatSendIconNew /></IconButton>
        }

      </Grid>
      {(isMobile || isAndroid || isIOS) && ( !dropzone && ( planDetails.plan_code?.includes("educate") || planDetails.plan_code === 'pro_plan_v2'|| planDetails.plan_code === 'pro_plan')) && type !== "teams" && type !== "teams" && Object.keys(editChatData)?.length === 0 &&
          activeHubData?.isInfiniti != 1 && actionIcons.includes('infinity') && <IconButton onClick={infinityButtonHandler} className={`${classes.BlueRecordIcon} ${classes.infinityLogo} ${infinityButtonOn && 'active'}`}><InfinityMobile /></IconButton>
      }
      {
        (isMobile || isAndroid || isIOS) && (actionIcons.includes('send')) && (chatInputText?.trim()?.length > 0 || has_mention === true) &&
        <IconButton id="mobile-send-button" onClick={() => editorRef?.current?.submitChat()} className={`${classes.mobileSendIcon}`}>
          <MobileSend />
        </IconButton>
      }
      {
        (isMobile || isAndroid || isIOS) && (actionIcons.includes('audio')) && (chatInputText?.trim()?.length === 0 && has_mention === false) && !infinityButtonOn &&
        <IconButton className={classes.BlueRecordIcon} onClick={() => getMedia()}>
          <BlueRecordIcon />
          {recordingStatus && <span className={classes.recording}></span>}
        </IconButton>
      }
      {
        audioPermission && audioDevice && recordingStatus && !isFlozyMobileApp() && actionIcons.includes('audio') && (
          <AudioRecorder handleClose={stopAudioRecording} handleSendVoiceNote={sendVoiceNote} />
        )
      }

      {
        isFlozyMobileApp() && recordingStatus && actionIcons.includes('audio') && (
          <MobileAudioRecorder handleClose={stopAudioRecording} handleSendVoiceNote={sendVoiceNote} />
        )
      }
      <InfoDialogComponent
        open={audioDeviceWaring}
        title="Microphone not found"
        content="You can’t record a Voice Message because it looks like your computer doesn’t have a microphone. Try connecting one or if you have one connected, try restarting your browser."
        handleCloseAction={() => setAudioDeviceWarning(false)}
      />
    </>
  );
})

// default props
TextEditor.defaultProps = {
  variant: "outlined",
  editChatData: {},
  // notesText: ``,
  // handleChatText: () => { },
  handleChatSend: () => { },
  updateDraftMessages:() =>{ },
  handleAttachment: () => { },
  handleChatGif: () => { },
  handleTyping: () => { },
  infinityModeHandler: () => { },
  activateInfinity: false,
  placeHolder: "Start Typing...",
  unsendMessages: {},
  actionIcons: ['plus', 'audio', 'emoji', 'gif', 'send', 'infinity'],
  backgroundColor: 'F0F0F0',
  handleChatChange: () => { },
  handleVoiceNoteMessage:()=>{}
};

// prop types
TextEditor.propTypes = {
  variant: PropTypes.string,
  editChatData: PropTypes.object,
  handleChatSend: PropTypes.func,
  handleAttachment: PropTypes.func,
  infinityModeHandler: PropTypes.func,
  handleChatGif: PropTypes.func,
  handleTyping: PropTypes.func,
  placeHolder: PropTypes.string,
  activateInfinity: PropTypes.bool,
  unsendMessages: PropTypes.object,
  actionIcons: PropTypes.array,
  handleChatChange: PropTypes.func,
};

// export component
export const ChatInputComponent = withStyles(styles)(TextEditor);
