
const PrivateLayoutStyles = (theme) => {
  return ({
    container: {
      // paddingTop: '16px',
      width: "100%",
      alignContent: "baseline",
      overflowY: "auto",
      background: theme.palette.containers.body,
      '& .btnsCloseBtn': {
        background: theme.palette.buttons.bg,
        color: theme.palette.buttons.color,
        borderColor: theme.palette.buttons.border
      },
      "& aside.emoji-picker-react": {
        boxShadow: theme.palette.shadows.shadow5,
        background: theme.palette.containers.emojiBg,
        border: `1px solid ${theme.palette.primary.border1}`,
        '& input.emoji-search': {
          background: theme.palette.containers.emojiBg,
        },
        '& .emoji-group::before': {
          background: theme.palette.containers.emojiBg,
        }
      },
      "& .newToolarShow": {
        background: `${theme.palette.containers.toolBar} !important`,
        boxShadow: theme.palette.shadows.shadow3,
      },
      "& .popup, .tab-popup, .option, .hexPreview": {
        boxShadow: theme.palette.shadows.shadow7,
        backgroundColor: theme.palette.containers.card,
        '& input': {
          backgroundColor: theme.palette.containers.bg1,
          color: theme.palette.text.text5
        },
        '& .MuiTabs-root': {
          '& .MuiTab-root ': {
            color: theme.palette.primary.main
          }
        },
        '& .btnsCloseBtn': {
          background: theme.palette.buttons.bg,
          color: theme.palette.buttons.color,
          borderColor: theme.palette.buttons.border
        }
      },
      '& .ui-color-picker': {
        backgroundColor: theme.palette.containers.card,
        '& .gradient-degree-value': {
          backgroundColor: theme.palette.containers.bg1,
          color: theme.palette.text.text5
        },
        '& .gradient-degrees': {
          border: `2px solid ${theme.palette.text.text5} !important`
        },
        '& .gradient-degree-pointer': {
          background: `${theme.palette.text.text5} !important`
        },
        '& .gradient-type-item': {
          boxShadow: theme.palette.shadows.shadow8,
          background: `${theme.palette.containers.toggleBtn2.bg} !important`,
          color: theme.palette.containers.toggleBtn2.color,
          '&::after': {
            color: theme.palette.containers.toggleBtn2.color,
          },
          '&.active': {
            background: `${theme.palette.containers.toggleBtn2.activeBg} !important`,
            color: theme.palette.containers.toggleBtn2.activeColor,
            '&::after': {
              color: theme.palette.containers.toggleBtn2.activeColor,
            },
          }
        }
      },
      '& .color-picker input': {
        backgroundColor: theme.palette.containers.card
      },
      '& .color-preview-area .label': {
        color: theme.palette.text.text1
      },
      '& .color-preview-area .input': {
        color: theme.palette.primary.main
      },
      '& .customPaper .MuiListItem-button': {
        color: theme.palette.text.text5
      },
      '& .boxContentEditable, & textarea': {
        color: theme.palette.primary.main,
        background: theme.palette.containers.bg1,
        borderColor: theme.palette.primary.border1,
      },
      '& textarea': {
        borderRadius: '7px',
        '&:hover': {
          borderColor: 'rgb(95 95 95 / 87%)'
        },
        '&:focus-visible': {
          borderColor: '#2563EB !important'
        }
      },
      '& ::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb': {
        background: theme.palette.type === "dark" ? '#3f3f3f !important' : '#bdc0d240'
      },
      '& *::-webkit-scrollbar-track': {
        visibility: theme.palette.type === "dark" ? 'hidden' : 'visible'
      },
      '&::-webkit-scrollbar-track': {
        visibility: theme.palette.type === "dark" ? 'hidden' : 'visible'
      },
      '& ::-webkit-scrollbar-track': {
        visibility: theme.palette.type === "dark" ? 'hidden' : 'visible'
      },
      '& .DraftEditor-editorContainer': {
        color: theme.palette.primary.main
      },
      '& .toggleBtnOff': {
        '& .rectFillPath': {
          fill: theme.palette.type === "dark" ? '#36393C' : '#e4eaf7'
        }
      },
      '& .boxContentEditable': {
        border: `1px solid ${theme.palette.primary.border1}`
      }
    },
    mobilePrivateLayout: {
      position: 'relative',
      '& .mobileHeader': {
        position: 'sticky',
        top: 0,
        zIndex: 999
      },
      '& .mobileFooter': {
        position: 'sticky',
        bottom: 0,
        zIndex: 999
      },
      '& .mobileBody': {
        position: 'relative',
        height: 'calc(100% - 101px)',
        overflowY: 'auto'
      }
    }
  })
};

export default PrivateLayoutStyles;
