import { invoiceConstant } from "../constants";

const tableData = () => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headers: [],
      },
    },
    search: "",
    filter:{}
  };
};

const initialState = {
  //works inversely ie: disable = true if isEditable is true
  isEditable: false,

  invoiceConnect: {
    connect_status: true,
    account_id: null,
    url: null,
  },
  invoiceUsers: [],
  invoiceData: {
    invoice_id: null,
    subscription_id: null,
    invoice_type: "invoice",
    currency: "USD",
    customer_name: "",
    customer_email: "",
    customer_detail: "",
    customer_id: "",
    schedule: "",
    start_date: null,
    end_date: null,
    due_date: null,
    autoPay: false,
    is_payable: true,
    invoice_items: [
      {
        description: "",
        quantity: 1,
        price: 0,
        total: 0,
      },
    ],
    invoice_total: "",
    discount: 0.0,
    tax_name: "Sales Tax",
    tax_value: 0.0,
    invoice_grand_total: "",
    invoice_notes: "",
    invoice_notes_default: false,
    invoice_title: "",
    invoice_detail: "",
    invoice_detail_default: false,
    invoice_logo: "",
    invoice_number: "",
    new_invoice_logo: "",
    form_status: "",
    company_name: "",
    customer_phone: "",
    public_invoice_title: "",
    public_invoice_description: "",
    public_invoice_id: null,
    subscription_id:null,
  },
  invoiceSettings: {
    invoice_title_name: "",
    invoice_sequence: "",
    new_invoice_number: "",
    invoice_currency: "",
    invoice_logo: "",
    invoice_notes: "",
  },
  invoiceSummary: { ...tableData() },
  archiveInvoices: { ...tableData() },
  invoiceClients: { ...tableData() },
  subscriptions: { ...tableData() },
  agencyData: null,
  settings: {},
  publicInvoiceSummary: { ...tableData() },

  ///chart
  chartData: {
    data: [],
    totaReceived: 0,
    thisMonth: 0,
    latMonth: 0,
    thisYear: 0,
    invoiceCurrency: null,
  },
  errorData: [],
  subscriptionLogs: [],
  invoiceReports: {
      data: [],
      
  },
  invoiceAllReports: {
      data: [],
      
  },

  //timeline data
  invoiceTimeLine: [],
  paymentReports:[],
  invoiceNotification : [],
  offlineInvoicePreview:{},
  currencyReports:[],
  stripeActivation:false
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    //Invoice connect status
    case invoiceConstant.GET_INVOICE_CONNECT_STATUS_R:
      return {
        ...state,
        invoiceConnect: initialState.invoiceConnect,
      };
    case invoiceConstant.GET_INVOICE_CONNECT_STATUS_S:
      return {
        ...state,
        invoiceConnect: { ...action.data },
        isEditable: !action.data.connect_status,
      };

    // Invoice Info (Default Invoice Data to create invoice)
    case invoiceConstant.GET_INVOICE_INFO_R:
    case invoiceConstant.CLEAR_INVOICE_DATA:
      return {
        ...state,
        invoiceUsers: initialState.invoiceUsers,
        invoiceData: initialState.invoiceData,
      };

    case invoiceConstant.GET_INVOICE_INFO_S:
      let invoiceInfoData = action.data.invoiceData
        ? action.data.invoiceData
        : state.invoiceData;
      return {
        ...state,
        invoiceUsers: action.data.clients,
        invoiceData: {
          ...state.invoiceData,
          ...invoiceInfoData,
        },
        agencyData: action.data.agencyData,
      };

    case invoiceConstant.GET_INVOICE_INFO_F:
      return {
        ...state,
        invoiceUsers: initialState.invoiceUsers,
        invoiceData: initialState.invoiceData,
      };

    // Get Invoice or Subscription Data on Edit Page
    case invoiceConstant.GET_INVOICE_R:
      return {
        ...state,
        invoiceData: initialState.invoiceData,
        isEditable: initialState.isEditable,
      };

    case invoiceConstant.GET_INVOICE_S:
      return {
        ...state,
        invoiceData: {
          ...action.data,
        },
        agencyData: action.data.agencyData,
        isEditable: action.data.isEditableDisabled,
      };

    case invoiceConstant.GET_INVOICE_F:
      return {
        ...state,
        invoiceData: initialState.invoiceData,
      };

    // Subscriptions
    case invoiceConstant.GET_SUBSCRIPTION_R:
      return {
        ...state,
        subscriptions: initialState.subscriptions,
      };
    case invoiceConstant.GET_SUBSCRIPTION_S:
      return {
        ...state,
        subscriptions:
          state.subscriptions && action.data.params?.preserveData
            ? {
                ...state.subscriptions,
                data: [...state.subscriptions.data, ...action.data.data],
              }
            : action.data,
      };
    case invoiceConstant.GET_SUBSCRIPTION_F:
      return {
        ...state,
        subscriptions: initialState.subscriptions,
      };

    // Invoice Clients
    case invoiceConstant.GET_INVOICE_CLIENTS_R:
      return {
        ...state,
        invoiceClients: initialState.invoiceClients,
      };
    case invoiceConstant.GET_INVOICE_CLIENTS_S:
      return {
        ...state,
        invoiceClients:
          state.invoiceClients && action.data.params?.preserveData
            ? {
                ...state.invoiceClients,
                data: [...state.invoiceClients.data, ...action.data.data],
              }
            : action.data,
      };

    case invoiceConstant.GET_INVOICE_CLIENTS_F:
      return {
        ...state,
        invoiceClients: initialState.invoiceClients,
      };

    // Invoice Settings
    case invoiceConstant.GET_INVOICE_SETTING_R:
      return {
        ...state,
        invoiceSettings: initialState.invoiceSettings,
      };
    case invoiceConstant.GET_INVOICE_SETTING_S:
      return {
        ...state,
        invoiceSettings: { ...action.data.data },
      };
    case invoiceConstant.GET_INVOICE_SETTING_F:
      return {
        ...state,
        invoiceSettings: initialState.invoiceSettings,
      };

    // Invoice Settings
    case invoiceConstant.GET_IN_SETTING_R:
      return {
        ...state,
        settings: initialState.settings,
      };
    case invoiceConstant.GET_IN_SETTING_S:
      return {
        ...state,
        settings: action.data.data,
      };
    case invoiceConstant.GET_IN_SETTING_F:
      return {
        ...state,
        settings: initialState.settings,
      };

    // Save Invoice
    case invoiceConstant.SAVE_INVOICE_R:
      return {
        ...state,
      };
    case invoiceConstant.SAVE_INVOICE_S:
      return {
        ...state,
      };
    case invoiceConstant.SAVE_INVOICE_F:
      return {
        ...state,
      };

    // Update Settings
    case invoiceConstant.UPDATE_IN_SETTING_R:
      return {
        ...state,
      };
    case invoiceConstant.UPDATE_IN_SETTING_S:
      return {
        ...state,
      };
    case invoiceConstant.UPDATE_IN_SETTING_F:
      return {
        ...state,
      };

    case invoiceConstant.UPDATE_IN_SETTING_REDUCER:
      return {
        ...state,
        settings: action.data,
      };

    case invoiceConstant.UPDATE_INVOICE_DATA_S:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          ...action.data,
        },
      };

    // Invoice Summary (List Of Agency Invoices)
    case invoiceConstant.GET_INVOICE_SUMMARY_R:
      return {
        ...state,
        invoiceSummary: initialState.invoiceSummary,
      };

    case invoiceConstant.GET_INVOICE_SUMMARY_S:
      // state.invoiceSummary && action.data.params?.preserveData
      // ? {
      //     ...state.invoiceSummary,
      //     data: [...state.invoiceSummary.data, ...action.data.data],
      //   }
      // : action.data,
      return {
        ...state,
        invoiceSummary:
          state.invoiceSummary && action.data.params?.preserveData
            ? {
                ...state.invoiceSummary,
                data: [...action?.data?.data],
              }
            : action.data,
      };

    case invoiceConstant.GET_INVOICE_SUMMARY_F:
      return {
        ...state,
        invoiceSummary: initialState.invoiceSummary,
      };

    // Invoice Summary (List Of Agency Invoices)
    case invoiceConstant.GET_ARCHIVE_INVOICE_SUMMARY_R:
      return {
        ...state,
        archiveInvoices: initialState.archiveInvoices,
      };

    case invoiceConstant.GET_ARCHIVE_INVOICE_SUMMARY_S:
      return {
        ...state,
        archiveInvoices: action.data,
      };

    case invoiceConstant.GET_ARCHIVE_INVOICE_SUMMARY_F:
      return {
        ...state,
        archiveInvoices: initialState.archiveInvoices,
      };

    // Invoice Summary (List Of Agency Invoices)
    case invoiceConstant.GET_PAYMENT_CHART_R:
      return {
        ...state,
        chartData: initialState.chartData,
      };

    case invoiceConstant.GET_PAYMENT_CHART_S:
      return {
        ...state,
        chartData: action.data.data,
      };

    case invoiceConstant.GET_PAYMENT_CHART_F:
      return {
        ...state,
        chartData: initialState.chartData,
      };

    case invoiceConstant.DISABLE_EDIT:
      return {
        ...state,
        invoiceData: initialState.invoiceData,
        isEditable: action.data,
      };

    // Invoice Summary (List Of Agency Invoices)
    case invoiceConstant.GET_INVOICE_ERRORS_R:
      return {
        ...state,
        errorData: initialState.errorData,
      };

    case invoiceConstant.GET_INVOICE_ERRORS_S:
      return {
        ...state,
        errorData: action.data,
      };

    case invoiceConstant.GET_INVOICE_ERRORS_F:
      return {
        ...state,
        errorData: initialState.errorData,
      };

    case invoiceConstant.SUBSCRIPTION_LOGS_R:
      return {
        ...state,
        subscriptionLogs: initialState.subscriptionLogs,
      };

    case invoiceConstant.SUBSCRIPTION_LOGS_S:
      return {
        ...state,
        subscriptionLogs: action.data,
      };

    case invoiceConstant.SUBSCRIPTION_LOGS_F:
      return {
        ...state,
        subscriptionLogs: initialState.subscriptionLogs,
      };

    case invoiceConstant.CREATE_INVOICE_FROM_PUBLIC_INVOICE_R:
      return {
        ...state,
      };

    case invoiceConstant.CREATE_INVOICE_FROM_PUBLIC_INVOICE_S:
      return {
        ...state,
      };

    case invoiceConstant.CREATE_INVOICE_FROM_PUBLIC_INVOICE_F:
      return {
        ...state,
      };

    case invoiceConstant.GET_INVOICE_REPORTS_R:
        return {
            ...state,
            invoiceReports: initialState.invoiceReports,
        };
    case invoiceConstant.GET_INVOICE_REPORTS_S:
        return {
            ...state,
            invoiceReports: action.data,
        };
    case invoiceConstant.GET_INVOICE_REPORTS_F:
        return {
            ...state,
            invoiceReports: initialState.invoiceReports,
        };
    case invoiceConstant.GET_INVOICE_ALL_REPORTS_R:
        return {
            ...state,
            invoiceAllReports: initialState.invoiceAllReports,
        };
    case invoiceConstant.GET_INVOICE_ALL_REPORTS_S:
        return {
            ...state,
            invoiceAllReports: action.data,
        };
    case invoiceConstant.GET_INVOICE_ALL_REPORTS_F:
        return {
            ...state,
            invoiceAllReports: initialState.invoiceAllReports,
        };

    case invoiceConstant.GET_PUBLIC_INVOICE_SUMMARY_R:
      return {
        ...state,
        publicInvoiceSummary: initialState.publicInvoiceSummary,
      };

    case invoiceConstant.GET_PUBLIC_INVOICE_SUMMARY_S:
      return {
        ...state,
        publicInvoiceSummary: state.publicInvoiceSummary && action.data.params?.preserveData
        ? {
            ...state.publicInvoiceSummary,
            data: [...state.publicInvoiceSummary.data, ...action.data.data],
          }
        : action.data,
      };

    case invoiceConstant.GET_PUBLIC_INVOICE_SUMMARY_F:
      return {
        ...state,
        publicInvoiceSummary: initialState.publicInvoiceSummary,
      };

    case invoiceConstant.GET_PUBLIC_INVOICE_R:
      return {
        ...state,
        invoiceData: initialState.invoiceData,
      };

    case invoiceConstant.GET_PUBLIC_INVOICE_S:
      return {
        ...state,
        invoiceData: {
          ...action.data,
        },
        agencyData: action.data.agencyData,
      };

    case invoiceConstant.GET_PUBLIC_INVOICE_F:
      return {
        ...state,
        invoiceData: initialState.invoiceData,
      };

    // update invoice summary
    case invoiceConstant.UPDATE_INVOICE_SUMMARY:
      return {
        ...state,
        invoiceSummary: action.data,
      };

    // Update public invoice summary
    case invoiceConstant.UPDATE_PUBLIC_INVOICE_SUMMARY:
      return {
        ...state,
        publicInvoiceSummary: action.data,
      };

    // get invoice timeline
    case invoiceConstant.GET_INVOICE_TIMELINE_R:
      return {
        ...state,
      };

    case invoiceConstant.GET_INVOICE_TIMELINE_S:
      return {
        ...state,
        invoiceTimeLine: action.data,
      };

    case invoiceConstant.GET_INVOICE_TIMELINE_F:
      return {
        ...state,
      };

    case invoiceConstant.UPDATE_PAYMENT_STATUS_S:
      return {
        ...state,
        invoiceSummary: {
          ...state.invoiceSummary,
          data: action.data,
        },
      };
      case invoiceConstant.GET_PAYMENT_REPORTS_R:
        return {
            ...state,
            paymentReports: initialState.paymentReports,
        };
    case invoiceConstant.GET_PAYMENT_REPORTS_S:
        return {
            ...state,
            paymentReports: action.data,
        };
    case invoiceConstant.GET_PAYMENT_REPORTS_F:
        return {
            ...state,
            paymentReports: initialState.paymentReports,
        };
      
    case invoiceConstant.GET_NOTIFICATION_R:
      return {
        ...state
      };

    case invoiceConstant.GET_NOTIFICATION_S:
      return {
        ...state,
        invoiceNotification : action.data.data
      };

    case invoiceConstant.GET_NOTIFICATION_F:
      return {
        ...state
      };
    
    case invoiceConstant.REMOVE_NOTIFICATIONS_S:
      return {
        ...state,
       invoiceNotification : state.invoiceNotification.filter(f => f.id !== action.data.id)
      };
    
    // update invoice subscription
    case invoiceConstant.UPDATE_INVOICE_SUBSCRIPTION:
    return {
      ...state,
      subscriptions: action.data,
    };
    case invoiceConstant.SET_INVOICE_ID:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,  
          invoice_id: action.data 
        }
      }
    case invoiceConstant.GET_CURRENCY_REPORTS_R:
      return {
          ...state,
          currencyReports: initialState.currencyReports,
      };
  case invoiceConstant.GET_CURRENCY_REPORTS_S:
      return {
          ...state,
          
          currencyReports: action.data,
         
      };
       
  case invoiceConstant.GET_CURRENCY_REPORTS_F:
      return {
          ...state,
          currencyReports: initialState.currencyReports,
      };
  case invoiceConstant.INVOICE_STRIPE_ACTIVATION_S:
      return {
        ...state,
        stripeActivation:true
      }
    default:
      return state;
  }

};

export default invoiceReducer;
