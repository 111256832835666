import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { withStyles } from '@material-ui/core'
import { drawCanvas } from './tools'
import { tools } from './tools/tools'
import { hasUndo, hasRedo, getSelectedNode, hideAllTransforms } from './tools/utils'
import { EditorMenus } from './EditorMenus'
import { ToolOptions } from './EditorMenus/ToolOptions'
import styles from './styles'
import { isEditorFormat } from '../../utils'
import { isMobile } from "react-device-detect";

const CanvasImageEditor = forwardRef((props, ref) => {
    const { classes, id, src, file, onSave, fileExtension, fileType, fileFormat,type ,attachmentType} = props
    const canvasWrapperRef = useRef(null)
    const [stage, setStage] = useState(null)
    const [nodes, setNodes] = useState([])
    const [extensionType,setExtensionType]=useState(fileExtension)
    const [stageData, setStageData] = useState({
        undo: true,
        redo: true,
        selectedNode: null,
        drawMode: false,
        containerData: null
    })


    let canEdit;
    if(attachmentType=='doc'){
        canEdit=false;
    }
    else{
        canEdit = isEditorFormat(file.type)
    }
    useImperativeHandle(ref, () => ({
        async triggerSave(isUpload) {
            return await handleSave(isUpload)
        }
    }));

    useEffect(() => {
        if (canEdit) {
            const wrapperEle = document.getElementsByClassName('ci_editor_gbcr')[0]
            const { width, height } = wrapperEle.getBoundingClientRect()
            const padWH = isMobile ? { w: 0, h: 100 } : { w: 300, h: 120 }
            const st = new window.Konva.Stage({
                container: id,   // id of container <div>
                width: width - padWH.w,
                height: height - padWH.h,
                lastUndo: null,
                onUpdate: onUpdate,
                drawMode: false
            })
            st.imageSmoothingEnabled = true
            setStage(st)
            setStageData({
                ...stageData,
                drawMode: false
            })
        }
        if(attachmentType=='doc' && file?.type?.startsWith("image") || fileExtension=='csv' || fileExtension=='xlsx' || fileExtension=='svg' || fileExtension=='html'){
            setExtensionType('jpg')
        }
        else if( fileExtension=='m4a' || fileExtension=='mp3'){
            setExtensionType('record')
        }
        else if(fileExtension=='mov'){
            setExtensionType('mp4')
        }
    }, [id])

    useEffect(() => {
        if (stage && canvasWrapperRef) {
            setNodes([
                {
                    type: 'image',
                    method: 'drawImage',
                    props: {
                        src,
                        stage,
                        canvasWrapperRef: canvasWrapperRef.current,
                        draggable: false
                    },
                    painted: false
                }
            ])
        }
    }, [stage])

    useEffect(() => {
        if (stage && canvasWrapperRef) {
            drawCanvas(nodes)
        }
    }, [nodes])

    const addText = () => {
        setNodes([
            ...nodes,
            {
                type: 'text',
                method: 'textNode',
                props: {
                    text: 'text...',
                    stage,
                    canvasWrapperRef: canvasWrapperRef.current
                },
                painted: false
            }
        ])
    }

    const addPen = () => {
        if (!nodes.find(f => f.type == 'pen')) {
            setNodes([
                ...nodes,
                {
                    type: 'pen',
                    method: 'drawPen',
                    props: {
                        stage
                    },
                    painted: false
                }
            ])
        }
        if (stage?.attrs?.drawMode !== undefined) {
            stage.attrs.drawMode = !stage.attrs.drawMode
            setStageData({
                ...stageData,
                drawMode: stage.attrs.drawMode
            })
            if (stage.attrs.drawMode) {
                hideAllTransforms({ stage })
            }
        }
    }

    const addBlur = () => {
        setNodes([
            ...nodes,
            {
                type: 'blur',
                method: 'drawBlur',
                props: {
                    stage
                },
                painted: false
            }
        ])
    }

    const undo = () => {
        tools.undo({ stage })
    }

    const redo = () => {
        tools.redo({ stage })
    }

    const addCrop = () => {
        setNodes([
            ...nodes,
            {
                type: 'crop',
                method: 'crop',
                props: {
                    stage,
                    canvasWrapperRef: canvasWrapperRef.current
                },
                painted: false
            }
        ])
    }

    const onUpdate = (stageLatest, extOpt = {}) => {
        setStageData({
            ...stageData,
            undo: hasUndo({ stage: stageLatest }),
            redo: hasRedo({ stage: stageLatest }),
            selectedNode: getSelectedNode({ stage: stageLatest }),
            drawMode: stage?.attrs?.drawMode,
            containerData: stageLatest?.attrs?.containerData,
            ...extOpt
        })
    }

    const onMenuClick = (menuName) => () => {
        switch (menuName) {
            case 'textNode': return addText()
            case 'drawPen': return addPen()
            case 'drawBlur': return addBlur()
            case 'crop': return addCrop()
            case 'undo': return undo()
            case 'redo': return redo()
            case 'save': return handleSave()
            default: return null
        }
    }

    const handleSave = async (isUpload) => {
        if (canEdit) {
            hideAllTransforms({ stage })
            const blob = await stage.toBlob({
                pixelRatio: stageData?.containerData?.upScale < 1 ? 1 : stageData?.containerData?.upScale
            })
            const editedFile = new File([blob], file.name, {
                type: "image/png"
            });
            const previewURL = URL.createObjectURL(blob);
            editedFile.thumb = previewURL;
            editedFile.preview = previewURL;
            editedFile.iof = file.iof;
            editedFile.message_text = file?.message_text || '';
            editedFile.message_raw_text = file?.message_raw_text || '';
            editedFile.isUploadFile = true;
            return onSave(editedFile, isUpload)
        } else {
            return onSave(file, isUpload)
        }
    }

    const onChange = (type, data) => {
        switch (type) {
            case 'drawPen':
                stage.attrs.drawProps = {
                    stroke: data.fill,
                    strokeWidth: data.strokeWidth
                }
                return
            default: return
        }
    }

    const renderImageTypes = () => {
        return canEdit ? (
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'start'
                    //  minHeight: '300px' 
                }}>
                    <div
                        className={`ci_stage_wrapper ${classes.canvasWrapper}`}
                        id={id}
                        ref={canvasWrapperRef}
                        style={{
                            border: '1px solid #CCC',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%'
                        }}
                    />
                    <EditorMenus
                        type={type}
                        classes={classes}
                        stageData={stageData}
                        onMenuClick={onMenuClick}
                    />
                </div>
                <ToolOptions
                    classes={classes}
                    stageData={stageData}
                    onChange={onChange}
                />
            </>
        ) : (
            <img alt={file?.name} src={src} />
        )

    }

    const renderOther = () => {
        return fileType === "video" && attachmentType !== 'doc' ? (
            <>
                <video
                    width="100%"
                    height="auto"
                    disablePictureInPicture
                    controls
                    controlsList="nofullscreen nodownload"
                    style={{ maxHeight: '300px' }}
                >
                    <source src={src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </>
        ) : (extensionType.length > 0 ?
            <>
                <img
                    style={{ width: "25px", height: "30px" }}
                    src={`./icons/${extensionType}.svg`}
                    alt={extensionType}
                />
                <div className={`pl-1 pt-1 ${classes.docTitle}`} style={{ textAlign: 'center' }}>
                    {src && file?.name ? file.name : "PDF"}
                </div>

            </> :
            <span>
                {src && file.name ?
                    file.name : fileFormat}
            </span>
        )
    }

    return (
        <div
            key={`${id}_ci_wrpr`}
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            {
                fileType === 'image' && attachmentType !=='doc' ? renderImageTypes() : renderOther()
            }
        </div>

    )
})

export default withStyles(styles)(CanvasImageEditor)