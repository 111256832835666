import { chatGroupSort, isNumber } from "../../utils"

const isUndo = (params, message, current_msg) => {
    if (params.deletedBy) {
        return {
            undo: params.deleteStatus ?
                (Array.isArray(message.id) ? message.id.includes(current_msg.id)  : message.id == current_msg.id)
                && current_msg.created_by == params.deletedBy : false
        }
    } else {
        return {}
    }
}
export const getOldChat = (state, params) => {
    let oldChats = []
    if (params.skip != 0) {
        oldChats = state.chatv2?.[params.cur_type][params.room_id]?.chats || []
    }
    return oldChats
}

export const addNewMessage = (state, newMsg, params) => {
    const typeData = state.chatv2?.[params.cur_type] || {};
    const chatData = typeData[params.room_id] || { chats: [], page: {} };
    let { chats: oldChats } = chatData;

    let newMessages = [];
    if (newMsg?.uploadPreviews?.files_data) {
        const filesData = JSON.parse(newMsg.uploadPreviews.files_data) || [];
        const filesWithText = filesData.filter(file => file.message_text && file?.type?.startsWith("image"));
        const filesWithoutText = filesData.filter(file => !file.message_text && file?.type?.startsWith("image"));
        const otherFiles = filesData.filter(file => file?.type?.startsWith("video"));

        if (newMsg.message_category === 2) {
            if (filesData.length > 1) {
                filesData.forEach(file => {
                    newMessages.push({
                        ...newMsg,
                        uploadPreviews: {
                            ...newMsg.uploadPreviews,
                            file: file,
                            file_type: file.type, 
                            files_data: JSON.stringify([file]), 
                        },
                        message_text: file.message_text || '',
                        message_category: 2, 
                    });
                });
            } else {
                newMessages.push({
                    ...newMsg,
                    message_category: 2,
                });
            }
        } else if(newMsg.message_category==4){
            newMessages.push({
                ...newMsg,
                message_category: 4,
            });
        }
        else {
            filesWithText.forEach(fileWithText => {
                newMessages.push({
                    ...newMsg,
                    uploadPreviews: {
                        ...newMsg.uploadPreviews,
                        file: fileWithText, 
                        file_type: fileWithText.type,
                        files_data: JSON.stringify([fileWithText]),
                    },
                    message_text: fileWithText.message_text,
                    message_category: 1,
                });
            });
            // For image files without text
            if (filesWithoutText.length > 0) {
                newMessages.push({
                    ...newMsg,
                    uploadPreviews: {
                        ...newMsg.uploadPreviews,
                        file: filesWithoutText[0], 
                        file_type: filesWithoutText[0]?.type, 
                        files_data: JSON.stringify(filesWithoutText),
                    },
                    message_text: '',
                    message_category: filesWithoutText.length > 1 ? 3 : 1,
                });
            }
            // For video files
            if (otherFiles.length > 0) {
                newMessages.push({
                    ...newMsg,
                    uploadPreviews: {
                        ...newMsg.uploadPreviews,
                        file: otherFiles[0],
                        file_type: otherFiles[0]?.type, 
                        files_data: JSON.stringify(otherFiles),
                    },
                    message_text: otherFiles?.[0]?.message_text || '',
                    message_category: otherFiles.length > 1 ? 3 : 1,
                });
            }
        }
    } else {
        newMessages.push(newMsg);
    }
    return {
        ...chatData,
        chats: [
            ...newMessages,
            ...oldChats
        ],
    };
};

export const updateNewMessageId = (state, updatedMsg, params) => {
    const typeData = state.chatv2?.[params.cur_type] || {}
    const chatData = typeData[params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    const isMessageExists = oldChats?.find(f => f.msgKey == updatedMsg.msgKey)
    if (isMessageExists) {
        return {
            ...chatData,
            chats: oldChats.map(m => {
                if (m.msgKey == updatedMsg.msgKey) {
                    m = {
                        ...updatedMsg
                    }
                }
                return m
            })
        }
    } else {
        return addNewMessage(state, updatedMsg, params)
    }
}

export const updateMultiSelectMsgs = (msgObj) => {
    const result = []
    msgObj?.id.map((m)=> result.push({id: m}))
    return result
}

export const updateNewMessages = (state, updatedMsg, params) => {
    const { loginUser: { id: currentUser } } = state.auth
    const typeData = state.chatv2?.[params.cur_type] || {}
    const chatData = typeData[params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    let newMsgs = Array.isArray(updatedMsg) ? updatedMsg : (Array.isArray(updatedMsg?.id || {}) ? updateMultiSelectMsgs(updatedMsg) :[updatedMsg])
    // For image and video ficker fix
    if (newMsgs) {
           newMsgs.forEach((m) => {
                const mKey = m?.message_file && JSON.parse(m?.message_file)?.unique_key;
            
                oldChats.forEach((f) => {
                    if (mKey && m?.message_category !== 0 && m?.message_category !== 4 && f?.uploadPreviews?.file?.unique_id === mKey) {
                        m["uploadPreviews"] = f.uploadPreviews; 
                    }
                });
            });
    }
    else if (params?.is_emoji?.message_id) {
        const isSender = oldChats.findIndex(f => f.id === params?.is_emoji?.message_id)
        if (isSender > -1) {
            const tmpMsg = oldChats[isSender]
            if (tmpMsg?.uploadPreviews) {
                newMsgs[isSender]["uploadPreviews"] = tmpMsg.uploadPreviews
            }
        }
    }
    let defaultKey = 'msgKey'
    if (params.defaultKey) {
        defaultKey = params.defaultKey
    }

    // Logic to prevent dupicate message
    let is_duplicate = false
    newMsgs && newMsgs.map((m) =>{
        const duplicate_index = oldChats.findIndex(f => f?.id == m?.id)
        if(duplicate_index > -1) {
            is_duplicate = true
        }
    })
    let updatedMsgs = newMsgs?.filter(f => f?.id !== undefined).reduce((a, b) => {
        const isExists = b[defaultKey] ? a.findIndex(f => f[defaultKey] == b[defaultKey]) : -1
        if (params.isNewMsg && currentUser != b.message_from) {
            b.read_at = null
        }

        if (isExists > -1) {
            a[isExists] = !params.updateProps ? {
                ...b
            } : {
                ...a[isExists],
                ...params.updateProps,
                ...isUndo(params, updatedMsg, a[isExists])
            }
        } else if(!is_duplicate) {
            a.splice(0, 0, b)
        }
        return a
    }, (oldChats || []))
    if (params.updateProps?.read_status === 1) {
        updatedMsgs = updatedMsgs.map(msg => 
            msg.read_status === 0 ? { ...msg, read_status: 1 } : msg
        );
    }
    if (params.removeMsgKey) {
        updatedMsgs = updatedMsgs.filter(f => f.id != f?.msgKey);
        if(newMsgs && updatedMsgs.some(msg => msg?.id == newMsgs[0]?.id)) {
            updatedMsgs = updatedMsgs.map(msg => 
                msg?.id === newMsgs[0]?.id ? newMsgs[0] : msg
            );
        }    }

    return {
        ...chatData,
        chats: updatedMsgs
    }
}


export const onUploadChatFiles = (state, updatedMsgs, params) => {
    const typeData = state.chatv2?.[params.cur_type] || {}
    const chatData = typeData[params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    return {
        ...chatData,
        chats: [
            ...(updatedMsgs || []),
            ...(oldChats || [])
        ]
    }
}

export const deleteMessage = (state, { message_id, isUndo }, params) => {
    const typeData = state.chatv2?.[params.cur_type] || {}
    const chatData = typeData[params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    return {
        ...chatData,
        chats: (oldChats || []).map(m => {
            if (params.userId == m.created && m.id == message_id) {
                m.undo = isUndo
            }
            return m
        })
    }
}

export const updateChannelRecentMessage = (state, latestMessage, params, reacted_message = null) => {
    let channelDataArr = state.chatv2?.channel[params.cur_type] || []
    const infinitiRoom = (channelDataArr.length > 0) && channelDataArr[0]['isInfiniti'] ? [channelDataArr[0]] : []
    let isNewRoom = true
    const channelLatestMsg = {
        message_category: latestMessage?.message_category,
        message_from: latestMessage?.message_from,
        message_id: latestMessage?.id,
        message_text: latestMessage?.message_text || " ",
        message_time: latestMessage?.created_on || new Date(),
        undo: latestMessage?.undo
    }

    channelDataArr = channelDataArr.map(f => {
        if (f.id == params.room_id) {
            // Added this logic to update the recent message to emoji reacted messsage
            if (reacted_message && params?.is_emoji && params?.is_emoji?.userId) {
                if (reacted_message?.message_category === 1 && reacted_message?.message_file) {
                    const file = reacted_message.message_file ? JSON.parse(reacted_message.message_file) : {};
                    if (file.type.includes("video")) {
                        reacted_message["reacted_text"] = "Video"
                    } else if (file.type.includes("gif")) {
                        reacted_message["reacted_text"] = "GIF"
                    } else if (file.type.includes("image")) {
                        reacted_message["reacted_text"] = "Image"
                    } else {
                        reacted_message["reacted_text"] = "Attachment"
                    }
                }
                else if(reacted_message?.message_category === 3 && reacted_message?.message_file){
                    reacted_message["reacted_text"] = "Image"
                }
                let updated_msgtext = ""
                f?.users?.map((u) => {
                    if (params?.is_emoji?.userId == u.id) {
                        updated_msgtext = u?.name ? u?.name : u?.username
                        updated_msgtext = `reacted ${params?.is_emoji?.emoji || ""} to: ${reacted_message?.reacted_text || reacted_message?.message_text || ""}`
                    }
                })
                channelLatestMsg["message_text"] = updated_msgtext
                channelLatestMsg["message_from"] = params?.is_emoji?.userId || channelLatestMsg.message_from
            }
            isNewRoom = false
            return {
                ...f,
                ...channelLatestMsg
            }
        } else {
            return f
        }
    }).sort(chatGroupSort)

    if (isNewRoom && params.room_id) {
        channelDataArr.splice(0, 0, {
            ...channelLatestMsg,
            mute_notification: 0,
            id: parseInt(params.room_id),
            isNewRoom: isNewRoom
        })
    } else {
        isNewRoom = false
    }

    channelDataArr = [...infinitiRoom, ...channelDataArr.filter(room => !room['isInfiniti'])]
    return {
        latestChanelData: {
            ...(state.chatv2?.channel || {}),
            [params.cur_type]: [
                ...channelDataArr
            ]
        },
        isNewRoom
    }
}

export const updateChannelEditedMessage = (state, latestMessage, params, reacted_message = null) => {
    let channelDataArr = state.chatv2?.channel[params.cur_type] || []
    const infinitiRoom = (channelDataArr.length > 0) && channelDataArr[0]['isInfiniti'] ? [channelDataArr[0]] : []

    channelDataArr = channelDataArr.map(f => {
        if (f.id == params.room_id && f?.message_id === latestMessage?.message_id) {
            f["message_text"] = latestMessage.chatText
        }
        return f
    }).sort(chatGroupSort)

    channelDataArr = [...infinitiRoom, ...channelDataArr.filter(room => !room['isInfiniti'])]
    return {
        latestChanelData: {
            ...(state.chatv2?.channel || {}),
            [params.cur_type]: [
                ...channelDataArr
            ]
        }
    }
}

export const addOrRemoveUser = (state, user, params) => {
    const channelDataArr = state.chatv2?.channel[params.cur_type] || []
    const channelData = channelDataArr.find(f => f.id == params.room_id)
    if (channelData) {
        let oldUsers = [...(channelData.users || [])]
        const isExists = user ? oldUsers.findIndex(f => f.id == user.id) : null
        if (params?.newUsers && params?.newUsers?.length > 0) {
            const duplicate_indx = oldUsers.findIndex( u => params?.newUsers[0]?.id === u?.id)
            if(duplicate_indx > -1) {
                oldUsers = [...params.newUsers, ...oldUsers]
            }
        } else if (isExists == -1 && user?.id) {
            oldUsers.push(user)
        } else if (isExists > -1) {
            oldUsers.splice(isExists, 1)
        }
        return {
            result: {
                ...channelData,
                chat_room: {
                    ...(channelData?.chat_room || {}),
                    chatRoomUsers: oldUsers.map(m => m.id || m.value)
                },
                users: oldUsers
            }
        }
    } else {
        return {
            result: null
        }
    }
}

export const deleteChatv2Group = (state, params) => {
    const channelDataArr = state.chatv2?.channel[params.cur_type] || []
    return {
        result: (channelDataArr || []).map(m => {
            if (m.id == params.room_id) {
                m.deleted = true
            }
            return m
        })
    }
}

export const changeMessageReadAt = (state, params) => {
    const typeData = state.chatv2?.[params.cur_type] || {}
    const chatData = typeData[params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    return {
        ...chatData,
        chats: [...(oldChats || [])].map(m => {
            if (m.id == params.message_id) {
                // need to set from api in future
                m.read_at = new Date()
            }
            return m
        })
    }
}

export const changeMultipleMessageReadAt = (state, msgData) => {
    let updatedMsgIds = (msgData.message.read_messages || [])
    updatedMsgIds = updatedMsgIds.map((m) => {return Number(m)})

    if (msgData.message.type) {
        msgData.params["type"] = `${msgData.message.type}s`
    }

    const typeData = state.chatv2?.[msgData.params["type"]] || {}
    const chatData = typeData[msgData.params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    return {
        ...chatData,
        chats: [...(oldChats || [])].map(m => {
            if (updatedMsgIds.includes(m.id)) {
                m.read_at = new Date()
                m.read_status = 1
            }
            return m
        })
    }
}

export const updateMessageEdit = (state, updatedMsg, params) => {
    const typeData = state.chatv2?.[params.cur_type] || {}
    const chatData = typeData[params.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    const isMessageExists = oldChats?.find(f => f.id == updatedMsg.message_id)
    if (isMessageExists) {
        return {
            ...chatData,
            chats: oldChats.map(m => {
                if (m.id == updatedMsg.message_id) {
                    m = {
                        ...m,
                        is_edited: 1,
                        message_text: updatedMsg.chatText,
                        message_raw_text: updatedMsg.chatRawContent ? JSON.stringify(updatedMsg.chatRawContent) : null
                    }
                }
                return m
            })
        }
    } else {
        return {
            ...chatData
        }
    }
}

export const changeMultipleMessageDeliveredAt = (state, message) => {
    const updatedMsgIds = (message.delivered_messages || [])

    const typeData = state.chatv2?.[message.chat_type] || {}
    const chatData = typeData[message.room_id] || { chats: [], page: {} }
    const { chats: oldChats } = chatData
    return {
        ...chatData,
        chats: [...(oldChats || [])].map(m => {
            if (updatedMsgIds.includes(m.id)) {
                m.delivered = new Date()
                m.delivered_status = 1
            }
            return m
        })
    }
}

// Update the notification count as soon as user views the chat
export const updateNotificationeCount = (notifications_data, params, type) => {
    if(params, notifications_data.notifications_count["newMsgData"][type]) {
        const current_chat_count = notifications_data.notifications_count["newMsgData"][type][params?.room_id]
        if(isNumber(current_chat_count) && isNumber(notifications_data.notifications_count["allNewMsgCount"])) {
            notifications_data.notifications_count["allNewMsgCount"] = notifications_data.notifications_count["allNewMsgCount"] > 0 ? notifications_data.notifications_count["allNewMsgCount"] - current_chat_count : notifications_data.notifications_count["allNewMsgCount"] - 0
        }
        delete notifications_data.notifications_count["newMsgData"][type][params?.room_id]
    }
    return notifications_data
}