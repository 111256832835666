import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Avatar, IconButton, CircularProgress, Tooltip, Popper } from '@material-ui/core'


import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

//Imort component
import { Typography } from '../../components'

//import Images
import { BriefcaseIcon2, CloseCircleIcon, HandShakeIcon, SearchIcon, ExternalClientIcon, GlobalSearchHeader } from '../../assets/svg'

//import Styles
import HeaderStyles from './HeaderStyles'

//import actions
import { profileAction, updateAction } from '../../redux/actions';
import history from '../../config/history';
import { profileConstant } from '../../redux/constants';
import { isMobile } from 'react-device-detect';

function GlobalSearch(props) {
    //define dispatch
    const dispatch = useDispatch();

    //get props
    const { classes } = props;

    //reducer data
    const { global } = useSelector(s => (s.profile));
    const { ag_users } = useSelector(s => s.auth.loginUser);
    const { globalLoading } = useSelector(s => (s.UI));

    const [value, setValue] = useState("");
    const [hideNoOption, setHideNoOption] = useState(true);

    //update search and timeout
    useEffect(() => {
        const delaySet = setTimeout(() => {
            if (value) {
                dispatch(profileAction.getGlobalData({ search: value },()=>{
                    setHideNoOption(false);
                }))
            }
        }, 100)
        return () => clearTimeout(delaySet)
    }, [value]);

    useEffect(()=>{
        if(!value || (value && globalLoading)){
            setHideNoOption(true)
        }
    },[value, globalLoading, global])

    const onInputChange = (value, eValue, reason) => {
        if (reason === "input") {
            setValue(eValue)
        }
    }

    const [openSearch, setOpenSearch] = useState(false);

    const clickGlobalSearch = () => {
        setOpenSearch(!openSearch)
    }

    const handleClick = (newValue) => {
        newValue = newValue ? newValue : {}
        const { value = null, type = null, title = null } = newValue;
        if (type === "job_user") {
            history.push("/teams")
            dispatch(updateAction.update(profileConstant.GET_GLOBAL_S, { data: [] }))
        } else if (type === "jobs") {
            history.push(`/projects/detail/${value}`)
            dispatch(updateAction.update(profileConstant.GET_GLOBAL_S, { data: [] }))
        } else if (type === "clients") {
            history.push(`/clients/detail/${value}`)
            dispatch(updateAction.update(profileConstant.GET_GLOBAL_S, { data: [] }))
        } else if (type === "groups") {
            history.push(`/chat/teams?id=${value}`)
            dispatch(updateAction.update(profileConstant.GET_GLOBAL_S, { data: [] }))
        }
    }

    return (
        <>
            {openSearch && <div className={`${classes.globalSearch} globalSearch`}>
                <Autocomplete
                PopperComponent={PopperMy}
                    // multiple
                    id="checkboxes-tags-demo"
                    options={global}
                    className={`${isMobile ? 'borderNone' : ''} ${classes.globalSearchInput}`}
                    popupIcon={<SearchIcon />}
                    disableCloseOnSelect
                    freeSolo={hideNoOption ? true : false}
                    noOptionsText={"No match found"}
                    value={""}
                    inputValue={value}
                    getOptionLabel={(option) => option.title}
                    onInputChange={(value, eValue, reason) => { setHideNoOption(true); onInputChange(value, eValue, reason) }}
                    renderOption={(option, { selected }) => {

                        let userData = ag_users && ag_users.length > 0 ? ag_users.filter((img) => option.value === img.id) : []
                        return (
                            <React.Fragment>
                                <Grid container alignItems="center">
                                    <Grid>
                                        {(option.type === "job_user" || option.type === "groups") &&
                                            <Avatar className={classes.searchTitleAvatar} alt={userData.length && userData[0]["first_name"] ? userData[0]["first_name"].toUpperCase() : ""} src={userData.length && userData[0].avatar ? userData[0].avatar : "/static/images/avatar/1.jpg"} />}
                                        {(option.type === "jobs") && <Avatar className={classes.searchTitleAvatarIcon}><BriefcaseIcon2 /></Avatar>}
                                        {(option.type === "clients") && <Avatar className={classes.searchTitleAvatarIcon}><HandShakeIcon /></Avatar>}
                                        {(option.type === "null") && null}
                                        {/* {(option.type === "jobs") && <span className="icon icon-Briefcase"></span>}
                                    {(option.type === "clients") && <HandShakeIcon />}
                                    {(option.type === "null") && null} */}
                                    </Grid>
                                    <Grid>
                                        <Typography variant="subtitle1">{option.title}{option.type === "clients" && option.client_type === 2 &&
                                            // <img src={ExternalLogo} style={{ marginLeft: "5px", height: "20px", width: "20px", marginTop: "2px", padding: "4px" }} />
                                            <IconButton style={{ marginTop: "2px", padding: "4px" }}>
                                                <ExternalClientIcon />
                                            </IconButton>
                                        }</Typography>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )
                    }}
                    onChange={(event, newValue) => { handleClick(newValue); setValue("") }}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Search" />
                    )}
                />
                <div className="globalSearchClose">
                    <IconButton onClick={() => { clickGlobalSearch() }}><CloseCircleIcon /></IconButton>
                </div>
            </div>
            }
            <Tooltip title={"Global Search"}><IconButton onClick={() => { clickGlobalSearch() }}>{globalLoading ? <CircularProgress style={{ width: "20px", height: "20px" }} /> : <GlobalSearchHeader />}</IconButton></Tooltip>
        </>
    )
}

// default props
GlobalSearch.defaultProps = {
    classes: {},
    isPublic: true
};

// prop types
GlobalSearch.propTypes = {
    classes: PropTypes.object,
    isPublic: PropTypes.bool
};

export default withStyles(HeaderStyles)(GlobalSearch)

const styles = (theme) => ({
    popper: {
       width: "fit-content"
    }
 });

const PopperMy = function (props) {
   return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};